.notifications {
  position: absolute;
  right: 0;
  top: 90px;
  max-width: 320px;
  max-height: 290px;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  border: 2px solid  #FFFFFF1A;
  border-top: 0;
  box-shadow: 0px 12px 16.6px 0px #09151E;
  display: flex;
  flex-direction: column;
  gap: 16px;
  z-index: 20;
  background-color: #162633;
  overflow: hidden;
  p {margin: unset;}

  @media screen and (max-width: 991.98px) {
    top: 80px;
    left: 0;
    width: 100%;
    height: calc(100% - 80px);
    max-width: unset;
    max-height: unset;
    border: unset;
    border-radius: unset;

    .notification__read-all {
      border-radius: 12px;
      margin: auto 12px 12px;
      width: calc(100% - 24px);
    }
  }
}



.notifications__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px 6px;

  p {
    font-weight: 800;
    font-size: 18px;
    line-height: 18px;
    color: #FFFFFF;
  }

  button {
    padding: 0;
    border: unset;
    display: flex;
    background-color: unset;
    width: 16px;
    height: 16px;
    svg, path {
      fill: #DCDCDC;
      width: 16px;
      height: 16px;
    }
  }
}

.notifications_content {
  gap: 16px;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.notifications__item {
  gap: 12px;
  display: flex;
  align-items: center;
  padding: 6px 0;
  background-color: transparent;
  border: unset;
  text-align: left;
  height: unset;

  .notifications__item-icon {
    height: 36px;
    width: 36px;
    border-radius: 12px;
    background-color: #253749;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.notifications__item-wrapper {
  gap: 2px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;

  .notifications__item-title {
    color: #DCDCDC;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
  }
  .notifications__item-text {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #90A2AF;
  }
}

.notifications__divider {
  width: 100%;
  height: 2px;
  border: 1px solid #1E3241;
  /* border-image-source: linear-gradient(180deg, #1E3241 0%,  100%); */
}

.notification__read-all {
  width: 100%;
  height: 48px;
  background-color: #253749;
  border: unset;
  padding: 16px 0;
  display: flex;
  justify-content: center;
  
  >div {
    background-image: linear-gradient(129.51deg, #FF9F00, #FFDB8C);
    color: transparent;
    background-clip: text;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
  }
}