.container {
  width: 100%;
  margin: 0;
  padding: 36px 66px;
  max-width: unset;

  p {
    margin-bottom: 0;
  }
  @media screen and (max-width: 991px) {
    padding: 24px 12px;

  }
}

.cassino__loby-header {
  display: flex;
  gap: 32px;
  flex-direction: column;

  @media screen and (max-width: 767px) {
    gap: 20px;
  }
}

.cassino__banner-wrapper {
  display: flex;
  gap: 18px;
  width: 100%;

  .cassino__banner {
    flex: 1;
    height: 211px;
    border-radius: 24px;
    box-shadow:inset 0px 0px 0px 2px #FFFFFF26;

    background-image: url("../../assets/images/home/BannerSlider/bg-banner1.svg");
    background-position: right top;
    background-repeat: no-repeat;
    background-size: cover;

    &:last-of-type {
      background-image: url("../../assets/images/home/BannerSlider/bg-banner2.svg");

    }

    @media screen and (max-width: 767px) {

      &:last-of-type {
        display: none;
      }
    }

    .cassino__banner-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 35px;
      margin: 24px 24px 0;
      width: 186px;

      p {
        color: #FFFFFF;
        font-size: 24px;
        line-height: 30px;
        font-weight: 800;
      }

      button {
        height: 38px;
        padding: 0 15px;
        border-radius: 12px;
        background-color: #FFFFFF;
        color: #525252;
        font-size: 14px;
        font-weight: 700;
      }
    }
  }
}
.filter {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .filter__wrapper {
    display: flex;
    align-items: center;
    gap: 18px;

    .filter__search-wrapper {
      width: 100%;
      display: flex;
      position: relative;

      >svg {
        top: 16px;
        left: 28px;
        z-index: 2;
        position: absolute;
      }

      .search-game {
        width: 100%;
        height: 51px;
        color: #90A2AF;
        font-size: 16px;
        font-weight: 700;
        line-height: 19.49px;
        padding-left: 56px;
        border-radius: 16px;
        background-color: #09151E;
        backdrop-filter: blur(13.5px);

        &::placeholder {
          color: #90A2AF;
        }
      }
    }
  }
  .filter__select-wrapper {
    display: flex;
    gap: 18px;
    align-items: center;
    position: relative;
  }

  .filter__select { 
    width: 234px;
    height: 51px;
    border-radius: 16px;
    background-color: #1E324199;
    border: 2px solid #FFFFFF1A;
    backdrop-filter: blur(13.5px);
    color: #90A2AF;

    svg {
      color: #DCDCDC;
      right: 20px;
    }

    .MuiSelect-select {
      padding: 0 24px;
    }

  }

  @media screen and (max-width: 991.98px) {
    .filter__wrapper {
      gap: 9px;
      flex-direction: column;
      align-items: unset;
      width: 100%;
      margin-bottom: 22px;
      
      .filter__search-wrapper {
        width: 100%;
      }
    }
    .filter__select {
      width: 100%;
    }
  }
}
.filter__select-menu {
  .MuiPopover-paper {
    color: #90A2AF;
    background-color:#162633;
    border: 2px solid #FFFFFF1A;
    border-radius: 16px;;
    box-shadow: 0px 12px 16.6px 0px #09151E;
    padding: 8px 24px 16px;
    margin-top: 12px;
    max-width: 234px;
    max-height: 300px;
    position: fixed;
    li {
      padding: 6px 0;

      .MuiListItemText-root, .MuiTypography-root {
        font-size: 16px;
        font-weight: 600;
        line-height: 21px;
      }

      .MuiCheckbox-root {
        padding: 0;
      }

      &:first-of-type {
        display: none;
      }
    }

  }
}

.filter__header {
  gap: 12px;
  display: flex;
  align-items: center;

  svg {
    height: 20px;
    path {
      fill: #DCDCDC;
    }
  }

  p {
    color: #FFFFFF;
    font-size: 24px;
    line-height: 24px;
    font-weight: 800;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  .filter__header-badge {
    padding: 2px 8px;
    border-radius: 100px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20.3px;
    letter-spacing: 0.01em;
    background-color: #5956FF;
  }
}

.recent-wins__container {
  display: flex;
  gap: 9px;
  margin-bottom: 13px;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &:-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
  }

  .recent-wins__item {
    gap: 9px;
    display: flex;
    padding: 8px;
    min-width: 167px;
    height: 60px;
    border-radius: 19px;
    background-color: #1E324199;
    border: 2px solid #FFFFFF1A;
    backdrop-filter: blur(13.5px)
  }

  p {
    color: #FFFFFF;
    font-size: 16px;
    line-height: 19px;
    font-weight: 700;
    display: flex;
    align-items: center;
    gap: 3px;

    &:last-of-type {
      margin-top: 3px;
      color: transparent;
      background-clip: text;
      background-image: linear-gradient(180deg, #FFC882 0%, #FD9632 100%);
    }
  }
}

.lobby__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  p {
    margin: 0;
    font-size: 24px;
    font-weight: 800;
    color: #FFFFFF;
  }
}

.lobby__navigation {
  display: flex;
  align-items: center;
  gap: 8px;

  .lobby__see-more {
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #253749;
    border: 3px solid #FFFFFF0D;
    box-shadow: 0px 9px 39.6px 0px #0700214D;
    border-radius: 12px;
    height: 50px;
    color: #DCDCDC;
    font-weight: 600;
    font-size: 16px;
  }
  

  .lobby__navigation-button {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #253749;
    border: 3px solid #FFFFFF0D;
    box-shadow: 0px 9px 39.6px 0px #0700214D;
    border-radius: 12px;
    width: 50px;
    height: 50px;

    &:disabled {
      opacity: 0.3;
    }
  }

  @media screen and (max-width: 991.98px) {
    display: none;
  }
}

.game-swiper-container {
  margin-top: 14px !important;
}

.game-thumb-container {
  border: 2px solid #FFFFFF26;
}

.swiper-free-mode > .swiper-wrapper {
  margin: 0 !important;
}

.game__name-wrapper {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 6px;

  p {
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 700;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &:last-of-type {
      color: #90A2AF;
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.mini-games-wrapper {
  margin-bottom: 32px !important;
}

.live-bets__container {
  display: flex;
  flex-direction: column;
  gap: 14px;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &:-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
  }

  .live-bets__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    p {
      font-size: 24px;
      font-weight: 800;
      line-height: 29px;
      color: #FFFFFF;
    }
  }

  @media screen and (max-width: 991.98px) {
    .live-bets__header {
      flex-direction: column;
      gap: 14px;
    }

    .live-bets__table {
      overflow: auto;
      min-width: 1220px;
    }
  }
}

.live-bets__button-wrapper {
  display: flex;
  gap: 10px

}
.live-bets__header-button {
  padding: 0 16px;
  height: 41px;
  color: #90A2AF;
  font-size: 16px;
  font-weight: 600;
  background-color: transparent;
  border: 0;
  border-radius: 12px;

  &.--active {
    background-color: #11212E;
    color: #FFFFFF;
  }
}

.live-bets__table {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;

  .live-bets__table-row {
    display: grid;
    height: 48px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    background-color: #11212E;
    border-radius: 16px;
    font-size: 16px;

    &.--header {
      background-color: transparent;
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
    }
  }
  .live-bets__table-col {
    display: flex;
    align-items: center;
    font-weight: 600;
    line-height: 21px;
    gap: 6px;
    padding: 0 16px;
    color: #90A2AF;

    &.--player {
      gap: 12px;
    }

    &.--white {
      color: #FFFFFF;
    }

    &.--gold{
      color: transparent;
      background-clip: text;
      background-image: linear-gradient(180deg, #FFC882 0%, #FD9632 100%);
    }
  }
}

main .filter-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

main .filter-wrapper .search-wrapper {
  position: relative;
  width: 18rem;
}

main .filter-wrapper .search-wrapper .material-icons:before {
  position: absolute;
  right: 1rem;
  color: #00ec66;
  font-size: 1.8rem;
  top: 0.6rem;
  content: attr(icon);
}

main .filter-wrapper .search-wrapper input {
  width: 100%;
  border-radius: 1.875rem;
  background-color: rgba(40, 45, 57, 0.9);
  color: #fff;
  height: 3.125rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-right: 3rem;
  border: none;
  outline: none;
}

main .filter-wrapper .search-wrapper input::-webkit-input-placeholder {
  color: var(--secondary-text-color);
}

main .filter-wrapper .search-wrapper input::-moz-placeholder {
  color: var(--secondary-text-color);
}

main .filter-wrapper .search-wrapper input::-ms-input-placeholder {
  color: var(--secondary-text-color);
}

main .filter-wrapper .search-wrapper input::placeholder {
  color: var(--secondary-text-color);
}

main .filter-wrapper .clear {
  border-radius: 50%;
  background-color: rgba(40, 45, 57, 0.9);
  width: 3.125rem;
  height: 3.125rem;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  color: var(--secondary-text-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-left: 0.6rem;
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease;
}

main .filter-wrapper .is-enable:hover {
  background-color: #00ec66;
  color: #000;
  cursor: pointer;
}



main .dropdown-cont {
  max-width: 17.625rem;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  position: relative;
  margin-bottom: 1.2rem;
  margin-left: auto;
}

main .dropdown-cont .custom-select.provider-filter {
  max-width: 17.625rem;
  width: 100%;
  border-radius: 1.875rem;
  background-color: rgba(40, 45, 57, 0.9);
  height: 3.125rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

main .dropdown-cont .custom-select.provider-filter input {
  width: 100%;
  height: 100%;
  background: 0 0;
  color: var(--secondary-text-color);
  border: none;
  outline: none;
}

main .dropdown-cont .custom-select.provider-filter input::-webkit-input-placeholder {
  color: var(--secondary-text-color);
}

main .dropdown-cont .custom-select.provider-filter input::-moz-placeholder {
  color: var(--secondary-text-color);
}

main .dropdown-cont .custom-select.provider-filter input::-ms-input-placeholder {
  color: var(--secondary-text-color);
}

main .dropdown-cont .custom-select.provider-filter input::placeholder {
  color: var(--secondary-text-color);
}

main .dropdown-cont .custom-select.provider-filter img {
  margin-right: 0.8625rem;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

main .dropdown-cont .custom-select.provider-filter span {
  font-size: 0.875rem;
}

main .dropdown-cont:hover .options-wrapper {
  opacity: 1;
  visibility: visible;
}

main .dropdown-cont .options-wrapper {
  z-index: 20;
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  background: rgba(26, 31, 43, 0.97);
  -webkit-transform-origin: top;
  transform-origin: top;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  opacity: 0;
  max-width: 17.625rem;
  top: 4.3rem;
  overflow-y: auto;
  border-radius: 0.5375rem;
  visibility: hidden;
}

main .dropdown-cont .options-wrapper ul {
  max-height: 18rem;
  padding: 1.125rem 0.625rem;
}

main .dropdown-cont .options-wrapper li {
  list-style: none;
  color: #fff;
  height: 2.25rem;
  max-width: 17.0625rem;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 0.6875rem;
  border-radius: 1.125rem;
  margin-top: 0.125rem;
  cursor: pointer;
}

main .dropdown-cont .options-wrapper li.active {
  background-color: #0c121e;
}

main .dropdown-cont .options-wrapper li:hover {
  background-color: rgba(12, 18, 30, 0.5);
}

main .dropdown-cont .options-wrapper li .counter {
  color: #00ec66;
}

main .dropdown-cont .options-wrapper li .provider-name {
  color: var(--secondary-text-color);
  font-size: 0.875rem;
}

main .options-wrapper {
  overflow: hidden;
}

main #custom-select-opt {
  position: relative;
  width: calc(100% + 17px);
  height: 100%;
  overflow: auto;
}

/* ========================================================================== */
main.casino .content,
main.livecasino .content {
  overflow: hidden;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-top: 1.2rem;
}

main.casino .content .loader-wrapper,
main.livecasino .content .loader-wrapper {
  background: rgba(27, 32, 44, 0.9);
  height: 12.5rem;
  padding-top: 5rem;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 0.625rem;
}

main.casino .content .no-data,
main.livecasino .content .no-data {
  display: none;
  background: rgba(27, 32, 44, 0.9);
  text-align: center;
  border-radius: 0.625rem;
  height: 17.625rem;
  font-size: 1.25rem;
  text-transform: capitalize;
  color: rgba(255, 255, 255, 0.8);
}

/* ============================= slider ======================================== */
main.casino .casino-slider,
main.livecasino .slider {
  margin-top: 0.8125rem !important;
  overflow: hidden;
  position: relative;
  padding-top: 0.1875rem;
  width: 100%;
  border-radius: 0.625rem;
  height: 15.125rem;
}

main.casino .casino-slider img,
main.livecasino .slider img {
  width: 100%;
  border-radius: 0.625rem;
}

/* =================================== game card ============================== */

main.casino .content .games-wrapper ul.games,
main.livecasino .content .games-wrapper ul.games {
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 13px;
}

main.casino .content .games-wrapper ul.games li h6 {
  color: #fff;
}

main.casino .content .games-wrapper ul.games li,
main.livecasino .content .games-wrapper ul.games li {
  overflow: hidden;
  position: relative;
  /* border-radius: 0.625rem; */
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-animation: showAnim 250ms forwards;
  animation: showAnim 250ms forwards;
  -webkit-animation-delay: var(--delay);
  animation-delay: var(--delay);
  /* margin-right: 17px; */
}

@-webkit-keyframes showAnim {
  to {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes showAnim {
  to {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

main.casino .content .games-wrapper ul.games li .bg,
main.livecasino .content .games-wrapper ul.games li .bg {
  position: relative;
  /* padding-top: 80%; */
  background-size: cover;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-position: center;
  /* border-radius: 8px; */
  /* height: 235px; */
  /* height: 100%; */
  width: 100%;
  border-radius: 1.5rem;
  border: 2px solid #FFFFFF26
}

/* main.casino .content .games-wrapper ul.games li .bg img {
  width: 100% !important;
  height: 100%;
  object-fit: cover;
  background-size: cover;
  aspect-ratio: 1;
} */

main.casino .content .games-wrapper ul.games li .bg img,
main.livecasino .content .games-wrapper ul.games li .bg img {
  width: 100% !important;
  height: 100%;
  object-fit: cover;
  background-size: cover;
  aspect-ratio: 1;
}

main.casino .content .games-wrapper ul.games li .bg:hover .slot-hover,
main.livecasino .content .games-wrapper ul.games li .bg:hover .slot-hover {
  -webkit-transform: translateY(0);
  transition-duration: .5s;
  transform: translateY(0);
}



main.casino .content .games-wrapper ul.games li .bg .slot-hover,
main.livecasino .content .games-wrapper ul.games li .bg .slot-hover {
  position: absolute;
  text-align: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  background-color: #0F1B24CC;
  -webkit-transform: translateY(101%);
  transform: translateY(101%);
  -webkit-transition: -webkit-transform 180ms;
  transition: -webkit-transform 180ms;
  transition: transform 180ms;
  transition: transform 180ms, -webkit-transform 180ms;
  z-index: 1;
}

main.casino .content .games-wrapper ul.games li .bg .slot-hover img.play,
main.livecasino .content .games-wrapper ul.games li .bg .slot-hover img.play {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  cursor: pointer;
  width: 52px;
  height: 52px;
  object-fit: fill !important;
}

main.casino .content .games-wrapper ul.games li .star-wrapper,
main.livecasino .content .games-wrapper ul.games li .star-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 3rem;
  line-height: 2.6rem;
  padding: 0 1rem;
  color: var(--white);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  svg {
   fill: #ffce63;
  }
}

main.casino .content .games-wrapper ul.games li .star-wrapper .slot-name,
main.livecasino .content .games-wrapper ul.games li .star-wrapper .slot-name {
  display: inline-block;

  width: calc(100% - 30px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

main.casino .content .games-wrapper ul.games li .star-wrapper .material-icons,
main.livecasino .content .games-wrapper ul.games li .star-wrapper .material-icons {
  float: right;
  font-size: 24px;
  cursor: pointer;
}

main.casino .content .games-wrapper .loaded,
main.livecasino .content .games-wrapper .loaded {
  margin-top: 1.875rem !important;
  width: 8.75rem;
  margin: auto;
  font-size: 0.9375rem;
  padding: 0 1.25rem;
}

main.casino .content .games-wrapper .loaded.visible,
main.livecasino .content .games-wrapper .loaded.visible {
  display: block !important;
}

main.casino .content .games-wrapper .loaded .text-wrapper,
main.livecasino .content .games-wrapper .loaded .text-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

main.casino .content .games-wrapper .loaded .text-wrapper span,
main.livecasino .content .games-wrapper .loaded .text-wrapper span {
  display: block;
  font-size: 0.75rem;
  color: var(--secondary-text-color);
}

main.casino .content .games-wrapper .loaded .text-wrapper span.of,
main.livecasino .content .games-wrapper .loaded .text-wrapper span.of {
  margin: 0 0.3125rem;
}

main.casino .content .games-wrapper .loaded .progress-bar,
main.livecasino .content .games-wrapper .loaded .progress-bar {
  overflow: hidden;
  border-radius: 1.25rem;
  width: 100%;
  height: 0.3125rem;
  background: #464b58;
  margin-top: 0.4375rem;
}

main.casino .content .games-wrapper .loaded .progress-bar .line,
main.livecasino .content .games-wrapper .loaded .progress-bar .line {
  background: var(--orange-primary);
  border-radius: 1.25rem;
  height: 100%;
  width: 50px;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}

main.casino .content .games-wrapper .show-more,
main.livecasino .content .games-wrapper .show-more {
  overflow: hidden;
  margin-top: 0.625rem !important;
  width: 9.75rem;
  margin: auto;
  font-size: 0.9375rem;
  background: 0 0 !important;
}

/* ================================ game popup ================================ */
.gamePopup {
  z-index: 99999;
}

main .filter-wrapper .search-wrapper input:focus {
  border: 1px solid #ffffff54;
}

.game-popup {
  display: flex;
  align-items: center;
  /* position: fixed; */
  left: 0;
  bottom: 0;
  /* height: 100%; */
  background-size: cover;
  width: 100%;
  padding-top: 1.8rem;
  padding-bottom: 3rem;
  /* padding-left: 19.475rem; */
  background: #0c121e;
  z-index: 9999;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  height: 100%;
  /* display: none; */
}

.no-result-section {
  background: rgba(27, 32, 44, .9);
  border-radius: .625rem;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.no-result-section img,
.no-result-section svg {
  height: 60px;
  width: 60px;
}

.no-result-section h5 {
  line-height: 30px;
  margin-bottom: 0;
}

.no-result-section p {
  font-weight: normal;
  font-size: 14px;
  color: #ccd6f6ba;
}

.swiper-button-prev,
.swiper-button-next {
  cursor: pointer;
}

.swiper-button-prev img,
.swiper-button-next img {
  height: 10px;
}

.searchCount {
  color: #00ec66;
}

.game-container__frame-wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 36px 66px;

  &.--fullscreen {
    padding: 10px;
  }

  .game-popup {
    height: unset;
    padding: 0;
  
    .game-info-wrapper {
      gap: 32px;
      display: flex;
      flex-direction: column;
      width: 100% !important;
    }
  
    .game-wrapper {
      border-radius: 0 !important;
      border: 0;
    }
  }

  @media screen and (max-width: 991.98px) { 
    gap: 20px;
    padding: 24px 12px;

    .game-popup {
      &.--fullscreen {
        top: 70px;
        height: calc(100svh - 80px);

        iframe {
          height: calc(100svh - 80px) !important;
        }

        &.--landscape {
          z-index: 20;
          top: 0;
          height: calc(100svh) !important;

          iframe {
            height: calc(100svh) !important;
          }
        }
      }
      &:not(.--fullscreen) {
        height: unset !important;
        width: unset !important;
        position: relative;
      }
    }

    .game-container__demo-mode {
      flex-direction: column;
      align-items: flex-start;
      gap: 0 !important;
      
      p {
        font-size: 14px !important;
        line-height: 18px !important;
      }
    }

    .MuiSwitch-root {
      margin-left: -12px;
      margin-top: -6px;
    }

    .game-wrapper {
      border-radius: 12px !important;
    }
  }

  .game-container__buttons-wrapper {
    &.--landscape {
      z-index: 30;
      position: absolute;
      right: 0;
    
      button {
        width: 40px;
        height: 40px;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.game-container__actions {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  width: 100%;

  .game-container__demo-mode {
    gap: 12px;
    margin: 0;

    .MuiSwitch-track {
      background-color: #253749 !important;
      opacity: 1;
    }

    .MuiSwitch-switchBase {
      color: #DCDCDC;

      &.Mui-checked {
        color: #ffce63;
      }
    }

    p {
      margin: 0;
      color: #FFFFFF;
      font-size: 18px;
      font-weight: 700;
    }
  }

  .game-container__buttons-wrapper {
    display: flex;
    gap: 8px;
    button {
      padding: 0;
      height: 50px;
      width: 50px;
      background-color: #253749;
      border-radius: 18px;
      border: 3px solid #FFFFFF0D;
      box-shadow: 0px 9px 39.6px 0px #0700214D;
    }
  }
}

.game-container__info {
  gap: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;

  p {
    margin: 0;
  }

  .game-container__info-header {
    gap: 16px;
    display: flex;
    align-items: center;
    color: #FFFFFF;

    >p {
      font-size: 24px;
      font-weight: 800;
    }

    .game-container__header-badge {
      border-radius: 100px;
      font-size: 16px;
      font-weight: 600;
      padding: 4px 12px;
      background-color: #D63789;
    }
  }

  .game-container__description {
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    color: #90A2AF;
  }
}
.gamer-card__no-result {
  padding: 48px 0;
  p {
    color: #90A2AF;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
  }
}

.game-card__footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 32px 0;
  gap: 20px;
}
.game-card__footer-text {
  display: flex;
  p {
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    color: #ffffff;
    
    &:last-of-type {
      color: #90A2AF;
    } 
  }
}

.game-card__footer-button {
  height: 42px;
  padding: 0 20px;
  color: #90A2AF;
  background-color: #11212E;
  border-radius: 12px;
  border: unset;
}

.game-popup.miniGames {
  padding: 0;
  background: #0c121ee0;
}

.game-popup.miniGames .game-info-wrapper {
  width: 99% !important;
  margin-top: 5.4rem;
}

.game-popup.miniGames .game-info-wrapper {
  /* width: 100%; */
  width: var(--width) !important;
}

.game-popup.betgames .game .iframe-helper .game-wrapper {
  position: relative;
  width: auto !important;
  /* height: auto !important; */

}

.game-popup .game-info-wrapper {
  /* width: 72rem; */
  width: 85rem;
  position: relative;
  overflow: hidden;
  z-index: 99;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  height: 100%;
  /* overflow: auto; */
  /* width: var(--width); */
  /* margin-top: 1.4rem; */
  /* margin-bottom: 1.4rem;  */
}

/* @media (max-height: 760px) {
  .game-popup .game-info-wrapper {
    --width: 72rem;
  }
} */
.game-popup .game-info-wrapper .back {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 0.9375rem;
  line-height: 0;
  cursor: pointer;
}


.game-popup .game-info-wrapper .back:hover span {
  color: #fff;
}

.game-popup .game-info-wrapper .back span {
  font-size: 13px;
  color: rgba(204, 214, 246, 0.72);
  font-weight: 700;
}

.game-popup .game-wrapper {
  position: relative;
  width: 100%;
  background: #000000;
  /* border-radius: 0.5rem; */
  overflow: hidden;
  overflow: auto;
}

.game-popup .game-wrapper .game {
  position: relative;
}

.game-popup .game-wrapper:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
  background: #171d29;
}

.game-popup .game-wrapper .iframe-helper {
  height: 0;
  padding-top: 50%;
}

.game-popup .game-wrapper .iframe-helper>* {
  position: absolute;
  left: 0;
  top: 0;
  width: 100% !important;
  height: 100% !important;
}

.game-popup .game-wrapper .iframe-helper #game-container {
  text-align: center;
  overflow: auto;
}

.game-popup .game-wrapper .iframe-helper #game-container iframe {
  width: 100% !important;
  height: calc(100vh - 100px) !important;
}

.game-popup .game-wrapper .iframe-helper #game-container #egamings_container {
  height: 100%;
}

.game-popup .game-wrapper .icons {
  position: absolute;
  right: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--white);
  -webkit-transform: translateX(120%);
  transform: translateX(120%);
}

.game-popup .game-wrapper .icons .material-icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #f44141;
  -webkit-transition: 180ms ease;
  transition: 180ms ease;
  font-size: 1.875rem;
  cursor: pointer;
  padding: 0.625rem;
  border-radius: 0.625rem;
}

.game-popup .game-wrapper .icons .material-icons:last-child {
  border-top: none;
  margin-top: 0.625rem;
}

.game-popup .game-wrapper .icons .material-icons:hover {
  -webkit-filter: brightness(1.2);
  filter: brightness(1.2);
  -webkit-box-shadow: 0 0 0.625rem #f44141;
  box-shadow: 0 0 0.625rem #f44141;
  color: var(--white);
}

.game-popup .game-wrapper .game-review {
  position: relative;
  overflow: hidden;
  padding: 1.375rem 1.5rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.game-popup .game-wrapper .game-review svg {
  height: 1.875rem;
}

.game-popup .game-wrapper .game-review .game-review-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.game-popup .game-wrapper .game-review .game-review-container .game-info .title {
  color: #ccd6f6;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.125rem;
}

.game-popup .game-wrapper .game-review .game-review-container .game-info p {
  color: #ccd6f6;
  font-size: 1rem;
  font-weight: 400;
}

.game-popup .game-wrapper .game-review .game-review-container .fav-container {
  cursor: pointer;
  padding-top: 0.1875rem;
  margin-left: 0.75rem;
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease;
}

.game-popup .game-wrapper .game-review .game-review-container .fav-container:hover {
  opacity: 0.8;
}

.game-popup .game-wrapper .game-review .action-buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: auto;
  position: relative;
  border-left: 0.0625rem solid rgba(204, 214, 246, 0.15);
  padding-left: 0.9375rem;
}

.game-popup .game-wrapper .game-review .action-buttons>div {
  margin: 0 0.9375rem;
  cursor: pointer;
}

.game-popup .game-wrapper .game-review .action-buttons>div.active svg,
.game-popup .game-wrapper .game-review .action-buttons>div:hover svg {
  -webkit-filter: brightness(10);
  filter: brightness(10);
}

.game-popup .game-wrapper.minimized {
  background: 0 0;
  bottom: 0.9375rem;
  overflow: unset;
  left: 0.9375rem;
  width: 34vh;
  bottom: 8.5rem;
  height: 13.25rem;
}

.game-popup .game-wrapper.minimized .game {
  width: 34vh;
  -webkit-transform: none;
  transform: none;
  left: 0;
}

.game-popup .game-wrapper.minimized .icons {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

.game-popup .game-wrapper.minimized .icons .material-icons {
  font-size: 1rem;
  padding: 0.3125rem;
}

.game-popup .game-wrapper.minimized .game-review {
  display: none;
}

.game-popup .game-wrapper .choose-currency {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #171d29;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.game-popup .game-wrapper .choose-currency.hidden {
  display: none;
}

.game-popup .game-wrapper .choose-currency .select {
  width: 15.25rem;
}

.game-popup .game-wrapper .choose-currency .btn {
  margin-right: 1.25rem;
  font-size: 0.875rem;
  background: rgba(12, 18, 30, 0.5);
  font-weight: 400;
  overflow: unset;
  position: relative;
  z-index: 1;
  color: #0c121e;
  background: #00ec66;
  -webkit-box-shadow: 0 2px 5px rgba(38, 51, 77, 0.03);
  box-shadow: 0 2px 5px rgba(38, 51, 77, 0.03);
  background-position: center;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  border: none;
  margin-left: 0.8rem;
  font-weight: bold;
}

.game-popup .game-wrapper .choose-currency .btn:after,
.game-popup .game-wrapper .choose-currency .btn:before {
  content: "";
  position: absolute;
  z-index: -1;
  border-radius: 2rem;
  display: block;
  pointer-events: none;
}

.game-popup .game-wrapper .choose-currency .btn:before {
  background: -webkit-gradient(linear,
      left bottom,
      left top,
      from(transparent),
      to(rgba(0, 0, 0, 0.4)));
  background: linear-gradient(to top, transparent, rgba(0, 0, 0, 0.4));
  top: 0;
  height: 100%;
  width: 100%;
  right: 0;
  margin: auto;
}

.game-popup .game-wrapper .choose-currency .btn:after {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 0.125rem;
  width: 80%;
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease;
  height: 0.5rem;
  background: #00ec66;
  -webkit-filter: blur(0.625rem);
  filter: blur(0.625rem);
}

.game-popup .game-wrapper .choose-currency .btn:hover {
  -webkit-filter: brightness(1.1);
  filter: brightness(1.1);
}

.game-popup .game-wrapper .choose-currency .btn:hover:after {
  height: 0.9375rem;
}

main.livecasino {
  --colorgame: #f44141 !important;
  --slotgradient: linear-gradient(to top,
      #f44141 0,
      rgba(244, 65, 65, 0.95) 11.3%,
      rgba(244, 65, 65, 0.92) 31.8%,
      rgba(244, 65, 65, 0.6) 71.5%,
      rgba(244, 65, 65, 0.2) 100%) !important;
  --width: 72rem !important;
}

main.casino {
  --colorgame: #f47341;
  --slotgradient: linear-gradient(to top,
      #f47341 0,
      rgba(244, 115, 65, 0.95) 11.3%,
      rgba(244, 115, 65, 0.92) 31.8%,
      rgba(244, 115, 65, 0.6) 71.5%,
      rgba(244, 115, 65, 0.2) 100%);
}

.padding-top-2 {
  padding-top: 2px;
}

@media screen and (max-width: 1640.98px) {
  .game-popup .game-info-wrapper {
    width: 72rem;
  }
}

@media screen and (max-width: 1400px) {
  .search_history_game {
    /* height: 250px; */
  }
}

@media screen and (max-width: 1024.98px) {
  .game-popup .game-info-wrapper {
    width: 40rem;
  }

  main.casino .content .games-wrapper ul.games {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 15px;
  }

  .game-popup {
    padding-top: 11.8rem;
  }
}

/* ========================================================================= */
/* ================================ media query ============================ */
/* ========================================================================= */
@media screen and (min-width: 991.98px) {
  .back-btn-mobile {
    display: none;
  }
}

@media screen and (max-width: 991.98px) {
  .games-wrapper {
    margin-bottom: 70px;
  }



  /* main.livecasino {
    --colorgame: #f44141 !important;
    --slotgradient: linear-gradient(
      to top,
      #f47341 0,
      rgba(244, 115, 65, 0.95) 11.3%,
      rgba(244, 115, 65, 0.92) 31.8%,
      rgba(244, 115, 65, 0.6) 71.5%,
      rgba(244, 115, 65, 0.2) 100%
    );
  }
  main.casino {
    --colorgame: #f47341;
    --slotgradient: linear-gradient(
      to top,
      #f47341 0,
      rgba(244, 115, 65, 0.95) 11.3%,
      rgba(244, 115, 65, 0.92) 31.8%,
      rgba(244, 115, 65, 0.6) 71.5%,
      rgba(244, 115, 65, 0.2) 100%
    );
  } */

  main.casino .content .games-wrapper ul.games,
  main.livecasino .content .games-wrapper ul.games {
    grid-template-columns: repeat(3, 1fr);
  }

  main .filter-wrapper .clear {
    width: 2.25rem;
    height: 2.25rem;
  }

  main .filter-wrapper .search-wrapper {
    width: 12rem;
    height: 2.25rem;
  }

  main .filter-wrapper .search-wrapper input {
    width: 100%;
    height: 2.2857rem;
    max-width: 14.375rem;
    width: 100%;
    border-radius: 1.875rem;
    border: 0.0625rem solid #39404c;
    background-color: #262b36;
    height: 2.25rem;
    font-size: 0.775rem;
    display: flex;
    align-items: center;
    padding: 0 0.625rem;
    justify-content: space-between;
  }

  main .filter-wrapper {
    justify-content: space-between;
  }

  main .filter-wrapper .search-wrapper .material-icons:before {
    display: none;
  }

  main .dropdown-cont {
    width: 12rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    position: relative;
  }

  main .dropdown-cont .custom-select.provider-filter {
    position: relative;
    display: flex;
    align-items: center;
    max-width: 14.375rem;
    width: 100%;
    border-radius: 1.875rem;
    border: 0.0625rem solid #39404c;
    background-color: #262b36;
    height: 2.25rem;
    padding: 0 0.625rem;
    justify-content: space-between;
    font-size: 0.8rem;
  }

  main .dropdown-cont .custom-select.provider-filter input {
    padding-left: 0 !important;
  }

  main .dropdown-cont .custom-select.provider-filter img {
    margin: 0;
  }

  main .dropdown-cont {
    margin: 0;
  }

  main .filter-content {
    width: 100%;
    position: -webkit-sticky;
    position: sticky;
    top: 0rem;
    /* background: #0c121e; */
    padding: 0.8571rem 0;
    z-index: 9;
  }

  main.casino .content,
  main.livecasino .content {
    margin: 0;
  }

  main .dropdown-cont .options-wrapper {
    max-width: 15.625rem;
    top: 2.5rem;
    overflow-y: auto;
  }

  main #custom-select-opt {
    width: 100%;
  }

  main .dropdown-cont .options-wrapper li {
    font-weight: 400;
  }

  main.casino .casino-slider,
  main.livecasino .slider {
    height: 53vw;
    margin-top: 0 !important;
    padding: 0;
  }

  /* ================= game slot =========================== */
  main.casino .content .slot-categories {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: auto;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0.5714rem;
  }

  main.casino .content .clear-filter.clear-filter {
    padding: 0 0.8229rem;
  }

  main.casino .content .clear-filter {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: var(--colorgame);
    color: #fff;
    height: 2.2857rem;
    font-size: 0.8109rem;
    padding: 0 0.5714rem;
    border-radius: 0.2092rem;
    margin-right: 0.5714rem;
  }

  main.casino .content .clear-filter.clear-filter .icon {
    color: #fff;
    font-size: 1.28rem;
    font-family: "Material Icons";
  }

  main.casino .content .slot-categories ul {
    overflow-x: auto;
    display: flex;
  }

  main.casino .content .slot-categories ul li {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-align: center;
    color: #fff;
    height: 2.2857rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 0.5714rem;
    background-color: var(--colorgame);
    padding: 0 1.3714rem;
    border-radius: 0.2092rem;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }

  main.casino .content .slot-categories ul li p {
    color: #fff;
    font-size: 0.6857rem;
    position: relative;
    text-transform: uppercase;
    margin: 0;
  }

  main.casino .content .games-wrapper ul.games li .bg .slot-hover img.play,
  main.livecasino .content .games-wrapper ul.games li .bg .slot-hover img.play {
    width: 25px;
  }

  main.casino .content .games-wrapper ul.games li .star-wrapper .material-icons,
  main.livecasino .content .games-wrapper ul.games li .star-wrapper .material-icons {
    font-size: 24px;
  }

  main.casino .content .games-wrapper ul.games li .star-wrapper,
  main.livecasino .content .games-wrapper ul.games li .star-wrapper {
    /* bottom: -10px;
    right: -5px; */
  }

  /* ========================================================================== */
  /* ========================================================================== */
  /* ========================================================================== */

  main.casino .content .games-wrapper ul.games li .bg .slot-hover img.play,
  main.livecasino .content .games-wrapper ul.games li .bg .slot-hover img.play {
    width: 25px;
  }

  main.casino .content .games-wrapper ul.games li .star-wrapper .material-icons,
  main.livecasino .content .games-wrapper ul.games li .star-wrapper .material-icons {
    font-size: 24px;
  }

  main.casino .content .games-wrapper ul.games li .star-wrapper,
  main.livecasino .content .games-wrapper ul.games li .star-wrapper {
    bottom: 0px;
    right: 0px;
  }

  .game-popup.miniGames {
    background: #0c121e;
    z-index: 999999;
  }

  .game-popup .game-wrapper .game-review {
    display: none;
  }

  .game-popup .game-wrapper .choose-currency {
    background: #0c121e;
    top: 50%;
    transform: translateY(-50%);
    height: 100%;
  }

  .game-popup .game-info-wrapper .back {
    display: none;
  }

  .game-popup.miniGames .game-info-wrapper {
    margin: 0;
    height: 100vh;
  }

  .game-popup .game-wrapper {
    /* display: flex; */
    align-items: center;
    justify-content: center;
    /* height: 100vh 
    */
    height: 100%
  }

  /* .game-info-wrapper {
    margin-top: 30px;
  } */

  .game-popup .game-wrapper .game {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    /* margin-top: 70px; */
  }

  .game-popup {
    position: absolute;
    /* height: 100vh !important; */
    /* padding: 0 5
    px; */
    /* margin-top: 30px; */
    overflow: hidden;
    /* width: 100v !important;  */
    padding: 0;
  }
}

/******** new banner *******/

.all-games-banner {
  height: 17.875rem;

  /* background: url("../../assets/images/casinogame/banner/heading-object.png"), linear-gradient(to left, #0f1a33, rgba(15, 26, 51, .79), #0c121e); */
  background: linear-gradient(to left,
      #0f1a33,
      rgba(15, 26, 51, 0.79),
      #0c121e);
  border-radius: 1.25rem;
  background-repeat: no-repeat;
  background-position: center right;
  padding: 2.5rem;
  padding-bottom: 0.625rem;
  padding-left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  background-size: 30%, 100%;
  z-index: 9;
  justify-content: space-between;
}

.all-games-banner .page-title-2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 3.1875rem;
}

.all-games-banner .page-title-2 .name {
  font-size: 1.5rem;
}

.all-games-banner .page-title-2 .name span:last-child {
  color: #00ec66;
}

.all-games-banner .page-title-2 img {
  margin-right: 0.8125rem;
}

.all-games-banner .bottom-filter {
  margin-top: auto;
  /* display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start; */
  background-color: #121723;
  border-radius: 9.375rem;
  margin-left: 1rem;
  margin-right: 1rem;
  width: calc(100% - 1.5rem);
  white-space: nowrap;
  padding: 0px 0.25rem;
}

.all-games-banner .bottom-filter .slick-dots li button:before {
  display: none;
}

.all-games-banner .bottom-filter .slick-next {
  width: 13px;
  height: 13px;
}

.all-games-banner .bottom-filter .slick-prev {
  width: 13px;
  height: 13px;
}

.all-games-banner .filter-categories {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #121723;
  height: 3rem;
  border-radius: 9.375rem;
  /* margin-right: 1.6875rem; */
  padding: 6px 0.25rem;
}

.all-games-banner .filter-categories .category-item.active {
  background: #191e2b;
  color: #00ec66 !important;
  /* margin-top: 3px; */
}

.all-games-banner .bottom-filter .filter-categories .category-item {
  padding: 0 1.475rem;
  height: 100%;
  border-radius: 9.375rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 700;
  font-size: 15px;
  color: #97a1c2;
  /* margin-top: 3px; */
}

.swiper-wrapper {
  max-width: 1000px;
}

.slider-navigation-mobile .swiper-slide {
  width: auto !important;
}

.slider-navigation-desktop .swiper-slide {
  width: auto !important;
}

.all-games-banner .dropdown-cont {
  display: none;
}

.game-filter-wrapper {
  display: flex;
  flex-direction: column;
  gap: 26px;
}

.game-filter {
  display: flex;
  align-items: center;
}

.slider-navigation-mobile {
  display: none !important;
}

/**** media query to make banner responsive ******/
.back-btn-mobile {
  color: white;
  text-shadow: 2px 2px #000;
  background: transparent;
  width: 100%;
  /* position: fixed; */
  /* height: 30px; */
  cursor: pointer;
  /* padding: 10px 0 0 0; */
  align-content: center;
  position: absolute;
  top: 3px;
  z-index: 99999;
  font-size: 12px;
}

.back-btn-mobile svg {
  color: rgb(234, 43, 43) !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

@media screen and (max-width: 768px) {

  /* .all-games-banner .page-title-2 img {
    display: none;
  } */


  .slider-navigation-desktop {
    display: none !important;
  }

  .slider-navigation-mobile {
    display: block !important;
  }

  .all-games-banner .page-title-2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0rem;
    padding-top: 1.9333rem;
    height: 11.875rem;
    background: url("./../../assets/images/casinogame/banner/heading-object.png"),
      #0c121e;
    border-radius: 1.25rem;
    background-repeat: no-repeat;
    background-position: top -1.7rem right -1.9rem;
    padding-bottom: 0.625rem;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;

    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    background-size: 75%, 100%;
    z-index: 9;
  }

  .all-games-banner {
    height: auto;
    /* background: url("../../assets/images/casinogame/banner/heading-object.png"),#0c121e; */
    background: #0c121e;
    border-radius: 1.25rem;
    background-repeat: no-repeat;
    background-position: top -1.7rem right -1.9rem;

    padding: 0 0 0.625rem 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    background-size: 75%, 100%;
    z-index: 9;
  }

  .all-games-banner .page-title-2 .name {
    flex-direction: column;
    display: flex;
  }

  .all-games-banner .page-title-2 .name {
    font-size: 1.6rem;
    width: 11rem;
    line-height: 1.6rem;
  }

  /* .all-games-banner .bottom-filter {
    display: none;
  } */

  .all-games-banner .dropdown-cont {
    display: block;
  }

  .options-wrapper span {
    color: #00ec66;
    font-size: 0.875rem;
  }

  .all-games-banner .dropdown-cont ul li {
    list-style: none;
    color: #ccd6f6;
    height: 2.6667rem;
    font-size: 0.875rem;
    max-width: 14.0625rem;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0.625rem;
    border-radius: 1.125rem;
  }

  .game-filter-wrapper {
    /* display: flex;
    flex-direction: row; */
    gap: 8px;
    align-items: center;
  }

  .all-games-banner .bottom-filter {
    margin-left: 0rem;
    width: calc(100% - 2.5rem);
  }

  main.casino .content .games-wrapper ul.games li .bg {
    height: 178px;
  }

  main.casino .content .games-wrapper ul.games li .bg img {
    height: 178px;
  }

  .game-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .all-games-banner .bottom-filter .filter-categories .category-item {
    font-size: 15px;
  }
}

@media screen and (max-width: 500px) {

  main.casino .content .games-wrapper ul.games,
  main.livecasino .content .games-wrapper ul.games {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 425px) {
  .all-games-banner .bottom-filter .filter-categories .category-item {
    font-size: 13px;
    font-weight: 600;
  }

  .games-wrapper {
    margin-bottom: 22px;
  }

  main.casino .content .games-wrapper ul.games,
  main.livecasino .content .games-wrapper ul.games {
    grid-template-columns: repeat(2, 1fr);
  }


  main.casino .content .games-wrapper ul.games {
    grid-gap: 18px;
  }
}

@media screen and (max-width: 557px) {
  .all-games-banner .bottom-filter .filter-categories .category-item {
    font-size: 15px;
    font-weight: 600;
  }
}

.game-title {
  color: #fff;
  font-weight: 500;
}

@media screen and (max-width: 480px) {
  main.casino .content .games-wrapper ul.games li .bg {
    height: auto;
  }

  main.casino .content .games-wrapper ul.games li .bg img {
    height: 100%;
  }

  .game-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  main .filter-wrapper .search-wrapper {
    width: 100%;
  }

  main .filter-wrapper .search-wrapper .search-game {
    max-width: 100%;
    width: 100%;
    padding: 0 1rem;
    height: 40px;
    font-size: 15px;
  }

  main .filter-wrapper .clear {
    width: 40px;
    height: 40px;
  }
}

.phone-search {
  display: none;
}

.web-search {
  display: block;
}



@media screen and (max-width: 991px) {
  .phone-search {
    display: block;
  }

  .all-games-banner .bottom-filter .slick-arrow {
    display: none !important;
  }

  .all-games-banner .bottom-filter {
    margin-left: .7rem;
    width: 100%;
    margin-right: .7rem;
  }

  .web-search {
    display: none;
  }
}

/* .ios-device {
  height: 100vh
} */

.ios-device iframe {
  height: 100%;
  width: 100%;

}