.support {
  gap: 14px;
  display: flex;
  flex-direction: column;
  margin: 36px 66px;

  p {
    margin-bottom: 0;
  }
}

.support-header {
  p {
    font-weight: 800;
    font-size: 24px;
    line-height: 24px;
    color: #FFFFFF;
  }
}

.support__wrapper {
  gap: 18px;
  display: flex;
}

.support__menu {
  min-width: 270px;
  gap: 6px;
  display: flex;
  flex-direction: column;

  .support__menu-button {
    height: 68px;
    border: unset;
    border-radius: 18px;
    background-color: #11212E;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 28px 0 18px;

    p {
      gap: 11px;
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
      letter-spacing: 0;
      color: #90A2AF;
      
      svg, path {
        fill: #DCDCDC;
      }
    }

    &.--active {
      background-color: #253749;
    }

    &.--write-to-support {
      padding-right: 20px;

      p {
        background: linear-gradient(129.51deg, #FF9F00 -12.53%, #FFDB8C 82.06%);
        color: transparent;
        background-clip: text;
      }
    }
  }
}

.support__content {
  gap: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 872px;
}

.support__content-wrapper {
  gap: 6px;
  display: flex;
  flex-direction: column;
}

.support__help-item {
  gap: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 18px;
  background-color: #11212E;
  padding: 21px;
  min-height: 68px;

  .support__item-title {
    width: 100%;
    display: flex;
    align-self: center;
    justify-content: space-between;
    color: #90A2AF;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;

    svg {
      margin: 6px 10px;
      transition-duration: 0.8s;
      transition-property: transform;
    }
  }

  .support__item-text {
    color: #FFFFFF;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    animation: fadein 1.5s;
  }

  &.--expanded {
    flex-direction: column;

    .support__item-title svg {
      transform: rotate(90deg);
    }

    .support__item-text {
      padding-right: 36px;
    }
  }
}

.support__content-header {
  gap: 14px;
  display: flex;
  align-items: center;
  
  p {
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: #FFFFFF;
  }
}


@media screen and (max-width: 991.98px) {
  .support {
    margin: 24px 12px;
  }

  .support-header p {
    font-weight: 800;
    font-size: 20px;
    line-height: 20px;
  }

  .support__wrapper {
    flex-direction: column;
  }

  .support__menu {
    width: 100%;
  }
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}