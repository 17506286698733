.create-campaign {
  width: 480px;
  margin: 0 12px;
  gap: 22px;
  display: flex;
  flex-direction: column;
  background-color: #11212E;
  border-radius: 24px;
  box-shadow: 3px -3px 12px -7px rgba(144,162,175,0.44);
  padding: 22px;
  
  p {
    margin-bottom: 0 !important;
  }

  @media screen and (max-width: 480px) {
    width: 400px;
    padding: 16px;
  }
}

.create-campaign__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.create-campaign__title {
  gap: 12px;
  display: flex;
  align-items: center;
  color: #FFFFFF;

  svg, path {
    width: 20px;
    height: 20px;
    fill: #DCDCDC;
  }

  p {
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
  }
}

.create-campaign__close-button {
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background-color: #253749;
  margin: 0;
  border: 3px solid #FFFFFF0D;
  border-radius: 12px;
  box-shadow: 0px 9px 39.6px 0px #0700214D;
  max-height: 36px;

  svg {
    width: 16px;
    height: 16px;
  }
}

.create-campaign__content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.create-campaign__item {
  gap: 6px;
  display: flex;
  flex-direction: column;
  position: relative;

  > label {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    color: #90A2AF;
  }

  svg {
    position: absolute;
    right: 16px;
    top: 50%;
  }

  &:last-of-type {
    input {
      &::placeholder {
        color: #41505A;
      }
    }
  }
}

.create-campaign__input {
  height: 52px;
  padding:  0 16px;
  border-radius: 16px;
  border: unset;
  background-color: #09151E;

  &::placeholder {
    color: #90A2AF;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
  }
}

.create-campaign__button {
  height: 52px;
  border-radius: 16px;
  border: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(129.51deg, #FF9F00 -12.53%, #FFDB8C 82.06%);
  color: #101010;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.92px;
}