.daily-promotion {
  gap: 20px;
  display: flex;
  flex-direction: column;
  margin: 36px 66px;

  p {
    margin-bottom: 0;
  }

  @media screen and (max-width: 480px) {
    margin: 24px 12px;

    .daily-promotion__header {
      gap: 16px;
      flex-direction: column;
      align-items: start;
    }

    .daily-promotion__progress-wrapper {
      flex-direction: column;
    
      .daily-promotion__progress-item {
        height: 202px;
        padding: 16px;
        width: 100% !important;
        > p {
          font-size: 20px;
        }
      }
    }

    .daily-promotion__active-promos {
      display: flex;
      flex-direction: column;

      .daily-promotion__promo-item {
        padding: 16px;
        height: 202px;
        > p {
          font-size: 20px;
        }
      }
    }
  }
}

.daily-promotion__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.daily-promotion__title {
  gap: 12px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  font-size: 24px;
  font-weight: 800;
  line-height: 24px;

  svg {
    fill: #FFFFFF;
  }

  .daily-promotion__title-badge {
    height: 25px;
    width: 25px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20.3px;
    letter-spacing: 0.01em;
    background-color: #5956FF;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.daily-promotion__button-wrapper {
  display: flex;

  .daily-promotion__button {
    gap: 8px;
    display: flex;
    align-items: center;
    justify-items: center;
    width: 120px;
    border: unset;
    border-radius: 12px;
    background-color: transparent;
    color: #90A2AF;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;

    &.--active {
      color: #FFFFFF;
      background-color: #253749;
    }
  }
}

.daily-promotion__content {
  gap: 18px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.daily-promotion__progress-wrapper {
  display: flex;
  gap: 18px;

  .daily-promotion__progress-item {
    background-position: top right;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    padding: 22px;
    border-radius: 24px;
    height: 260px;
    width: 60%;
    color: #FFFFFF;
    cursor: pointer;

    &:last-of-type { 
      width: 40%;
    }

  }
}

.daily-promotion__progress-title {
  font-family: Druk Wide Bold;
  font-size: 24px;
  font-weight: 700;
  line-height: 31.34px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  max-width: 290px;
}

.daily-promotion__progress-content {
  margin-top: auto;
  width: 100%;
  >p {
    font-size: 14px;
    font-weight: 800;
    line-height: 21px;
    color: #FFFFFF;
    margin-bottom: 6px;
  }
}

.daily-promotion__content-wrapper {
  width: 100%;
  gap: 12px;
  display: flex;
  align-items: center;

  svg {
    width: 32px;
    height: 32px;
  }
}

.daily-promotion__progressbar-wrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 6px;
  width: 100%;
  p {
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.92px;
  }
}

.daily-promotion__progressbar {
  width: 100%;
  height: 12px;
  border-radius: 24px;
  background-color: #FFFFFF80;
  position: relative;
  >div {
    position: absolute;
    height: 12px;
    border-radius: 24px;
    background-color: #FFFFFF;
  }
}

.daily-promotion__active-promos {
  gap: 18px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.daily-promotion__promo-item {
  display: flex;
  flex-direction: column;
  align-items: self-start;
  height: 261px;
  padding: 22px;
  border-radius: 24px;
  background-position: top right;
  background-repeat: repeat;
  background-size: cover;
  cursor: pointer;

  >p {
    font-family: Druk Wide Bold;
    font-size: 24px;
    font-weight: 700;
    line-height: 31.34px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    max-width: 230px;
    color: #FFFFFF;
  }
}

.daily-promotion__status-badge {
  margin-top: auto;
  gap: 10px;
  display: flex;
  padding: 8px 16px;
  border-radius: 16px;
  background-color: #FFFFFF33;
  color: #FFFFFF;

  svg, path {
   fill: #FFFFFF; 
  }
}

.promo-details {
  width: 740px;
  margin: 0 12px;
  gap: 22px;
  display: flex;
  flex-direction: column;
  background-color: #11212E;
  border-radius: 24px;
  box-shadow: 3px -3px 12px -7px rgba(144,162,175,0.44);
  padding: 22px;
  
  p {
    margin-bottom: 0 !important;
  }

  @media screen and (max-width: 480px) {
    width: 400px;
    padding: 16px;
    height: 100%;
    overflow: hidden;

    .promo-details__promo-item {
      height: 202px;
      padding: 16px;
      >p {
        font-size: 20px;
      }
    }

    .promo-details__game >img {
      height: 171px;
      width: 171px;
    }
  }
}

.promo-details__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.promo-details__header-title {
  gap: 12px;
  display: flex;
  align-items: center;
  color: #FFFFFF;

  >svg {
    width: 21px;
    height: 20px;
  }

  p {
    font-size: 24px;
    font-weight: 800;
    line-height: 24px;
  }
}

.promo-details__close-button {
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background-color: #253749;
  margin: 0;
  border: 3px solid #FFFFFF0D;
  border-radius: 12px;
  box-shadow: 0px 9px 39.6px 0px #0700214D;
  max-height: 36px;

  svg {
    width: 16px;
    height: 16px;
  }
}

.promo-details__content-wrapper {
  gap: 22px;
  display: flex;
  overflow: auto ;
  flex-direction: column;
  scrollbar-width: none;
}

.promo-details__content {
  gap: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .promo-details__content-description {
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    color: #90A2AF;
  }
}

.promo-details__content-title {
  font-size: 18px;
  font-weight: 800;
  line-height: 18px;
  color: #FFFFFF;
}

.promo-details__content-divider {
  width: 100%;
  height: 1px;
  border: 1px solid #1E3241;
}

.promo-details__promo-item {
  background-position: top right;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 22px;
  border-radius: 24px;
  height: 260px;
  color: #FFFFFF;

  >div {
    cursor: unset;
  }
}

.promo-details__slider-wrapper {
  overflow-x: scroll;
  min-height: 240px;
  scrollbar-width: 0;
}

.promo-details__games-slider {
  display: flex;
  gap: 22px;
}

.promo-details__game {
  img {
    width: 189px;
    height: 189px;
  }
}

.promo-details__game-description {
  margin-top: 12px;
  gap: 6px;
  display: flex;
  flex-direction: column;

  p {
    font-size: 18px;
    font-weight: 700;
    line-height: 18px;
    color: #FFFFFF;

    &:last-of-type {
      font-size: 15px;
      font-weight: 600;
      line-height: 18px;
      color: #90A2AF;
    }
  }
}

@media screen and (max-width: 480px) {
  .MuiModal-root {
    bottom: unset !important;
    height: 90%;
    top: 100px
  
  }
  .new-modal__content {
    margin-top: 70px;
  }
}