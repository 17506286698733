#sidebar {
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.3s ease;
  background-color: #11212E;
  grid-area: nav;

  .menu {
    padding: 0 0 0 24px !important;  
  }

  @media screen and (max-width: 991.98px) {
    height: unset !important;
    
    .menu {
      padding: 0!important;
    }

    .sections-wrapper {
      height: unset !important;
    }
  }
}

.sidebar-menu {
  overflow: hidden;
  border: 0 !important;
  background-image: unset;
  background-color: #11212E;
  border-radius: 0;

  p {
    margin-bottom: 0;
  }
}


.sidebar-menu__list {
  gap: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.sidebar-menu__list-title {
  font-size: 12px;
  color: #FFFFFF;
  margin-bottom: 8px;
}

.sidebar-menu__list-item {
  display: flex;
  align-items: center;
  color: #90A2AF;
  font-weight: 600;
  gap: 12px;
  cursor: pointer;

  &.--active {
    color: #FFDB8C;
  }
  
  img {
    width: 18px;
    height: 18px;
  }
  
  svg {
    width: 18px;
    height: 18px;

    path {
      fill: #DCDCDC;
    }
  }
}

.sidebar-menu__for-you-badge {
  gap: 4px;
  display: flex;
  align-items: center;
  background-color: #5956FF;
  border-radius: 20px;
  padding: 2px 4px;
  font-size: 12px;
  color: #FFFFFF;
  img {
    width: 10px;
    height: 10px;
  }
}

.sidebar-menu__badge {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: 24px;
  border-radius: 50%;
  padding: 0 4px;
  font-size: 14px;
  color: #FFFFFF;
  background-color: #D63789;
}

.options-list {
  position: absolute;
  left: 0;
  padding-left: 3.5rem;
  /* height: 100%; */
  display: flex;
  align-items: center;
  width: calc(100% - 2.7rem);
  font-size: 0.875rem;
  border-right: 2px solid #393f4c;
  cursor: pointer;
}

#sidebar .sections-wrapper {
  width: 100%;
  /* height: 100%; */
  position: relative;
  overflow: hidden;
}

#sidebar .sections-wrapper section.menu {
  /* overflow: hidden scroll !important; */
  /* width: calc(100% + 17px) !important; */
  position: relative;
  height: 100% !important;
  padding: 0 0.75rem;
  padding-top: 0;
}

#sidebar .sections-wrapper section.menu::-webkit-scrollbar {
  width: 2px;
  background-color: transparent;
}

#sidebar .sections-wrapper section.menu::-webkit-scrollbar-track {
  background-color: transparent;
}

#sidebar .sections-wrapper section.menu:hover::-webkit-scrollbar-thumb {
  background-color: #262e3d;
}

#sidebar .sections-wrapper section.menu>ul {
  width: 100%;
  height: 100%;
  padding: 0;
  color: var(--secondary-text-color);
  margin: 0;
  padding-top: 30px;

}

/* #sidebar .side-bar-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
} */

#sidebar .sections-wrapper section.menu:after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-60%) translateX(50%);
  transform: translateY(-60%) translateX(50%);
  height: 100%;
  width: 100%;
  background-image: radial-gradient(#ccd6f64f, transparent, transparent);
  display: block;
  background-size: 230% 217%;
  background-position: -7% 27%;
  z-index: -11;
  -webkit-filter: blur(1.25rem);
  filter: blur(1.25rem);
}

#sidebar .sections-wrapper section.menu>ul .buy-crypto {
  padding: 1rem;
  background: #0c121e;
  border-radius: 1rem;
  cursor: auto;
  margin-top: 2rem;
}

#sidebar .sections-wrapper section.menu>ul .buy-crypto .btn {
  border: 0.0625rem solid rgba(255, 255, 255, 0.06);
  background: #1b1f2e;
  height: 2.9375rem;
  color: #fff;
  border-radius: 0.75rem;
  font-size: 0.875rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 1.0625rem;
}

#sidebar .sections-wrapper section.menu>ul .buy-crypto .btn svg {
  height: 1.5625rem;
}

#sidebar .sections-wrapper section.menu>ul .buy-crypto .imgs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 0.75rem;
  gap: 1.375rem;
}

#sidebar .sections-wrapper section.menu>ul .buy-crypto .imgs img {
  height: 2.5rem;
}

#sidebar .sections-wrapper section.menu>ul .icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-transform: uppercase;
}

#sidebar .sections-wrapper section.menu>ul>li.static {
  position: relative;
  height: 1.5rem;
  margin-top: 1rem;
  border-top: 2px solid #282c3a;
  padding-top: 1.7rem;
  cursor: pointer;
}

#sidebar .sections-wrapper section.menu>ul>li.static.affiliate {
  border-top: 0px solid rgb(0, 0, 0);
  margin-top: 20px;
}

#sidebar .sections-wrapper section.menu>ul>li a {
  display: block;
  color: var(--secondary-text-color);
  width: 100%;
  text-decoration: none;
  font-family: "Poppins";
}

#sidebar .sections-wrapper section.menu>ul li.casino>.dropdown-menu {
  display: block;
}

#sidebar .sections-wrapper section.menu>ul .live-casino,
#sidebar .sections-wrapper section.menu>ul .slots {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

#sidebar .sections-wrapper section.menu>ul li.casino>ul>li .icon-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

#sidebar .sections-wrapper section.menu>ul .icon-shadow {
  margin-top: 0.625rem;
}

#sidebar .sections-wrapper section.menu .arrow-item,
#sidebar .sections-wrapper section.menu .esports,
#sidebar .sections-wrapper section.menu .promo {
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0 1.0375rem;
  height: 2.8125rem;
  border-radius: 1.875rem;
  border: 0.125rem solid #3c424ea6;
  padding-right: 0;
}

.fontStyle {
  font-size: 13px;
  font-weight: 400;
}

#sidebar .sections-wrapper section.menu .arrow-item a,
#sidebar .sections-wrapper section.menu .esports a,
#sidebar .sections-wrapper section.menu .promo a {
  position: absolute;
  left: 0;
  padding-left: 3.5rem;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: calc(100% - 2.7rem);
}

#sidebar .sections-wrapper section.menu>ul li.casino>ul>li.slots .icon-wrapper:before {
  background-image: url(../../assets/images/sidebar/casino.svg);
}

#sidebar .sections-wrapper section.menu>ul li.casino>ul>li.slots .icon-wrapper:before {
  content: "";
  background-repeat: no-repeat;
  margin-right: 0.625rem;
  display: block;
  height: 1.75rem;
  width: 1.75rem;
  background-size: 107%;
  background-position: -0.2rem center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

#sidebar .sections-wrapper section.menu>ul li.casino>ul>li.live-casino .icon-wrapper:after,
#sidebar .sections-wrapper section.menu>ul li.casino>ul>li.live-casino .icon-wrapper:before {
  background-size: 110%;
  background-position-x: -0.2rem;
}

#sidebar .sections-wrapper section.menu>ul li.casino>ul>li.slots .icon-wrapper:after,
#sidebar .sections-wrapper section.menu>ul li.casino>ul>li.slots .icon-wrapper:before {
}

#sidebar .sections-wrapper section.menu>ul li.casino>ul>li .icon-wrapper:after,
#sidebar .sections-wrapper section.menu>ul li.casino>ul>li .icon-wrapper:before {
  content: "";
  background-repeat: no-repeat;
  margin-right: 0.625rem;
  display: block;
  height: 1.75rem;
  width: 1.75rem;
  background-size: 107%;
  background-position: -0.2rem center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

#sidebar .sections-wrapper section.menu>ul .icon-shadow:after {
  content: "";
  position: absolute;
  left: 1.2375rem;
  -webkit-filter: blur(0.4rem);
  filter: blur(0.4rem);
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  -webkit-transform: scale(0.9) translateY(21.2%);
  transform: scale(0.9) translateY(21.2%);
  z-index: 1;
  pointer-events: none;
}

#sidebar .sections-wrapper section.menu>ul li.casino>ul>li .icon-wrapper:before {
  content: "";
  background-repeat: no-repeat;
  margin-right: 0.625rem;
  display: block;
  height: 1.75rem;
  width: 1.75rem;
  background-size: 107%;
  background-position: -0.2rem center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

#sidebar .sections-wrapper section.menu>ul>li.promo:after,
#sidebar .sections-wrapper section.menu>ul>li.promo:before {
  background-image: url(../../assets/images/sidebar/promos.svg);
  height: 1.6251rem;
  width: 1.6251rem;
  background-size: 104%;
  background-position: 0.1rem -0.2rem;
}

#sidebar .sections-wrapper section.menu>ul>li.promo:after {
  content: "";
  background-repeat: no-repeat;
  margin-right: 0.625rem;
  display: block;
  height: 1.75rem;
  width: 1.75rem;
  background-size: 107%;
  background-position: -0.2rem center;
  flex-shrink: 0;
}

#sidebar .sections-wrapper section.menu .arrow-item a:after,
#sidebar .sections-wrapper section.menu .esports a:after,
#sidebar .sections-wrapper section.menu .promos a:after {
  content: "";
  position: absolute;
  right: 0;
  width: 1px;
  background: #3c424ea6;
  top: -0.1rem;
  height: 106%;
}

#sidebar .sections-wrapper section.menu .arrow-item .arrow,
#sidebar .sections-wrapper section.menu .esports .arrow,
#sidebar .sections-wrapper section.menu .promo .arrow {
  background-image: url(../../assets/images/sidebar/arrow-right.svg);
  background-repeat: no-repeat;
  background-position: center;
  height: 3.2rem;
  width: 2.8125rem;
  -webkit-transition: 0.1s ease;
  transition: 0.1s ease;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  position: relative;
  background-size: 1.1rem;
  cursor: pointer;
}

#sidebar .sections-wrapper section.menu>ul li.casino #casino,
#sidebar .sections-wrapper section.menu>ul li.casino #livecasino {
  width: 95%;
  border-top: 1px solid #3c424e;
  margin: 0.9375rem auto;
  margin-bottom: 0px !important;
  /* border-bottom: 1px solid #3c424e; */
  /* padding-bottom: 0.9375rem; */
}

#sidebar .sections-wrapper section.menu>ul li.casino .close-box {
  opacity: 0;
  padding: 0 !important;
  margin: 0 !important;
  height: 0;
  transition: height 0.4s ease-in-out 0s;
}

#sidebar .sections-wrapper section.menu>ul li.casino .open-box {
  /* height: 458px; */
  transition: height 0.4s ease-in-out 0s;
  overflow: auto;
}

#sidebar .sections-wrapper section.menu>ul li.casino #casino li,
#sidebar .sections-wrapper section.menu>ul li.casino #livecasino li {
  margin-top: 0.875rem;
}

.active-nav-item {
  color: #00ec66 !important;
}

#sidebar .sections-wrapper section.menu>ul li.casino #casino li a,
#sidebar .sections-wrapper section.menu>ul li.casino #livecasino li a {
  padding-left: 2rem;
  font-size: 0.875rem;
  color: var(--secondary-text-color);
  font-weight: 400;
}

#sidebar .sections-wrapper section.menu>ul li.casino #casino li.active a,
#sidebar .sections-wrapper section.menu>ul li.casino #casino li:hover a,
#sidebar .sections-wrapper section.menu>ul li.casino #livecasino li.active a,
#sidebar .sections-wrapper section.menu>ul li.casino #livecasino li:hover a {
  color: var(--white);
}

#sidebar .dropdown-menus {
  padding: 0;
}

#sidebar .sections-wrapper section.menu .arrow-item.active,
#sidebar .sections-wrapper section.menu .esports.active,
#sidebar .sections-wrapper section.menu .promo.active {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: -0.0625rem;
  background: linear-gradient(-45deg,
      rgba(12, 18, 30, 0.9),
      rgba(22, 26, 39, 0.9));
  z-index: 999;
  border: 0.125rem solid transparent;
}

#sidebar .sections-wrapper section.menu .arrow-item.active .arrow,
#sidebar .sections-wrapper section.menu .esports.active .arrow,
#sidebar .sections-wrapper section.menu .promo.active .arrow {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

#sidebar .sections-wrapper section.menu .arrow-item a,
#sidebar .sections-wrapper section.menu .esports a,
#sidebar .sections-wrapper section.menu .promo a {
  position: absolute;
  left: 0;
  padding-left: 3.5rem;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: calc(100% - 2.7rem);
  font-size: 0.875rem;
}

#sidebar .sections-wrapper section.menu .arrow-item a:after,
#sidebar .sections-wrapper section.menu .esports a:after {
  content: "";
  position: absolute;
  right: 0;
  width: 1px;
  background: #3c424ea6;
  top: -0.1rem;
  height: 106%;
}

#sidebar .sections-wrapper section.menu .arrow-item a:before,
#sidebar .sections-wrapper section.menu .esports a:before,
#sidebar .sections-wrapper section.menu .promo a:before {
  display: none !important;
}

#sidebar .sections-wrapper section.menu>ul>li.static:after,
#sidebar .sections-wrapper section.menu>ul>li.static:before {
  overflow: unset !important;
  overflow: unset !important;
  height: 1.6251rem;
  width: 1.6251rem;
}

#sidebar .sections-wrapper section.menu>ul>li.static:after {
  top: 1.2rem;
  left: -0.3625rem;
  -webkit-filter: blur(0.3rem);
  filter: blur(0.3rem);
  -webkit-transform: scale(1.3) translateY(-24.8%);
  transform: scale(1.3) translateY(-24.8%);
}

#sidebar .sections-wrapper section.menu>ul .icon:before {
  content: "";
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 0.625rem;
  display: block;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

#sidebar .sections-wrapper section.menu>ul .icon-shadow {
  margin-top: 0.625rem;
}

#sidebar .sections-wrapper section.menu>ul .icon-shadow:before {
  z-index: 3;
  pointer-events: none;
}

#sidebar .sections-wrapper section.menu>ul .icon-shadow:after {
  content: "";
  position: absolute;
  left: 1.2375rem;
  -webkit-filter: blur(0.4rem);
  filter: blur(0.4rem);
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  -webkit-transform: scale(0.9) translateY(21.2%);
  transform: scale(0.9) translateY(21.2%);
  z-index: 1;
  pointer-events: none;
}

#sidebar .sections-wrapper section.menu>ul .icon-shadow.active:after {
  -webkit-animation: glow 0.5s ease-out;
  animation: glow 0.5s ease-out;
  left: 1.0375rem;
  -webkit-transform: scale(1.3) translateY(7.8%);
  transform: scale(1.3) translateY(7.8%);
}

@keyframes glow {
  from {
    -webkit-transform: scale(1.1) translateY(21.2%);
    transform: scale(1.1) translateY(21.2%);
    left: var(--sideBarWidth);
  }
}

#sidebar .sections-wrapper section.menu>ul>li.static.support:after,
#sidebar .sections-wrapper section.menu>ul>li.static.support:before {
  background-size: 180%;
  background-image: url(../../assets/images/icons/live-chat43a0.svg);
}

#sidebar .sections-wrapper section.menu>ul>li.static.affiliate:after,
#sidebar .sections-wrapper section.menu>ul>li.static.affiliate:before {
  background-size: 150%;
  background-image: url(../../assets/images/icons/affiliate43a0.svg);
}

#sidebar .sections-wrapper section.menu>ul>li a:hover {
  color: var(--white);
}

#sidebar .close {
  display: none;
}

.all-games-down {
  width: 24px !important;
  height: 24px !important;

  /* padding: 0 14px 0 0px; */
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(90deg);
  margin-right: 10px;
}


.menu-open {
  transform: rotate(270deg);




}

#sidebar .sections-wrapper section.menu>ul>li.all-games-mobile {
  display: none !important;
}

/* ========================================================================= */
/* ================================ media query ============================ */
/* ========================================================================= */
@media screen and (max-width: 1024.98px) {
  #sidebar {

  }
}

@media screen and (max-width: 991.98px) {
  #sidebar {
    width: 100%;
    border-radius: 0;
    border: none;
    position: absolute;
    background: #11212E;
    padding: 24px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;
  }

  #sidebar.visible {
    visibility: visible;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  #sidebar.box::before,
  #sidebar.box::after,
  #sidebar .sections-wrapper section.menu:after,
  #sidebar .left {
    display: none;
  }

  #sidebar .sections-wrapper section.menu>ul {
    padding-top: 0;
  }

  #sidebar .close {
    display: block;
    position: absolute;
    top: 0.65rem;
    right: 1.5625rem;
    color: #97a1c2;
    font-size: 1.5rem;
    z-index: 99999;
    cursor: pointer;
  }

  #sidebar .close img {
    height: 3rem;
  }

  #sidebar .sections-wrapper section.menu {
    padding: 0;
    height: unset;
  }

  #sidebar .sections-wrapper section.menu>ul .buy-crypto {
    background: #1b1f2e;
  }

  #sidebar .sections-wrapper section.menu>ul .buy-crypto .btn {
    border: 0.0625rem solid rgba(255, 255, 255, 0.1);
    background: #0c121e;
  }

  #sidebar .sections-wrapper section.menu .arrow-item .arrow,
  #sidebar .sections-wrapper section.menu .esports .arrow,
  #sidebar .sections-wrapper section.menu .promo .arrow,
  #sidebar .sections-wrapper section.menu .arrow-item a:after,
  #sidebar .sections-wrapper section.menu .esports a:after,
  #sidebar .sections-wrapper section.menu>ul li.casino>ul>li.slots .icon-wrapper:after,
  #sidebar .sections-wrapper section.menu>ul li.casino>ul>li.live-casino .icon-wrapper:after,
  #sidebar .sections-wrapper section.menu>ul>li.promo:after,
  #sidebar .sections-wrapper section.menu>ul>li.static:after {
    display: none;
  }

  nav#sidebar .wrapper section.menu ul.nav-list>li a span,
  #sidebar .sections-wrapper section.menu .arrow-item a,
  #sidebar .sections-wrapper section.menu .esports a,
  #sidebar .sections-wrapper section.menu .promo a {
    font-weight: 600;
  }

  #sidebar .sections-wrapper {
    height: 100vh;
    overflow: initial;
  }

  #sidebar .sections-wrapper section.menu .arrow-item.active,
  #sidebar .sections-wrapper section.menu .esports.active,
  #sidebar .sections-wrapper section.menu .promo.active {
    background: #1e2331;
  }
}

.options-inner {
  padding-left: 2rem;
  font-size: 0.875rem;
  color: var(--secondary-text-color);
  font-weight: 400;
  display: block;
  width: 100%;
  text-decoration: none;
  font-family: "Poppins";
  cursor: pointer;
  /* #ccd6f6 */
}

@media screen and (max-width: 767.98px) {

  .all-games-down {
    display: none;
  }

  .options-list {
    border-right: 0px;
  }

  #sidebar .sections-wrapper section.menu>ul>li.all-games-mobile {
    display: block !important;
  }

  #sidebar .sections-wrapper section.menu>ul>li.all-games-mobile::before {
    background-size: 120% !important;
    background-image: url(../../assets/images/sidebar/casino.svg) !important;
  }

  #sidebar .sections-wrapper section.menu>ul>li.all-games-mobile {
    /* background-image: url(../../assets/images/sidebar/casino.svg); */
    display: flex !important;
    align-items: center;
    margin-left: 2px;
    position: relative;
    height: 1.5rem;
    margin-top: 1rem;
    border-top: none !important;
    padding-top: 1.7rem;
  }

  #sidebar .sections-wrapper section.menu>ul>li.static {
    position: relative;
    height: 1.5rem;
    margin-top: 1rem;
    border-top: none !important;
    padding-top: 1.7rem;
  }

  .slots {
    display: none !important;
  }
}