.promocode {
  width: 480px;
  margin: 0 12px;
  gap: 22px;
  display: flex;
  flex-direction: column;
  background-color: #11212E;
  border-radius: 24px;
  box-shadow: 3px -3px 12px -7px rgba(144,162,175,0.44);
  padding: 22px;
  
  p {
    margin-bottom: 0 !important;
  }

  @media screen and (max-width: 480px) {
    width: 400px;
    padding: 16px;

    .promocode__input-content {
      flex-direction: column;
      button {
        width: 100%;
      }
    }
  }

  ::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
  }
  
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  :hover::-webkit-scrollbar-thumb {
    background: linear-gradient(129.51deg, #FF9F00 -12.53%, #FFDB8C 82.06%);
  }
}

.promocode__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.promocode__header-title {
  gap: 12px;
  display: flex;
  align-items: center;
  color: #FFFFFF;

  >svg {
    width: 20px;
    height: 16px;
  }

  p {
    font-size: 24px;
    font-weight: 800;
    line-height: 24px;
  }
}

.promocode__close-button {
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background-color: #253749;
  margin: 0;
  border: 3px solid #FFFFFF0D;
  border-radius: 12px;
  box-shadow: 0px 9px 39.6px 0px #0700214D;
  max-height: 36px;

  svg {
    width: 16px;
    height: 16px;
  }
}

.promocode__content {
  display: flex;
  flex-direction: column;
  gap: 22px;
}

.promocode__input-content {
  display: flex;
  align-items: flex-end;
  gap: 12px;

  .promocode__input-wrapper {
    gap: 6px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    label {
      font-size: 16px;
      font-weight: 600;
      line-height: 21px;
      color: #90A2AF;
    }

    input {
      border: 0;
      color: #FFFFFF;
      font-size: 16px;
      font-weight: 600;
      background-color: #09151E;
      border-radius: 16px;
      height: 52px;
      width: 100%;
    }
  }

  button {
    height: 52px;
    padding: 0 14px;
    color: #101010;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    border-radius: 16px;
    background: linear-gradient(129.51deg, #FF9F00 -12.53%, #FFDB8C 82.06%);

    &:hover {
      opacity: 0.8;
    }
  }
}

.promocode__banner {
  height: 95px;
  width: 100%;
  position: relative;
  padding: 23px 16px;
  border-radius: 16px;
  color: #FFFFFF;
  background: linear-gradient(255.49deg, #44BCFF 4.73%, #17AFA6 99.27%);
  box-shadow: inset 0 0 0 2px #FFFFFF26;
  backdrop-filter: blur(13.5px);
  overflow: hidden;

  .promocode__banner-title {
    font-family: Druk Wide Bold;
    font-size: 20px;
    font-weight: 700;
    line-height: 26.12px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

  }

  .promocode__banner-description {
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
  }

  .promocode__banner-image {
    top: 0;
    right: 0;
    z-index: -1;
    position: absolute;
  }
}

.promocode__divider {
  width: 100%;
  height: 1px;
  border: 1px solid #1E3241;
}

.promocode__history {
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-height: 300px;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

.promocode__history-header {
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  color: #FFFFFF;
}

.promocode__list {
  gap: 6px;
  display: flex;
  flex-direction: column;
}

.promocode__list-item {
  width: 100%;
  display: inline-grid;
  justify-content: center;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 14px 16px;
  border-radius: 16px;
  background-color: #1E324199;

  p {
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    color: #90A2AF;
    &:first-of-type {
      justify-self: start;
    }
    &:last-of-type {
      justify-self: end;
    }
  }
}

.promocode__item-wrapper {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-self: center;
  img {
    width: 20px;
    height: 20px;
  }
}

