.transection-dialog {
  width: 480px;
  margin: 0 12px;
  display: flex;
  flex-direction: column;
  background-color: #11212E;
  border-radius: 24px;
  box-shadow: 3px -3px 12px -7px rgba(144,162,175,0.44);

  p {
    margin-bottom: 0 !important;
  }

  @media screen and (max-width: 480px) {
    width: 400px;
  }
}

.transection-dialog__header {
  height: 69px;
  display: flex;
  justify-content: space-between;
  background-color: #09151E;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  overflow: hidden;
  padding-left: 12px;

  @media screen and (max-width: 480px) { 
    padding: 0;
  }

}

.transection-dialog__header-wrapper {
  display: flex;
  justify-content: space-between;
}

.transection-dialog__header-button {
  position: relative;
  gap: 9px;
  display: flex;
  align-items: center;
  height: 100%;
  color: #90A2AF;
  background-color: unset;
  border: unset;
  max-height: 69px;
  padding: 0 18px;
  font-size: 16px;

  &.--active {
    color: #FFFFFF;

    .transection-dialog__header-active-indicator {
      width: 72px;
      position: absolute;
      bottom: 0px;
      border: 1px solid #FFDB8C;
      box-shadow: 0px 1px 10.4px 0px #EF9645;

    }
  }

  @media screen and (max-width: 480px) {
    font-size: 14px;
    padding: 0 12px;
  }
}


.transection-dialog__header-close {
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background-color: #253749;
  margin: 0 16px 0 0;
  border: 3px solid #FFFFFF0D;
  border-radius: 12px;
  box-shadow: 0px 9px 39.6px 0px #0700214D;
  max-height: 36px;

  svg {
    width: 16px;
    height: 16px;
  }
}

.transection-dialog__content {
  padding: 22px 18px;
}

.transection-dialog__form-item {
  gap: 8px;
  display: flex;
  flex-direction: column;
  position: relative;
  height: fit-content;

  .select {
    height: 52px;
  }

  label {
    color: #90A2AF;
  }
}

.transection-dialog__deposit-wrapper {
  display: flex;
  flex-direction: column;
  gap: 42px;
  margin-bottom: 18px;
}

.transection-dialog__deposit-promotion {
  background-color: unset;
  border-radius: 16px;
  border: unset;
  height: 81px;
  background-image: url("../../../assets/images/header/wallet/deposit-promotion.svg");
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (max-width: 429px) {
    background-image: url("../../../assets/images/header/wallet/deposit-promotion-mobile.svg");
    background-repeat: no-repeat;
    background-size: 100% 86px;
    height: 86px;
  }
}

.transection-dialog__deposit-select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 52px;
  padding: 0 24px;
  background: #1E324199;
  backdrop-filter: blur(13.5px);
  border: 2px solid #FFFFFF1A;
  border-radius: 16px;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }
}

.select-options {
  position: relative;
  z-index: 10;
  border-radius: 16px;
  border: 1px solid #FFFFFF1A;
  background-color: #1E3241;

  li {
    cursor: pointer;
    gap: 6px;
    display: flex;
    align-items: center;
    padding: 12px 16px;
    background-color: #1E324199;
    border-block-start: 1px solid #FFFFFF1A;
    text-transform: capitalize;

    &:hover {
      opacity: 0.7;
    }

    img {
      width: 20px;
      height: 20px;
    }

    &:first-of-type {
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    }
    &:last-of-type {
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
    }
  }
}

.transection-dialog__adress-item {
  display: flex;
  flex-direction: row;
  gap: 18px;

  @media screen and (max-width: 480px) {
    flex-direction: column;
    align-items: center;
  }
}

.transection-dialog__input-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
  color: #90A2AF;

  input {
    background-color: #09151E;
    height: 52px;
    border: 0;
    border-radius: 16px;
    color: #FFFFFF;
  }
}

.transection-dialog__qrcode-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 16px;
  background-color: #FFFFFF;
  max-height: 130px;
  max-width: 130px;

  @media screen and (max-width: 480px) {
    max-height: 150px;
    max-width: 150px;
  }
}

.transection-dialog__copy-icon {
  position: absolute;
  cursor: pointer;
  right: 20px;
  top: 37%;
}

.transection-dialog__text-warning {
  font-size: 12px;
  font-weight: 600;
  border-radius: 12px;
  padding: 6px 12px;
  margin-top: 6px;
  color: #FD9632;
  width: fit-content;
  background-color: #FD963233;
}

.transection-dialog__withdraw-wrapper {
  display: flex;
  flex-direction: column;
  justify-items: center;
  gap: 18px;
}


.transection-dialog__withdraw-amount {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 52px;
  padding: 0 24px;
  border: 0;
  background: #09151E;
  border-radius: 16px;
}

.transection-dialog__current-balance {
  position: absolute;
  top: 35%;
  right: 15px;
  font-size: 12px;
  font-weight: 600;
  color: #90A2AF;

  &::-webkit-inner-spin-button {
    display: none;
  }
}

.transection-dialog__amount-buttons {
  gap: 6px;
  display: flex;
  justify-content: space-between;

  button {
    flex: 1;
    padding: 8px 0;
    color: #90A2AF;
    font-size: 12px;
    background-color: #253749;
    border-radius: 12px;
  }
}

.transection-dialog__withdraw-values {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 600;
  color: #90A2AF;
  margin-top: 4px;;

 :last-child {
    color: #FFFFFF;
  }
}

.transection-dialog__confirm-button {
  border-radius: 16px;
  color: #101010;
  font-weight: 700;
  height: 52px;
  background: linear-gradient(129.51deg, #FF9F00 -12.53%, #FFDB8C 82.06%);
}

.transection-dialog__history {
  gap: 18px;
  display: flex;
  flex-direction: column;
}

.transection-dialog__history-header {
  display: flex;

  .transection-dialog__history-tab {
    height: 37px;
    padding: 0 18px;
    border: unset;
    border-radius: 12px;
    background-color: transparent;
    color: #90A2AF;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0%;

    &.--active {
      color: #FFFFFF;
      background-color: #253749; 
    }
  }
}

.transection-dialog__history-wrapper {
  display: flex;
  flex-direction: column;
  max-height: 330px;
  overflow-y: scroll;

  .no-data {
    background-color: #11212E !important;
  }
}

.transection-dialog__history-list {
  gap: 6px;
  display: flex;
  flex-direction: column;
}

.transection-dialog__list-item {
  gap: 30px;
  display: flex;
  padding: 14px 16px;
  border-radius: 16px;
  background-color: #1E324199;

  img {
    height: 16px;
    width: 16px;

  }

  .transection-dialog__list-col {
    flex: 1;
    gap: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #FFFFFF;

    >p {
      &.--gray-text {
        color: #90A2AF;
      }
    }

    .transection-dialog__col-subitem {
      gap: 8px;
      display: flex;
      align-items: center;
    }
  }

  @media screen and (max-width: 480px) {
    gap: 12px;
    flex-direction: column;
  }
}

.transection-dialog__history-badge {
  padding: 6px 12px;
  border-radius: 12px;
  height: 33px;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0%;
  

  &.in_proccess {
    color: #FD9632;
    background-color: #FD963233;
  }

  &.complete {
    color: #44D50F;
    background-color: #44D50F33;
  }

  &.rejected {
    color: #FF2F2F;
    background-color: #FF2F2F33;
  }
}