/* ===================================== side bar========================================== */
.account-content {
  display: flex;
  align-items: flex-start;
  position: relative;
}

main nav.account-sidebar {
  position: absolute;
  left: 0;
  top: 0;
  width: 16.875rem !important;
  overflow: hidden;
  height: 100%;
  -webkit-transition: 0s !important;
  transition: 0s !important;
  border-radius: 1.25rem !important;
  margin: inherit;
}

main nav.account-sidebar .g-title {
  padding-left: 1.375rem;
  margin-bottom: 0.625rem;
  background: #161a27;
}

main nav.account-sidebar .dashed-hr {
  border-top: 0.0625rem dashed #474646;
  border-bottom: none;
  border-left: none;
  border-right: none;
  margin: 1.6875rem auto 0;
  width: calc(100% - 3.125rem);
}

main nav.account-sidebar .account-menu-ul-wrapper {
  padding-top: 1.0625rem;
}

main nav.account-sidebar .account-menu-ul-wrapper .account-menu-ul-title {
  height: 2.5rem;
  line-height: 2.5rem;
  width: calc(100% - 3.125rem);
  margin: 0 auto;
  cursor: default;
}

main nav.account-sidebar .account-menu-ul-wrapper .account-menu-ul-title span {
  color: #8997a2;
  vertical-align: middle;
}

main nav.account-sidebar .account-menu-ul-wrapper .account-menu-ul-title span:first-child {
  font-size: 1.1875rem;
}

main nav.account-sidebar .account-menu-ul-wrapper .account-menu-ul-title span:last-child {
  margin-left: 0.3125rem;
  font-size: 0.8438rem;
}

main nav.account-sidebar ul li {
  position: relative;
  height: 2.8125rem;
  line-height: 2.8125rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: var(--secondary-text-color);
}

main nav.account-sidebar ul li:first-child img {
  -webkit-filter: brightness(0) invert(26%) sepia(91%) saturate(1426%) hue-rotate(227deg) brightness(104%) contrast(92%);
  filter: brightness(0) invert(26%) sepia(91%) saturate(1426%) hue-rotate(227deg) brightness(104%) contrast(92%);
}

main nav.account-sidebar ul li a.active img {
  -webkit-filter: invert(74%) sepia(40%) saturate(3342%) hue-rotate(91deg) brightness(98%) contrast(103%);
  filter: invert(74%) sepia(40%) saturate(3342%) hue-rotate(91deg) brightness(98%) contrast(103%);
}

main nav.account-sidebar ul li.active,
main nav.account-sidebar ul li:hover {
  color: var(--white);
}

main nav.account-sidebar ul li a,
main nav.account-sidebar ul li .sidebar-click-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-transition: opacity 120ms ease-in;
  transition: opacity 120ms ease-in;
  padding-left: 1.5625rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: normal;
  font-weight: 400;
  cursor: pointer;
}

main nav.account-sidebar ul li a.active {
  color: #fff;
}

main nav.account-sidebar ul li a.active img {
  -webkit-filter: invert(74%) sepia(40%) saturate(3342%) hue-rotate(91deg) brightness(98%) contrast(103%);
  filter: invert(74%) sepia(40%) saturate(3342%) hue-rotate(91deg) brightness(98%) contrast(103%);
}

main nav.account-sidebar ul li a img,
main nav.account-sidebar ul li .sidebar-click-content img {
  width: 1.6rem;
  margin-right: 0.75rem;
}

main nav.account-sidebar .g-title {
  padding-left: 1.375rem;
  margin-bottom: 0.625rem;
  background: #161a27;
}

main .g-title {
  position: relative;
  background-color: #1e1e25;
  height: 3.25rem;
  line-height: 3.25rem;
  color: var(--white);
  font-size: 0.9375rem;
  letter-spacing: 0.05rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-left: 0.4375rem solid var(--orange-primary);
  padding-left: 1.875rem;
}

/* ===================================== dashboard ======================================== */
main .page-wrapper .dashboard-page-wrapper section {
  min-height: inherit;
  margin: 0;
}

.bottom-20 {
  bottom: 20px;

}

main .page-wrapper {
  position: relative;
  float: left;
  width: calc(100% - 17.775rem);
  overflow: hidden;
  min-height: 53.75rem;

  margin-left: 17.775rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  border: var(--surface-border);
  display: block;
  border-radius: 1.5rem;
  background: linear-gradient(101deg, #1a1e2b, #0c121e2b 52%, #171c28);
  position: relative;
  border-radius: 1.25rem !important;
}

.custom-date-align {
  padding-left: 16px !important;
}

main .page-wrapper>ul {
  padding: 1.9375rem 1.75rem;
}

main .page-wrapper:after,
main .page-wrapper:before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  background-image: radial-gradient(rgba(204, 214, 246, 0.116),
      transparent,
      transparent);
  z-index: -1;
  pointer-events: none;
}

main .page-wrapper:before {
  top: 0;
  height: 20rem;
  background-repeat: no-repeat;
  background-position: 98% 130%;
  background-size: 200% 200%;
}

main .page-wrapper:after {
  height: 6rem;
  background-repeat: no-repeat;
  background-position: 55% 2%;
  background-size: 194% 305%;
  bottom: 0;
}

main .page-wrapper ul.fields li {
  position: relative;
  overflow: hidden;
  height: 3rem;
  line-height: 3rem;
  font-size: 0.9375rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

main .page-wrapper ul.fields li .desc,
main .page-wrapper ul.fields li .info {
  float: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.875rem;
  height: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

main .page-wrapper ul.fields li .desc.desc,
main .page-wrapper ul.fields li .info.desc {
  width: 7.5rem;
  color: rgba(255, 255, 255, 0.5);
  padding-left: 0.125rem;
}

main .page-wrapper ul.fields li .desc.info,
main .page-wrapper ul.fields li .info.info {
  width: calc(100% - 10.625rem);
  padding-left: 0.9375rem;
  overflow: hidden;
  color: var(--white);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/* =========================================================== */

main .page-wrapper {
  padding: 2.9375rem 2.75rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

main .page-wrapper .personal-info ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

main .page-wrapper .personal-info ul li {
  height: 8.075rem;
  background: linear-gradient(135deg, #363e4c 21%, #1c2330 79%);
  border-radius: 0.1875rem;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: calc(100% / 2 - 0.7937rem);
  margin-left: 1.1875rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 0.4895rem;
}

main .page-wrapper .personal-info ul li:first-child {
  padding: 0 1.75rem;
  margin-left: 0;
}

main .page-wrapper .personal-info ul li .left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

main .page-wrapper .personal-info ul li .content {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

main .page-wrapper .personal-info ul li .content .icon {
  margin-right: 0.9375rem;
  color: var(--orange-primary);
  font-size: 2.25rem;
}

main .page-wrapper .personal-info ul li .content span:not(.icon) {
  color: #fff;

  font-size: 1.3125rem;
}

main .page-wrapper .personal-info ul li .content .status {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

main .page-wrapper .personal-info ul li .content .status span {
  font-size: 0.75rem;
}

main .page-wrapper .personal-info ul li .content .status span.verification {
  font-size: 1.3125rem;

  color: #fff;
  margin-bottom: 0.125rem;
}

main .page-wrapper .personal-info ul li.balance {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

main .page-wrapper .personal-info ul li.balance .left {
  margin-right: 0;
}

main .page-wrapper .personal-info ul li.balance .left>span {
  color: rgba(255, 255, 255, 0.5);
  font-size: 1rem;

  width: 100%;
  text-align: center;
}

main .page-wrapper .personal-info ul li.balance .left .amount {
  color: #fff;
  font-size: 1.25rem;

  padding: 0.1875rem 0;
}

main .page-wrapper .personal-info ul li.balance .left .amount span:last-child {
  color: #bfbfbf;
}

main .page-wrapper .personal-info ul li.balance .deposit {
  font-size: 1.25rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-width: 7.5rem;
  height: 2.6875rem;
  border-radius: 0.1875rem;
  background: var(--orange-primary);
  color: #fff;
}

main .page-wrapper .personal-info ul li.my-profile {
  -webkit-transition: 0.2s;
  transition: 0.2s;
  cursor: pointer;
}

main .page-wrapper .personal-info ul li.my-profile:hover {
  -webkit-filter: brightness(1.3);
  filter: brightness(1.3);
}

main .page-wrapper .personal-info ul li.not-verified {
  /* display: none; */
  background: linear-gradient(135deg, #363e4c 21%, #973434 79%);
}

main .page-wrapper .personal-info ul li.not-verified .icon {
  color: red;
}

main .page-wrapper .personal-info ul li.verified {
  /* display: none; */
}

main .page-wrapper section>h3 {
  text-align: center;
  font-size: 1.0625rem;

  height: 4.375rem;
  line-height: 4.375rem;
}

main .page-wrapper section>h3 a {
  color: var(--orange-primary);
  margin-left: 0.9375rem;
  text-decoration: underline;
}

main .page-wrapper section.bonuses .no-bonus {
  height: 8rem;
  text-align: center;
  background-size: cover;
  /* background-image: url(../../../assets/images/dashboard/bonusbg1b26.html?v2); */
  display: none;
}

main .page-wrapper section.bonuses .no-bonus .btn {
  background-color: var(--orange-primary);
  width: 12.5rem;
  height: 4.6875rem;
  margin-top: 1.6875rem;

  font-size: 1.25rem;
}

main .page-wrapper section.bonuses .bonus-list {
  position: relative;
  display: none;
}

main .page-wrapper section.bonuses .bonus-list .arrow {
  position: absolute;
  top: 50%;
  margin-top: -1.25rem;
  font-size: 2.5rem;
  color: #fff;
  cursor: pointer;
  display: none;
}

main .page-wrapper section.bonuses .bonus-list .arrow:first-child {
  left: -2.5rem;
}

main .page-wrapper section.bonuses .bonus-list .arrow:last-child {
  right: -2.5rem;
}

main .page-wrapper section.bonuses .bonus-list .list-wrapper {
  overflow: hidden;
}

main .page-wrapper section.bonuses .bonus-list .list-wrapper ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

main .page-wrapper section.bonuses .bonus-list .list-wrapper ul li {
  width: 24.625rem;
  height: 16.4375rem;
  border-radius: 0.625rem;
  overflow: hidden;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  background-color: #1c2330;
  text-align: center;
}

main .page-wrapper section.bonuses .bonus-list .list-wrapper ul li+li {
  margin-left: 1.25rem;
}

main .page-wrapper section.bonuses .bonus-list .list-wrapper ul li .bonus-banner {
  height: 9.375rem;
  background-size: cover;
  border-radius: 0.625rem;
  /* background-image: url(../../../assets/images/dashboard/bg11b26.html?v2); */
}

main .page-wrapper section.bonuses .bonus-list .list-wrapper ul li h2 {
  font-size: 1.3125rem;
  font-weight: bold;
  margin-top: 2.1875rem;
}

main .page-wrapper section.bonuses .bonus-list .list-wrapper ul li a {
  font-size: 1.125rem;

  margin-top: 0.6875rem;
  display: inline-block;
  color: #88bfff;
}

main .page-wrapper section.bonuses .bonus-list .list-wrapper ul li a:hover {
  text-decoration: underline;
}

main .page-wrapper section.messages .messages-wrapper ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  height: 4.5625rem;
  border-radius: 0.3125rem;
  margin-bottom: 0.125rem;
  padding: 0 1rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

main .page-wrapper section.messages .messages-wrapper ul li:hover {
  background: rgba(0, 0, 0, 0.1);
}

main .page-wrapper section.messages .messages-wrapper ul li .left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

main .page-wrapper section.messages .messages-wrapper ul li .left .icon {
  font-size: 1.5rem;
  color: var(--orange-primary);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  margin-right: 1.125rem;
}

main .page-wrapper section.messages .messages-wrapper ul li .left .welcome {
  font-size: 0.9375rem;
  font-weight: bold;
  color: #fff;
  margin-bottom: 0.125rem;
}

main .page-wrapper section.messages .messages-wrapper ul li .left span {
  font-size: 0.8125rem;
}

main .page-wrapper section.messages .messages-wrapper ul li .right {
  color: rgba(255, 255, 255, 0.9);
  text-align: right;
  font-size: 0.8125rem;
}

main .page-wrapper section.wallets ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

main .page-wrapper section.wallets ul li {
  border-radius: 0.9375rem;
  background-color: #3c3b43;
  width: calc(100% / 6 - 2.5rem);
  height: 4.375rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0 0.625rem;
  margin: 0.625rem;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}

main .page-wrapper section.wallets ul li span:first-child {
  color: #fff;
  margin-right: 0.3125rem;
}

main .page-wrapper section.wallets ul.add li:hover {
  background: var(--orange-primary);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

main .page-wrapper section.wallets .err-text {
  text-align: center;
}

main .page-wrapper section.transactions table {
  border-collapse: collapse;
  width: 100%;
}

main .page-wrapper section.transactions table td,
main .page-wrapper section.transactions table th {
  text-align: left;
  height: 2.5rem;
  padding-left: 3.4375rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

main .page-wrapper section.transactions table thead {
  background: rgba(255, 255, 255, 0.04);
  color: inherit;
}

main .page-wrapper section.transactions table thead tr th {
  font-weight: 400;
  text-align: center;
  white-space: nowrap;

}

main .page-wrapper section.transactions table tbody tr {
  background: rgba(0, 0, 0, 0.2);
  border-top: 0.0625rem solid rgba(255, 255, 255, 0.03);
  font-size: 0.8125rem;
  color: #fff;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

main .page-wrapper section.transactions table tbody tr:hover {
  background: rgba(255, 255, 255, 0.05);
}

main .page-wrapper,
main .page-wrapper {
  padding: 2.9375rem 2.75rem !important;
  /* padding: 2.1875rem 4.0625rem; */
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

main .page-wrapper .personal-info ul,
main .page-wrapper .personal-info ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

main .page-wrapper .personal-info ul li:first-child,
main .page-wrapper .personal-info ul li:first-child {
  padding: 0 1.75rem;
  margin-right: 0;
}

main .page-wrapper .personal-info ul li .left,
main .page-wrapper .personal-info ul li .left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

main .page-wrapper .personal-info ul li .content,
main .page-wrapper .personal-info ul li .content {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

main .page-wrapper .personal-info ul li .content .icon,
main .page-wrapper .personal-info ul li .content .icon {
  margin-left: 0.9375rem;
  color: var(--orange-primary);
  font-size: 2.25rem;
}

main .page-wrapper .personal-info ul li .content span:not(.icon),
main .page-wrapper .personal-info ul li .content span:not(.icon) {
  color: #fff;
  font-weight: 400;
  font-size: 1.3125rem;
}

main .page-wrapper .personal-info ul li .content .status,
main .page-wrapper .personal-info ul li .content .status {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

main .page-wrapper .personal-info ul li .content .status span,
main .page-wrapper .personal-info ul li .content .status span {
  font-size: 0.75rem;
}

main .page-wrapper .personal-info ul li .content .status span.verification,
main .page-wrapper .personal-info ul li .content .status span.verification {
  font-size: 1.3125rem;

  color: #fff;
  margin-bottom: 0.125rem;
}

main .page-wrapper .personal-info ul li.balance .left>span,
main .page-wrapper .personal-info ul li.balance .left>span {
  color: rgba(255, 255, 255, 0.5);
  font-size: 1rem;
  font-weight: 400;
  width: 100%;
  text-align: center;
}

main .page-wrapper .personal-info ul li.balance .left .amount,
main .page-wrapper .personal-info ul li.balance .left .amount {
  color: #fff;
  font-size: 1.25rem;
  font-weight: 400;
  margin: 0;
}

main .page-wrapper .personal-info ul li.balance .left .amount span:last-child,
main .page-wrapper .personal-info ul li.balance .left .amount span:last-child {
  color: #bfbfbf;
}

main .page-wrapper .personal-info ul li.balance .deposit,
main .page-wrapper .personal-info ul li.balance .deposit {
  font-size: 1.25rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-width: 7.5rem;
  height: 2.6875rem;
  border-radius: 0.1875rem;
  background: var(--orange-primary);
  font-weight: 400;
}

main .page-wrapper .personal-info ul li.my-profile,
main .page-wrapper .personal-info ul li.my-profile {
  -webkit-transition: 0.2s;
  transition: 0.2s;
  cursor: pointer;
}

main .page-wrapper .personal-info ul li.my-profile:hover,
main .page-wrapper .personal-info ul li.my-profile:hover {
  -webkit-filter: brightness(1.3);
  filter: brightness(1.3);
}

main .page-wrapper .personal-info ul li.not-verified .icon,
main .page-wrapper .personal-info ul li.not-verified .icon {
  color: red;
}

main .page-wrapper .personal-info ul li.verified,
main .page-wrapper .personal-info ul li.verified {
  display: none;
}

main .page-wrapper section>h3,
main .page-wrapper section>h3 {
  text-align: center;
  font-size: 1.0625rem;

  height: 4.375rem;
  line-height: 4.375rem;
}

main .page-wrapper section>h3 a,
main .page-wrapper section>h3 a {
  color: var(--orange-primary);
  margin-right: 0.9375rem;
  text-decoration: underline;
}

main .page-wrapper section.bonuses .no-bonus,
main .page-wrapper section.bonuses .no-bonus {
  height: 8rem;
  text-align: center;
  background-size: cover;
  /* background-image: url(../../../assets/images/dashboard/bonusbg1b26.html?v2); */
  display: none;
}

main .page-wrapper section.bonuses .no-bonus .btn,
main .page-wrapper section.bonuses .no-bonus .btn {
  background-color: var(--orange-primary);
  width: 12.5rem;
  height: 4.6875rem;
  margin-top: 1.6875rem;

  font-size: 1.25rem;
}

main .page-wrapper section.bonuses .bonus-list,
main .page-wrapper section.bonuses .bonus-list {
  position: relative;
  display: none;
}

main .page-wrapper section.bonuses .bonus-list .arrow,
main .page-wrapper section.bonuses .bonus-list .arrow {
  position: absolute;
  top: 50%;
  margin-top: -1.25rem;
  font-size: 2.5rem;
  color: #fff;
  cursor: pointer;
  display: none;
}

main .page-wrapper section.bonuses .bonus-list .arrow:first-child,
main .page-wrapper section.bonuses .bonus-list .arrow:first-child {
  right: -2.5rem;
}

main .page-wrapper section.bonuses .bonus-list .arrow:last-child,
main .page-wrapper section.bonuses .bonus-list .arrow:last-child {
  left: -2.5rem;
}

main .page-wrapper section.bonuses .bonus-list .list-wrapper,
main .page-wrapper section.bonuses .bonus-list .list-wrapper {
  overflow: hidden;
}

main .page-wrapper section.bonuses .bonus-list .list-wrapper ul,
main .page-wrapper section.bonuses .bonus-list .list-wrapper ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

main .page-wrapper section.bonuses .bonus-list .list-wrapper ul li,
main .page-wrapper section.bonuses .bonus-list .list-wrapper ul li {
  width: 24.625rem;
  height: 16.4375rem;
  border-radius: 0.625rem;
  overflow: hidden;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  background-color: #3c3b43;
  text-align: center;
}

main .page-wrapper section.bonuses .bonus-list .list-wrapper ul li+li,
main .page-wrapper section.bonuses .bonus-list .list-wrapper ul li+li {
  margin-right: 1.25rem;
}

main .page-wrapper section.bonuses .bonus-list .list-wrapper ul li .bonus-banner,
main .page-wrapper section.bonuses .bonus-list .list-wrapper ul li .bonus-banner {
  height: 9.375rem;
  background-size: cover;
  border-radius: 0.625rem;
  /* background-image: url(../../../assets/images/dashboard/bg11b26.html?v2); */
}

main .page-wrapper section.bonuses .bonus-list .list-wrapper ul li h2,
main .page-wrapper section.bonuses .bonus-list .list-wrapper ul li h2 {
  font-size: 1.3125rem;
  font-weight: bold;
  margin-top: 2.1875rem;
}

main .page-wrapper section.bonuses .bonus-list .list-wrapper ul li a,
main .page-wrapper section.bonuses .bonus-list .list-wrapper ul li a {
  font-size: 1.125rem;

  margin-top: 0.6875rem;
  display: inline-block;
  color: #88bfff;
}

main .page-wrapper section.bonuses .bonus-list .list-wrapper ul li a:hover,
main .page-wrapper section.bonuses .bonus-list .list-wrapper ul li a:hover {
  text-decoration: underline;
}

main .page-wrapper section.messages .messages-wrapper ul li,
main .page-wrapper section.messages .messages-wrapper ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  height: 4.5625rem;
  border-radius: 0.3125rem;
  margin-bottom: 0.125rem;
  padding: 0 1rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

main .page-wrapper section.messages .messages-wrapper ul li:hover,
main .page-wrapper section.messages .messages-wrapper ul li:hover {
  background: rgba(0, 0, 0, 0.1);
}

main .page-wrapper section.messages .messages-wrapper ul li .left,
main .page-wrapper section.messages .messages-wrapper ul li .left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

main .page-wrapper section.messages .messages-wrapper ul li .left .icon,
main .page-wrapper section.messages .messages-wrapper ul li .left .icon {
  font-size: 1.5rem;
  color: var(--orange-primary);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  margin-left: 1.125rem;
}

main .page-wrapper section.messages .messages-wrapper ul li .left .welcome,
main .page-wrapper section.messages .messages-wrapper ul li .left .welcome {
  font-size: 0.9375rem;
  font-weight: bold;
  color: #fff;
  margin-bottom: 0.125rem;
}

main .page-wrapper section.messages .messages-wrapper ul li .left span,
main .page-wrapper section.messages .messages-wrapper ul li .left span {
  font-size: 0.8125rem;
}

main .page-wrapper section.messages .messages-wrapper ul li .right,
main .page-wrapper section.messages .messages-wrapper ul li .right {
  color: rgba(255, 255, 255, 0.9);
  text-align: left;
  font-size: 0.8125rem;
}

main .page-wrapper section.transactions table,
main .page-wrapper section.transactions table {
  border-collapse: collapse;
  width: 100%;
}

main .page-wrapper section.transactions table td,
main .page-wrapper section.transactions table th,
main .page-wrapper section.transactions table td,
main .page-wrapper section.transactions table th {
  text-align: center;
  /* height: 2.5rem;
  padding-right: 3.4375rem; */
  height: 4.5rem;
  padding-right: 2.1rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

main .page-wrapper section.transactions table thead,
main .page-wrapper section.transactions table thead {
  background: rgba(255, 255, 255, 0.04);
  color: inherit;
}

main .page-wrapper section.transactions table thead tr th,
main .page-wrapper section.transactions table thead tr th {
  font-weight: 400;
}

main .page-wrapper section.transactions table tbody tr,
main .page-wrapper section.transactions table tbody tr {
  background: rgba(0, 0, 0, 0.2);
  border-top: 0.0625rem solid rgba(255, 255, 255, 0.03);
  font-size: 0.8125rem;
  color: #fff;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

main .page-wrapper section.transactions table tbody tr:hover,
main .page-wrapper section.transactions table tbody tr:hover {
  background: rgba(255, 255, 255, 0.05);
}

/* ================================== gift ========================================== */

.page-wrapper ul.tabs li {
  float: left;
  min-width: 2.5rem;
  text-align: center;
  height: 2.5rem;
  line-height: 2.8125rem;
  border-bottom: 0.1875rem solid transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}

.page-wrapper ul.tabs li+li {
  margin-left: 3.125rem;
}

.page-wrapper ul.tabs li.active,
.page-wrapper ul.tabs li:hover {
  color: #fff;
  border-color: var(--orange-primary);
}

.page-wrapper>.gift-page-wrapper ul.tabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0 !important;
}

.page-wrapper>.gift-page-wrapper ul.tabs li {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  height: 3.125rem;
  font-size: 1rem;
  background-color: #3a4155;
  color: #fff;
  border-radius: 0.3125rem;
  -webkit-transition: -webkit-filter 120ms ease-in;
  transition: -webkit-filter 120ms ease-in;
  transition: filter 120ms ease-in;
  transition: filter 120ms ease-in, -webkit-filter 120ms ease-in;
  cursor: pointer;
}

.page-wrapper>.gift-page-wrapper ul.tabs li:hover {
  -webkit-filter: brightness(1.15);
  filter: brightness(1.15);
}

.page-wrapper>.gift-page-wrapper ul.tabs li.active {
  /* background: #f45a41; */
  background-color: var(--orange-primary);
  color: #fff;
}

.page-wrapper>.gift-page-wrapper ul.tabs li+li {
  margin-left: 1.25rem;
}

.page-wrapper>.gift-page-wrapper ul.tabs li .total-count {
  position: absolute;
  right: 0.4375rem;
  top: 0.7375rem;
  width: 1.375rem;
  height: 1.375rem;
  border-radius: 1.375rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #ff172d;
  color: #fff;

  font-size: 0.875rem;
}

/* .page-wrapper > .gift-page-wrapper ul.tabs li .total-count:not(.visible) {
  display: none;
} */
.page-wrapper>.gift-page-wrapper ul.tabs li .total-count.disabled {
  background-color: #555f7d;
}

.page-wrapper>ul.tabs li .total-count.disabled {
  background-color: #555f7d;
}

.page-wrapper section {
  min-height: 35.625rem;
  margin-top: 1.25rem;
  /* display: none; */
}

.page-wrapper section.visible {
  display: block;
}

.page-wrapper section table {
  border-collapse: collapse;
  width: 100%;
}

.page-wrapper section table td,
.page-wrapper section table th {
  text-align: left;
  color: #fff;
}

.page-wrapper section table thead {
  background-color: #3a4155;
}

.page-wrapper section table thead tr {
  border-bottom: 0.0625rem solid #333645;
}

.page-wrapper section table thead tr th {
  font-weight: 400;
  color: #fff;
  height: 2.5rem;
}

.page-wrapper section table thead tr th:nth-child(1) {
  padding-left: 1.875rem;
}

.page-wrapper section table tbody tr {
  background-color: #1b202d;
  border-bottom: 0.0625rem solid #333645;
  font-size: 0.8125rem;
}

.page-wrapper section table tbody tr.expanded {
  background: linear-gradient(45deg, transparent, #3a4155);
}

.page-wrapper section table tbody tr td {
  height: 5rem;
}

.page-wrapper section table tbody tr td:first-child {
  padding-left: 1.875rem;
}

.page-wrapper section table tbody tr td .btn {
  /* background: #f45a41; */
  background-color: var(--orange-primary);

  color: #fff;
}

.page-wrapper section table tbody tr.child-row:not(.visible) {
  display: none;
}

.page-wrapper section table tbody tr.child-row td {
  padding: 0;
}

.page-wrapper section table tbody tr.child-row ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.page-wrapper section table tbody tr.child-row ul li {
  margin: 0.9375rem 0.625rem;
}

.page-wrapper section table tbody tr.child-row ul li a {
  display: block;
  padding: 0.625rem 1.25rem;
  /* background: #f45a41; */
  background-color: var(--orange-primary);

  font-size: 0.9375rem;
  color: #fff;
  border-radius: 6.25rem;
  -webkit-transition: -webkit-filter 120ms ease-in;
  transition: -webkit-filter 120ms ease-in;
  transition: filter 120ms ease-in;
  transition: filter 120ms ease-in, -webkit-filter 120ms ease-in;
  cursor: pointer;
}

.page-wrapper section table tbody tr.child-row ul li a:hover {
  -webkit-filter: brightness(1.1);
  filter: brightness(1.1);
}

/* .page-wrapper section .no-data {
  display: none;
} */

.no-data {
  width: 100%;
  background-color: #1b202d;
  height: 280px;
  text-align: center;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.no-data .icon {
  margin-top: 90px;
  font-size: 42px;
}

.no-data .text {
  font-size: 18px;
  margin-top: 30px;
}

.no-data img {
  width: 42px !important;
  height: 42px !important;
}

.page-wrapper .bonus-code {
  position: relative;
  background: linear-gradient(135deg, #363e4c 21%, #1c2330 79%);
  line-height: initial;
  text-align: initial;
  border-radius: 0.3125rem;
  padding-left: 1rem;
  height: 4.5625rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.page-wrapper .bonus-code form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.page-wrapper .bonus-code form .fields {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 2.5rem;
  overflow: hidden;
}

.page-wrapper .bonus-code form .fields input {
  width: 9.8125rem;
  height: 100%;
  font-size: 1rem;
  width: 13.9375rem;
  background: rgba(255, 255, 255, 0.17);
  color: #fff;
  font-size: 0.9375rem;

  border: none;
}

.page-wrapper .bonus-code form .fields+.txt {
  margin-left: 1.5625rem;
}

.page-wrapper .bonus-code form .fields button {
  font-size: 1rem;
  min-width: 7.5rem;
  height: 100%;
  margin-left: 0.9375rem;
  background: var(--orange-primary);
}

.page-wrapper .bonus-code form .fields .failure,
.page-wrapper .bonus-code form .fields .success {
  min-width: 6.25rem;
  padding: 0 0.9375rem;
  height: 2.5rem;
  line-height: 2.5rem;
  margin-left: 0.9375rem;
  border: 0.0625rem solid rgba(255, 255, 255, 0.17);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: none;
}

.page-wrapper .bonus-code form .fields .failure i,
.page-wrapper .bonus-code form .fields .success i {
  font-size: 1.75rem;
  margin-right: 0.625rem;
}

.page-wrapper .bonus-code form .fields .loader-wrapper {
  display: none;
  height: 100%;
  width: 7.5rem;
  text-align: center;
}

.page-wrapper .bonus-code form .fields .loader-wrapper .loader {
  margin-top: 0.3125rem;
}

.page-wrapper .bonus-filters {
  overflow: hidden;
}

.page-wrapper .bonus-filters ul.tabs {
  float: right;
}

.page-wrapper ul.tabs li {
  float: left;
  min-width: 2.5rem;
  text-align: center;
  height: 2.5rem;
  line-height: 2.8125rem;
  border-bottom: 0.1875rem solid transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}

.page-wrapper .bonus-filters ul.tabs li+li {
  margin-left: 3.125rem;
}

.page-wrapper ul.tabs li.active,
.page-wrapper ul.tabs li:hover {
  color: #fff;
  border-color: var(--orange-primary);
}

.page-wrapper .bonus-filters li,
.page-wrapper .bonus-filters li.active {
  background: transparent !important;
  border-radius: 0 !important;
  border-color: var(--orange-primary);
}

.page-wrapper .bonus-filters ul.tabs li {
  float: left;
  min-width: 2.5rem;
  text-align: center;
  height: 2.5rem;
  line-height: 2.8125rem;
  /* border-bottom: 0.1875rem solid transparent; */
  font-size: 0.875rem !important;
  font-weight: 400;
  width: inherit;
}

/* ============================= free spin ================================= */

.page-wrapper .promo-code {
  position: relative;
  background: linear-gradient(47deg, #3a4155, #3a4155);
  line-height: initial;
  text-align: initial;
  border-radius: 0.3125rem;
  padding-left: 1rem;
  height: 4.5625rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-top: 1.25rem;
}

.page-wrapper .promo-code form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.page-wrapper .promo-code form .fields {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 2.5rem;
  overflow: hidden;
}

.page-wrapper .promo-code form .fields input {
  width: 9.8125rem;
  height: 100%;
  font-size: 1rem;
  width: 13.9375rem;
  background: rgba(255, 255, 255, 0.17);
  color: #fff;
  font-size: 0.9375rem;
  font-weight: 400;
  border: none;
}

.page-wrapper .promo-code form .fields button {
  font-size: 1rem;
  min-width: 7.5rem;
  height: 100%;
  margin-left: 0.9375rem;
  /* background: #f45a41; */
  background-color: var(--orange-primary);

  border-radius: 2rem;
  color: #fff;
}

.page-wrapper .promo-code form .fields .failure,
.page-wrapper .promo-code form .fields .success {
  min-width: 6.25rem;
  padding: 0 0.9375rem;
  height: 2.5rem;
  line-height: 2.5rem;
  margin-left: 0.9375rem;
  border: 0.0625rem solid rgba(255, 255, 255, 0.17);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: none;
}

.page-wrapper .promo-code form .fields .failure i,
.page-wrapper .promo-code form .fields .success i {
  font-size: 1.75rem;
  margin-right: 0.625rem;
}

.page-wrapper .promo-code form .fields .loader-wrapper {
  display: none;
  height: 100%;
  width: 7.5rem;
  text-align: center;
}

.page-wrapper .promo-code form .fields .loader-wrapper .loader {
  margin-top: 0.3125rem;
}

.page-wrapper .promo-code form .txt {
  margin-left: 1.5625rem;
}

.page-wrapper .gift-page-wrapper section {
  min-height: 35.625rem;
  margin-top: 1.25rem;
  display: none;
}

.page-wrapper section.visible {
  display: block;
}

.page-wrapper section table {
  border-collapse: collapse;
  width: 100%;
}

.page-wrapper section table td,
.page-wrapper section table th {
  text-align: left;
  color: #fff;
}

.page-wrapper section table thead {
  background-color: #3a4155;
}

.page-wrapper section table thead tr {
  border-bottom: 0.0625rem solid #333645;
}

.page-wrapper section table thead tr th {
  font-weight: 400;
  color: #fff;
  height: 2.5rem;
}

.page-wrapper section table thead tr th:nth-child(1) {
  padding-left: 1.875rem;
}

.page-wrapper section table tbody tr {
  background-color: #1b202d;
  border-bottom: 0.0625rem solid #333645;
  font-size: 0.8125rem;
}

.page-wrapper section table tbody tr.expanded {
  background: linear-gradient(45deg, transparent, #3a4155);
}

.page-wrapper section table tbody tr td {
  height: 5rem;
}

.page-wrapper section table tbody tr td:first-child {
  padding-left: 1.875rem;
}

.page-wrapper section table tbody tr td .btn {
  /* background: #f45a41; */
  background-color: var(--orange-primary);

  color: #fff;
}

.page-wrapper section table tbody tr.child-row:not(.visible) {
  display: none;
}

.page-wrapper section table tbody tr.child-row td {
  padding: 0;
}

.page-wrapper section table tbody tr.child-row ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.page-wrapper section table tbody tr.child-row ul li {
  margin: 0.9375rem 0.625rem;
}

.page-wrapper section table tbody tr.child-row ul li a {
  display: block;
  padding: 0.625rem 1.25rem;
  /* background: #f45a41; */
  background-color: var(--orange-primary);

  font-size: 0.9375rem;
  color: #fff;
  border-radius: 6.25rem;
  -webkit-transition: -webkit-filter 120ms ease-in;
  transition: -webkit-filter 120ms ease-in;
  transition: filter 120ms ease-in;
  transition: filter 120ms ease-in, -webkit-filter 120ms ease-in;
  cursor: pointer;
}

.page-wrapper section table tbody tr.child-row ul li a:hover {
  -webkit-filter: brightness(1.1);
  filter: brightness(1.1);
}

/* .page-wrapper section .no-data {
  display: none;
} */
.page-wrapper .active-freespins {
  height: 35.625rem;
  margin-top: 1.875rem !important;
}

.page-wrapper .active-freespins table {
  border-collapse: collapse;
  width: 100%;
}

.page-wrapper .active-freespins table td,
.page-wrapper .active-freespins table th {
  text-align: left;
  height: 3.1rem;
}

.page-wrapper .active-freespins table thead {
  background-color: #141926;
}

.page-wrapper .active-freespins table thead tr th {
  font-weight: 400;
  color: #fff;
}

.page-wrapper .active-freespins table thead tr th:nth-child(1) {
  padding-left: 1.875rem;
  width: 20%;
}

.page-wrapper .active-freespins table thead tr th:nth-child(2) {
  width: 20%;
}

.page-wrapper .active-freespins table thead tr th:nth-child(3) {
  width: 14%;
}

.page-wrapper .active-freespins table thead tr th:nth-child(4) {
  width: 20%;
}

.page-wrapper .active-freespins table tbody tr {
  background-color: #20263685;
  border-bottom: 0.0625rem solid #172129;
  font-size: 0.8125rem;
}

.page-wrapper .active-freespins table tbody tr td:first-child {
  padding-left: 1.875rem;
}

.page-wrapper .active-freespins table tbody tr span {
  vertical-align: middle;
}

.page-wrapper .active-freespins table tbody tr .material-icons {
  color: var(--orange-primary);
  font-size: 0.9375rem;
  margin-left: 0.1875rem;
}

.page-wrapper .active-freespins .loader-wrapper {
  height: 7.5rem;
}

.page-wrapper .active-freespins .loader-wrapper .loader {
  margin-top: 3.125rem;
}

/* .page-wrapper .active-freespins .no-data {
  display: none;
} */
/* ========================= notification =============================== */
main .account-page-wrapper {
  padding: 0;
}

main .page-wrapper .notifications-wrapper {
  margin-top: 0;
  margin-left: auto;
}

main .page-wrapper .notifications-wrapper .icon {
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

main .page-wrapper .notifications-wrapper label {
  color: rgba(255, 255, 255, 0.4);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

main .page-wrapper .notifications-wrapper label input {
  position: absolute;
  opacity: 0;
}

main .page-wrapper .notifications-wrapper label span.checkmark {
  width: 1.25rem;
  height: 1.25rem;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 0.25rem;
  -webkit-transition: 280ms;
  transition: 280ms;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 0.625rem;
}

main .page-wrapper .notifications-wrapper label span.checkmark::after {
  display: none;
}

main .page-wrapper .notifications-wrapper label span.checkmark img {
  display: none;
}

main .page-wrapper .notifications-wrapper label input:checked~.checkmark {
  background-color: var(--orange-primary) !important;
  border: 1px solid transparent;
}

main .page-wrapper .notifications-wrapper label input:checked~.checkmark img {
  display: block;
  opacity: 1 !important;
}

main .page-wrapper div.heading {
  padding: 0.9375rem 1.5625rem;
  padding-left: 2.1875rem !important;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background: -webkit-gradient(linear,
      left top,
      right top,
      from(#0e1421),
      to(#191e2b));
  background: linear-gradient(to right, #0e1421, #191e2b);
  border-radius: 0.3125rem 0.3125rem 0 0;
}

main .page-wrapper div.heading .left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

main .page-wrapper div.heading .left h2 {
  color: var(--orange-primary);
  font-size: 0.9375rem;
  text-transform: uppercase;
  font-weight: bold;
  margin: 0;
}

main .page-wrapper div.heading .left input {
  margin-left: 1.375rem;
  height: 2.5rem;
  width: 13.9375rem;
  background-color: #1b202d;
  color: #fff;
  box-shadow: none;
  border: none;
  outline: none;
}

main .page-wrapper div.heading .left input::-webkit-input-placeholder {
  color: #828a99;
}

main .page-wrapper div.heading .left input::-moz-placeholder {
  color: #828a99;
}

main .page-wrapper div.heading .left input::-ms-input-placeholder {
  color: #828a99;
}

main .page-wrapper div.heading .left input::placeholder {
  color: #828a99;
}

main .page-wrapper div.heading .left img {
  position: relative;
  right: 1.875rem;
}

main .page-wrapper div.heading .right {
  position: relative;
}

main .page-wrapper div.heading .right .btn {
  background: var(--orange-primary);
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease;
}

main .page-wrapper div.heading .right .btn.disabled {
  opacity: 0.4;
  pointer-events: none;
}

main .page-wrapper .custom-scrollbar {
  background: #3a4155;
}

main .page-wrapper .loader-wrapper {
  height: 11rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

main .page-wrapper ul.notifications {
  border-radius: 0 0 0.3125rem 0.3125rem;
  max-height: 49rem;
  overflow: auto;
}

main .page-wrapper ul.notifications li {
  position: relative;
  padding: 0.9375rem 2.1875rem;
  padding-right: 3.75rem;
  background-color: #ffffff14;
  cursor: pointer;
}

main .page-wrapper ul.notifications li h5 {
  position: relative;
  margin-bottom: 0.625rem;
  color: #fff;
  font-size: 0.875rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

main .page-wrapper ul.notifications li>div,
main .page-wrapper ul.notifications li>p {
  color: #adb8cc;
  display: none;
}

main .page-wrapper ul.notifications li>span {
  margin-top: 0.8rem;
  font-size: 0.75rem;
  display: block;
  color: rgba(130, 138, 153, 0.58);
}

main .page-wrapper ul.notifications li+li {
  border-top: 0.0625rem solid rgba(154, 168, 196, 0.07);
}

main .page-wrapper ul.notifications li:not(.read) h5::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateX(-200%) translateY(-50%);
  transform: translateX(-200%) translateY(-50%);
  height: 0.625rem;
  width: 0.625rem;
  background: #e62b3a;
  border-radius: 50%;
  display: block;
  margin-right: 0.625rem;
}

main .page-wrapper ul.notifications li label.checkbox-wrapper {
  position: absolute;
  right: 1.5625rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

main .page-wrapper ul.notifications li label.checkbox-wrapper .checkmark {
  margin-left: 0;
  cursor: pointer;
  z-index: 9;
  background-color: #fff0;
  border: 1px solid #ffffff4a;
}

main .page-wrapper ul.notifications li label.checkbox-wrapper .checkmark img {
  display: block;
  opacity: 0.2;
}

main .page-wrapper ul.notifications li.read {
  background-color: #ffffff05;
}

main .page-wrapper ul.notifications li.read label.checkbox-wrapper {
  display: none !important;
}

main .page-wrapper ul.notifications li.read h5 {
  color: #adb8cc;
}

main .page-wrapper ul.notifications li.active {
  background: 0 0 !important;
}

/* main .page-wrapper .no-data {
  display: none;
} */
main .page-wrapper .no-data .icon {
  height: unset;
  margin-top: 5rem;
}

main .page-wrapper .gift-page-wrapper .visible {
  display: block;
}

/* =============================== Personal info ====================================== */

.personal-info-content .nav {
  overflow: hidden;
  padding-bottom: 2.1875rem;
  border: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.personal-info-content .nav .rect {
  height: 4.25rem;
  line-height: 4.25rem;
  float: left;
  min-width: 35%;
  color: rgba(255, 255, 255, 0.5);
  border-radius: 0.1875rem;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 1.25rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #ffffff14;
  margin: 0 0.625rem;
}

.wallet-box-content {
  display: flex;
  align-items: center;
}

.personal-info-content .nav .rect div {
  height: 100%;
}

.personal-info-content .nav .rect div .material-icons {
  color: var(--orange-primary);
  font-size: 2rem;
  /* float: left; */
  margin-right: 0.9375rem;
}

.personal-info-content .nav .rect div:first-child {
  margin-right: 0.9375rem;
}

.personal-info-content .nav .rect.deposit {
  background: var(--orange-primary);
  color: #fff;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 1.25rem;
}

.personal-info-content .nav .rect.deposit .material-icons {
  margin-right: 0.625rem;
  font-size: 2.25rem;
}

.personal-info-content .nav .rect .amount {
  margin-right: 0.3125rem;
  font-size: 1.25rem;
  color: #fff;
}

.personal-info-content .nav .rect .currency-str {
  font-size: 1.25rem;
  color: grey;
}

.personal-info-content .nav .rect.balance {
  font-size: 0.9375rem;
}

.personal-info-content .content {
  overflow: hidden;
}

.personal-info-content .half {
  float: left;
  width: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-right: 2.5rem;
}

.personal-info-content .half>h2 {
  color: var(--orange-primary);

  font-size: 1.0625rem;
  height: 5.25rem;
  line-height: 5.25rem;
}

.personal-info-content .half:last-child {
  padding-left: 2.5rem;
  padding-right: 0;
}

.personal-info-content .half ul.fields li {
  border-top: 0.0625rem solid rgba(255, 255, 255, 0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: normal;
}

.personal-info-content .half ul.fields li:last-child {
  border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.1);
}

.personal-info-content .half ul.fields li:not(.edit-mode) .cancel,
.personal-info-content .half ul.fields li:not(.edit-mode) .save,
.personal-info-content .half ul.fields li:not(.edit-mode) input,
.personal-info-content .half ul.fields li:not(.edit-mode) select {
  display: none;
}

.personal-info-content .half ul.fields li.edit-mode input,
.personal-info-content .half ul.fields li.edit-mode select {
  width: 80%;
  background: #1f1f27;
  border-radius: 0;
  color: #fff;
}

.personal-info-content .half ul.fields li.edit-mode .editable,
.personal-info-content .half ul.fields li.edit-mode .info,
.personal-info-content .half ul.fields li.edit-mode div:first-child {
  display: none;
}

.personal-info-content .half ul.fields li.edit-mode div:first-child {
  display: block;
  padding-right: 0.625rem;
}

.personal-info-content .half ul.fields li .material-icons {
  float: right;
  font-size: 1.375rem;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  margin-left: auto;
  margin-right: 0.9375rem;
  cursor: pointer;
}

.personal-info-content .half ul.fields li .material-icons.locked {
  color: rgba(255, 255, 255, 0.5);
  width: 1.375rem;
}

.personal-info-content .half ul.fields li .material-icons.editable {
  position: relative;
  color: var(--orange-primary);
  top: -0.25rem;
}

.personal-info-content .half ul.fields li .material-icons.editable:before {
  content: "";
  position: absolute;
  height: 0.25rem;
  width: 100%;
  background: var(--orange-primary);
  opacity: 0.3;
  bottom: -0.3125rem;
}

.personal-info-content .half ul.fields li .material-icons.save {
  margin-right: 0.3125rem;
  color: #3bab4e;
}

.personal-info-content .half ul.fields li .material-icons.cancel {
  margin-left: 0;
  color: #e94545;
}

.personal-info-content .half ul.fields li .material-icons:hover {
  opacity: 0.7;
}

.personal-info-content .half ul.fields li input,
.personal-info-content .half ul.fields li select {
  height: 100%;
  border: 0;
  background: #f3f3f3;
  width: 12.5rem;
  vertical-align: middle;
}

.personal-info-content .err-text {
  text-align: center;
  margin-top: 1.875rem;
  font-size: 1rem;
}

.personal-info-content,
.personal-info-content {
  position: relative;
  overflow: hidden;
  /* padding: 2.1875rem 4.0625rem; */
  padding: 0;
}

.personal-info-content .nav,
.personal-info-content .nav {
  overflow: hidden;
  padding-bottom: 2.1875rem;
  border: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.personal-info-content .nav .rect,
.personal-info-content .nav .rect {
  height: 4.25rem;
  line-height: 4.25rem;
  float: right;
  min-width: 35%;
  color: rgba(255, 255, 255, 0.5);
  border-radius: 0.1875rem;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 1.25rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.08);
  margin: 0 0.625rem;
}

.personal-info-content .nav .rect div,
.personal-info-content .nav .rect div {
  height: 100%;
}

.personal-info-content .nav .rect div .material-icons,
.personal-info-content .nav .rect div .material-icons {
  color: var(--orange-primary);
  font-size: 2rem;
  /* float: right; */
  margin-left: 0.9375rem;
}

.personal-info-content .nav .rect.deposit,
.personal-info-content .nav .rect.deposit {
  background: var(--orange-primary);
  color: #fff;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 1.25rem;
}

.personal-info-content .nav .rect.deposit .material-icons,
.personal-info-content .nav .rect.deposit .material-icons {
  margin-left: 0.625rem;
  font-size: 2.25rem;
}

.personal-info-content .nav .rect .amount,
.personal-info-content .nav .rect .amount {
  margin-left: 0.3125rem;
  font-size: 1.25rem;
  color: #fff;
}

.personal-info-content .nav .rect .currency-str,
.personal-info-content .nav .rect .currency-str {
  font-size: 1.25rem;
  color: grey;
}

.personal-info-content .nav .rect.balance,
.personal-info-content .nav .rect.balance {
  font-size: 0.9375rem;
}

.personal-info-content .content,
.personal-info-content .content {
  overflow: hidden;
}

/* .personal-info-content .half,
.personal-info-content .half {
  float: right;
  width: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 2.5rem;
} */
.personal-info-content .half>h2,
.personal-info-content .half>h2 {
  color: var(--orange-primary);
  font-weight: bold;
  font-size: 1.0625rem;
  height: 5.25rem;
  line-height: 5.25rem;
}

.personal-info-content .half:last-child,
.personal-info-content .half:last-child {
  padding-right: 2.5rem;
  padding-left: 0;
}

.personal-info-content .half ul.fields li,
.personal-info-content .half ul.fields li {
  border-top: 0.0625rem solid rgba(255, 255, 255, 0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: normal;
  font-weight: 400;
}

.personal-info-content .half ul.fields li:last-child,
.personal-info-content .half ul.fields li:last-child {
  border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.1);
}

.personal-info-content .half ul.fields li:not(.edit-mode) .cancel,
.personal-info-content .half ul.fields li:not(.edit-mode) .save,
.personal-info-content .half ul.fields li:not(.edit-mode) input,
.personal-info-content .half ul.fields li:not(.edit-mode) select,
.personal-info-content .half ul.fields li:not(.edit-mode) .cancel,
.personal-info-content .half ul.fields li:not(.edit-mode) .save,
.personal-info-content .half ul.fields li:not(.edit-mode) input,
.personal-info-content .half ul.fields li:not(.edit-mode) select {
  display: none;
}

.personal-info-content .half ul.fields li.edit-mode input,
.personal-info-content .half ul.fields li.edit-mode select,
.personal-info-content .half ul.fields li.edit-mode input,
.personal-info-content .half ul.fields li.edit-mode select {
  width: 80%;
  background: #1f1f27;
  border-radius: 0;
}

.personal-info-content .half ul.fields li.edit-mode .editable,
.personal-info-content .half ul.fields li.edit-mode .info,
.personal-info-content .half ul.fields li.edit-mode div:first-child,
.personal-info-content .half ul.fields li.edit-mode .editable,
.personal-info-content .half ul.fields li.edit-mode .info,
.personal-info-content .half ul.fields li.edit-mode div:first-child {
  display: none;
}

.personal-info-content .half ul.fields li.edit-mode div:first-child,
.personal-info-content .half ul.fields li.edit-mode div:first-child {
  display: block;
  padding-right: 0.625rem;
}

/* .personal-info-content .half ul.fields li .material-icons,
.personal-info-content .half ul.fields li .material-icons {
  float: left;
  font-size: 1.375rem;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  margin-right: auto;
  margin-left: 0.9375rem;
  cursor: pointer;
} */
.personal-info-content .half ul.fields li .material-icons.locked,
.personal-info-content .half ul.fields li .material-icons.locked {
  color: rgba(255, 255, 255, 0.5);
  width: 1.375rem;
}

.personal-info-content .half ul.fields li .material-icons.editable,
.personal-info-content .half ul.fields li .material-icons.editable {
  position: relative;
  color: var(--orange-primary);
  top: -0.25rem;
}

.personal-info-content .half ul.fields li .material-icons.editable:before,
.personal-info-content .half ul.fields li .material-icons.editable:before {
  content: "";
  position: absolute;
  height: 0.25rem;
  width: 100%;
  background: var(--orange-primary);
  opacity: 0.3;
  bottom: -0.3125rem;
}

.personal-info-content .half ul.fields li .material-icons.save,
.personal-info-content .half ul.fields li .material-icons.save {
  margin-left: 0.3125rem;
  color: #3bab4e;
}

.personal-info-content .half ul.fields li .material-icons.cancel,
.personal-info-content .half ul.fields li .material-icons.cancel {
  margin-right: 0;
  color: #e94545;
}

.personal-info-content .half ul.fields li .material-icons:hover,
.personal-info-content .half ul.fields li .material-icons:hover {
  opacity: 0.7;
}

.personal-info-content .half ul.fields li input,
.personal-info-content .half ul.fields li select,
.personal-info-content .half ul.fields li input,
.personal-info-content .half ul.fields li select {
  height: 100%;
  border: 0;
  background: #f3f3f3;
  width: 12.5rem;
  vertical-align: middle;
}

.personal-info-content .err-text,
.personal-info-content .err-text {
  text-align: center;
  margin-top: 1.875rem;
  font-size: 1rem;
}

::-webkit-calendar-picker-indicator {
  -webkit-filter: invert(1);
  filter: invert(1);
}

.personal-info-content .page-wrapper ul.fields li .desc.info,
.personal-info-content .page-wrapper ul.fields li .info.info {
  width: calc(100% - 4.625rem) !important;
}

/* ================================ transection ======================================= */

.page-wrapper .content .filter {
  padding: 1.625rem 2.25rem;
}

.page-wrapper .content .filter ul {
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.page-wrapper .content .filter ul li {
  position: relative;
}

.page-wrapper .content .filter ul li:not(:first-child) {
  margin-left: 2.5rem;
}

.page-wrapper .content .filter ul li:first-child,
.page-wrapper .content .filter ul li:nth-child(2) {
  width: calc(23% - 2.5rem);
}

.page-wrapper .content .filter ul li:nth-child(3),
.page-wrapper .content .filter ul li:nth-child(4) {
  width: calc(18% - 2.5rem);
}

.page-wrapper .content .filter ul li:nth-child(3):nth-child(3),
.page-wrapper .content .filter ul li:nth-child(4):nth-child(3) {
  margin-left: 3.75rem;
}

.page-wrapper .content .filter ul li:last-child {
  width: calc(20% - 2.5rem);
}

.page-wrapper .content .filter ul li .material-icons {
  font-size: 1.5rem;
  color: #c4c4c4;
}

.page-wrapper .content .filter ul.head li {
  height: 2.1875rem;
  line-height: 2.1875rem;
}

.personal-history-page-wrapper .filter {
  padding: 0px !important;
}

.personal-history-page-wrapper .filter .filters {
  margin: 0;
}

.page-wrapper .content .filter ul li {
  float: left;
}

.page-wrapper .content .filter ul li input,
.page-wrapper .content .filter ul li select {
  padding: 0.375rem 0;
  padding-left: 0.1875rem;
  width: 100%;
  height: 2.375rem;
  color: rgba(255, 255, 255, 0.5);
  border-radius: 0;
  line-height: 2.375rem;
  background: 0 0;
  border: none;
  border-bottom: 0.0625rem solid #afafaf;
}

.page-wrapper .content .filter ul li select option {
  color: #222;
}

.page-wrapper .content .filter ul li .btn {
  width: 100%;
  height: 2.375rem;
  line-height: 2.375rem;
  float: right;
  background: var(--orange-primary);
}

.page-wrapper .content .overflow {
  height: 7.5rem;
}

.page-wrapper .content .overflow .loader {
  margin-top: 3.125rem;
}

/* .page-wrapper .content .transactions-list {
  height: 35.625rem;
} */

.page-wrapper .content .transactions-list table {
  border-collapse: collapse;
  width: 100%;
  /* display: none; */
}

.page-wrapper .content .transactions-list table td,
.page-wrapper .content .transactions-list table th {
  text-align: center;
  white-space: noWrap;
  height: 3.375rem;
}

.page-wrapper .content .transactions-list table thead tr th {
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.8125rem;
}

.page-wrapper .content .transactions-list table thead tr th:nth-child(1) {
  padding-left: 3.5rem;
  width: 22%;
}

.page-wrapper .content .transactions-list table thead tr th:last-child {
  width: 10rem;
}

.page-wrapper .content .transactions-list table tbody {
  border-top: 0.0625rem solid rgba(255, 255, 255, 0.03);
}

.page-wrapper .content .transactions-list table tbody tr:hover {
  background-color: #ffffff0d;
  font-size: 16px;
  cursor: pointer;
}

.dashboard-table::-webkit-scrollbar,
body::-webkit-scrollbar {
  height: 2px;
  width: 2px;
  background-color: transparent;
}

.dashboard-table::-webkit-scrollbar-track,
body::-webkit-scrollbar-track {
  background-color: transparent;
}

.dashboard-table::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
  background-color: rgb(135, 132, 132);
}

.transaction-table::-webkit-scrollbar,
body::-webkit-scrollbar {
  height: 2px;
  width: 2px;
  background-color: transparent;
}

.transaction-table::-webkit-scrollbar-track,
body::-webkit-scrollbar-track {
  background-color: transparent;
}

.transaction-table::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
  background-color: rgb(135, 132, 132);
}

.bet-history-table::-webkit-scrollbar,
body::-webkit-scrollbar {
  height: 2px;
  width: 2px;
  background-color: transparent;
}

.bet-history-table::-webkit-scrollbar-track,
body::-webkit-scrollbar-track {
  background-color: transparent;
}

.bet-history-table::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
  background-color: rgb(135, 132, 132);
}

.page-wrapper .content .transactions-list table tbody tr {
  border-bottom: .0625rem solid #333645;
  font-size: 0.8rem;
  background: #0003;
  color: #fff;
}

.page-wrapper .content .transactions-list table tbody tr td:first-child {
  padding-left: 3.5rem;
}

.page-wrapper .content .transactions-list table tbody tr td:last-child {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-right: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.page-wrapper .content .transactions-list table tbody tr .cancel-transaction {
  background: var(--orange-primary);
  height: 2.4rem;
  margin-right: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 5rem;
  width: 6rem;
  color: var(--secondary-text-color);
  cursor: pointer;
  font-family: Poppins-Bold;
}

.page-wrapper .custom-scrollbar-btn {
  background-color: rgba(255, 255, 255, 0.15) !important;
}

/* .page-wrapper .no-data {
  display: none;
  text-align: center;
} */
/* ================================== bet history =========================================== */
#bet-history.show-as-page {
  /* margin-top: 1rem;
  height: calc(100vh - 2rem); */
}

#bet-history.show-as-page .wrapper {
  height: 100%;
}

#bet-history .wrapper {
  padding: 0px 0.83333rem;
}

#bet-history .wrapper .popup-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.25rem 0.41667rem;
}

#bet-history .wrapper .popup-title .title {
  display: flex;
  align-items: center;
  color: rgb(204, 214, 246);
}

#bet-history .wrapper .popup-title .title .icmn-icon {
  font-size: 1.66667rem;
  margin-right: 0.41667rem;
}

#bet-history .wrapper .popup-title .title span {
  font-weight: 600;
}

#bet-history .wrapper .popup-title .filters {
  height: 100%;
  /* margin-left: auto; */
  display: flex;
  align-items: center;
}

#bet-history .wrapper .popup-title .filters .date-filter {
  display: flex;
  /* flex-wrap: wrap; */
  gap: 15px;
}

#bet-history .wrapper .popup-title .filters .date-filter .inp-wrapper {
  position: relative;
}

#bet-history .wrapper .popup-title .filters .date-filter .inp-wrapper input {
  width: 160px;
  height: 3.33333rem;
  /* background-color: transparent; */
  border: 0px;
  border-radius: 0.41667rem;
  padding: 12px;
}

#bet-history .wrapper .popup-title .filters .date-filter .inp-wrapper .icmn-icon {
  position: absolute;
  right: 0.83333rem;
  font-size: 1.66667rem;
  top: 50%;
  margin-top: -0.83333rem;
}

#bet-history .wrapper .popup-title .filters .date-filter .inp-wrapper+.inp-wrapper {
  /* margin-left: 1.25rem; */
}

#bet-history .wrapper .popup-title .filters .date-filter .inp-wrapper {
  position: relative;
}

#bet-history .wrapper .popup-title .filters .date-filter .btn {
  height: 3.33333rem;
  line-height: 3.33333rem;
  /* margin-left: 1.25rem; */
  background-color: rgb(120, 66, 229);
  color: rgb(204, 214, 246);
  border-radius: 0.41667rem;
  padding: 0px 1.25rem;
  font-weight: 400;
}

#bet-history .wrapper .popup-title .filters .date-filter .btn:hover {
  filter: var(--brightness);
}

#sportsbook .btn {
  text-transform: none;
}

#bet-history .wrapper .popup-title .filters ul,
.transaction-history ul,
.tabs-mobile {
  font-size: 0.9rem;
  height: 3.33333rem;
  margin-left: 3.33333rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tabs-mobile {
  margin-left: 0px;
  margin: 0px auto;
  justify-content: space-between;
  max-width: 393px;
  margin-bottom: 15px;

}

.transaction-history ul {
  display: flex;
  margin: 0px 0px 20px 0px;
}

#bet-history .wrapper .popup-title .filters ul li,
.transaction-history ul li,
.tabs-mobile li {
  position: relative;
  height: 100%;
  padding: 0px 1.25rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
}

#bet-history .wrapper .popup-title .filters ul li.active::after,
#bet-history .wrapper .popup-title .filters ul li:hover::after,
.transaction-history ul li.active::after,
.transaction-history ul li.hover::after,
.tabs-mobile ul li.active::after,
.tabs-mobile ul li.hover::after {
  content: "";
  position: absolute;
  left: 0.5rem;
  bottom: 0px;
  width: calc(100% - 1rem);
  height: 0.2333rem;
  border-radius: 100px;
  background-color: rgb(0, 236, 102);
}

#bet-history .wrapper .body {
  position: relative;
  height: calc(100% - 5.83333rem);
}

#bet-history .no-data {
  width: 100%;
  background-color: rgba(31, 37, 48, 0.8);
  color: rgb(151, 161, 194);
  height: 280px;
  text-align: center;
  overflow: hidden;
}

#bet-history .no-data .text {
  font-weight: 400;
}

/* ============================== KYC =========================== */
/* .kyc-forms-content {
  padding: 1.9375rem 1.75rem;
} */
.page-wrapper .kyc-forms {
  margin-top: 1.25rem;
}

.page-wrapper .kyc-forms:last-child {
  margin-bottom: 1.25rem;
}

.page-wrapper .kyc-forms .accordion-item {
  border: none;
  border-radius: inherit !important;
  background-color: transparent !important;
}

.page-wrapper .kyc-forms .accordion-item:first-of-type .accordion-button {
  border-radius: inherit !important;
}

.page-wrapper .kyc-forms .accordion-button:focus {
  box-shadow: none;
}

.page-wrapper .kyc-forms .accordion-button::after {
  display: none;
}

.page-wrapper .kyc-forms .accordion-button:not(.collapsed) {
  box-shadow: none;
}

.page-wrapper .kyc-forms .accordion-button {
  padding: 1.5625rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #20293a;
  cursor: pointer;
  border: none;
  height: 67.2px;
}

.page-wrapper .kyc-forms .accordion-body {
  padding: 0;
}

.page-wrapper .kyc-forms .kyc-form-collapsable {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.page-wrapper .kyc-forms .kyc-form-collapsable .form-name {
  height: 1.5625rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1.125rem;
  text-transform: capitalize;
  color: #fff;
}

.page-wrapper .kyc-forms .kyc-form-collapsable .form-name .material-icons {
  font-size: 3rem;
  margin-right: 1.25rem;
  color: var(--orange-primary);
}

.page-wrapper .kyc-forms .kyc-form-collapsable span.material-icons {
  font-size: 1.75rem;
  color: #abb0c0;
}

.page-wrapper .kyc-forms .kyc-form-collapsable .verified {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
}

.page-wrapper .kyc-forms li .kyc-form-collapsable .verified span.material-icons {
  font-size: 1.5rem;
  color: #02eb21;
  margin-right: 0.75rem;
}

.page-wrapper .kyc-forms .kyc-form-collapsable.verified-form:hover {
  cursor: initial;
}

.page-wrapper .kyc-forms .form-content {
  /* max-height: 0; */
  overflow: hidden;
  -webkit-transition: max-height 0.3s ease-out;
  transition: max-height 0.3s ease-out;
  display: block;
  background-color: #0d131f;
  position: relative;
}

.page-wrapper .kyc-forms .form-content .selfie {
  position: absolute;
  top: 8.125rem;
  right: 1.875rem;
}

.page-wrapper .kyc-forms .form-content .form-description {
  margin: 1.875rem 1.875rem 2.5rem 1.875rem;
}

.page-wrapper .kyc-forms .form-content .form-description p {
  color: #bfbfbf;
  font-size: 0.9375rem;
  line-height: 1.25rem;
  font-weight: 400;
  margin: 0;
}

.page-wrapper .kyc-forms .form-content ul.kyc-questionnaires {
  margin-bottom: 1.25rem;
}

.page-wrapper .kyc-forms .form-content ul.kyc-questionnaires li {
  margin: auto;
  border: 0.0625rem solid rgba(78, 78, 78, 0.35);
  border-radius: 0.1875rem;
  margin-bottom: 1.6875rem;
}

.page-wrapper .kyc-forms .form-content ul.kyc-questionnaires .kyc-questionnaire-collapsible {
  padding: 1.6875rem 1.875rem;
  font-size: 1.125rem;
  color: #fff;
  font-size: 0.9375rem;
  font-weight: 400;
  background-color: #141922;
}

.page-wrapper .kyc-forms .form-content ul.kyc-questionnaires .kyc-questionnaire-collapsible .icon {
  float: right;
  width: 1.125rem;
}

.page-wrapper .kyc-forms li .form-content ul.kyc-questionnaires li .kyc-questionnaire-collapsible .status-message {
  float: right;
  margin-right: 0.9375rem;
}

.page-wrapper .kyc-forms li .form-content ul.kyc-questionnaires li .kyc-questionnaire-collapsible .status-rejected {
  color: red;
}

.page-wrapper .kyc-forms li .form-content ul.kyc-questionnaires li .kyc-questionnaire-collapsible .status-completed {
  color: #00f;
}

.page-wrapper .kyc-forms li .form-content ul.kyc-questionnaires li .kyc-questionnaire-collapsible .status-renew-required {
  color: red;
}

.page-wrapper .kyc-questionnaires li .content {
  padding: 0 1.125rem;
  max-height: 31.25rem;
  overflow: hidden;
  -webkit-transition: max-height 0.3s ease-out;
  transition: max-height 0.3s ease-out;
  display: block;
  background-color: #141922;
}

.page-wrapper .kyc-forms .kyc-questionnaire-row {
  margin-bottom: 1.25rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.page-wrapper .kyc-forms .kyc-input {
  padding: 0.625rem;
  height: 2.375rem;
  font-size: 0.875rem;
  border: none;
  border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.164);
  background-color: transparent;
  color: #fff;
}

.page-wrapper .kyc-forms input[list="country"] {
  /* background-image: url(../../../assets/images/expand_more-white-18dp43a0.html?v3); */
  background-repeat: no-repeat;
  background-size: 1.25rem auto;
  background-position: right 1.25rem center;
}

.page-wrapper .kyc-forms input[list="country"]::-webkit-calendar-picker-indicator {
  display: none;
}

.page-wrapper .kyc-forms li .form-content ul.kyc-questionnaires li .content .row input[list="country"].show-close-icon {
  /* background-image: url(../../../assets/images/close_white_24dp43a0.html?v3); */
}

.page-wrapper .kyc-forms li .form-content ul.kyc-questionnaires li .content .row input[list="country"].show-close-icon+span {
  display: block;
}

.page-wrapper .kyc-forms li .form-content ul.kyc-questionnaires li .content .row input[list="country"]:not(.show-close-icon)+span {
  display: none;
}

.page-wrapper .kyc-forms li .form-content ul.kyc-questionnaires li .content .row .date {
  /* background-image: url(../../../assets/images/calendar.html); */
  background-repeat: no-repeat;
  background-size: 1.0625rem auto;
  background-position: right 0.625rem center;
  cursor: pointer;
}

.page-wrapper .kyc-forms .file-inp-wrapper {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: auto;
  margin-top: 0.625rem;
}

.page-wrapper .kyc-forms .file-inp-wrapper span.field-name {
  color: #fff;
  font-weight: 400;
  font-size: 0.875rem;
  text-transform: uppercase;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.page-wrapper .kyc-forms .file-inp-wrapper label {
  background-color: #c8c8c8;
  border-radius: 0.1875rem;
  padding: 0.8125rem 1.5625rem;
  cursor: pointer;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin: 0.625rem 0;
}

.page-wrapper .kyc-forms .file-inp-wrapper label input[type="file"] {
  width: 0.0063rem;
  height: 0.0063rem;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  visibility: hidden;
}

.page-wrapper .kyc-forms .file-inp-wrapper label span {
  margin: 0;
  font-weight: 400;
  color: #010101;
}

.page-wrapper .kyc-forms .file-inp-wrapper span.chosen-file-name {
  margin-top: 0.625rem;
  font-weight: 400;
  font-weight: 400;
  color: #fff;
}

.page-wrapper .kyc-forms .file-inp-wrapper .max-size {
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.3);
}

.page-wrapper .kyc-forms .file-inp-wrapper .max-size.active {
  color: red;
}

.page-wrapper .kyc-forms li .form-content ul.kyc-questionnaires li .content .row:last-of-type {
  height: 6.25rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.page-wrapper .kyc-forms li .form-content ul.kyc-questionnaires li .content .row:last-of-type p {
  margin-top: 0.625rem;
}

.page-wrapper .kyc-forms li .form-content ul.kyc-questionnaires li .content .row:last-of-type.action {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.page-wrapper .kyc-forms li .form-content ul.kyc-questionnaires li .content .loader-wrapper {
  height: 1.875rem;
  display: none;
  margin-top: 1.875rem;
}

.page-wrapper .kyc-forms li .form-content ul.kyc-questionnaires li .content .success {
  color: #02eb21;
}

.page-wrapper .kyc-forms li .form-content ul.kyc-questionnaires li .content .failure {
  color: red;
}

.page-wrapper .kyc-forms li .form-content ul.kyc-questionnaires li .content .auto-verify .row {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.page-wrapper .kyc-forms li .form-content ul.kyc-questionnaires li .content .auto-verify .row p.message {
  width: 100%;
  text-align: center;
}

.page-wrapper .kyc-forms li .form-content ul.kyc-questionnaires .status-confirmed .kyc-questionnaire-collapsible {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.page-wrapper .kyc-forms li .form-content ul.kyc-questionnaires .status-confirmed .kyc-questionnaire-collapsible .verified {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.page-wrapper .kyc-forms li .form-content ul.kyc-questionnaires .status-confirmed .kyc-questionnaire-collapsible .verified span.material-icons {
  font-size: 1.5rem;
  color: #02eb21;
  margin-right: 0.75rem;
}

.view-more {
  color: #00ec66;
}

.view-more span {
  color: #545961;
}

.page-wrapper .kyc-forms li .form-content ul.kyc-questionnaires .status-confirmed .kyc-questionnaire-collapsible .update {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(69, 72, 83, 0.35);
  padding: 0.5625rem 1.375rem;
  -webkit-transition: -webkit-filter 0.3s ease-out;
  transition: -webkit-filter 0.3s ease-out;
  transition: filter 0.3s ease-out;
  transition: filter 0.3s ease-out, -webkit-filter 0.3s ease-out;
}

.page-wrapper .kyc-forms li .form-content ul.kyc-questionnaires .status-confirmed .kyc-questionnaire-collapsible .update:hover {
  -webkit-filter: brightness(1.2);
  filter: brightness(1.2);
  cursor: pointer;
}

.page-wrapper .kyc-forms li .form-content ul.kyc-questionnaires .status-confirmed .kyc-questionnaire-collapsible .update span.material-icons {
  font-size: 1.5rem;
  color: #ffc000;
  margin-right: 0.5rem;
}

.page-wrapper .kyc-forms li .form-content ul.kyc-questionnaires .status-confirmed .content {
  max-height: 0;
}

.kyc-questionnaire-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 6.25rem;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.kyc-questionnaire-btn .btn-save,
.btn-save {
  font-size: 0.875rem;
  height: 2.375rem;
  width: 7.8125rem;
  border-radius: 0.1875rem;
  background-color: var(--orange-primary) !important;
  color: #fff !important;
  /* font-family: Poppins-Medium; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: -webkit-filter 0.2s ease-out;
  transition: -webkit-filter 0.2s ease-out;
  transition: filter 0.2s ease-out;
  transition: filter 0.2s ease-out, -webkit-filter 0.2s ease-out;
}

.kyc-questionnaire-btn .btn-save:hover,
.btn-save:hover {
  -webkit-filter: brightness(1.2);
  filter: brightness(1.2);
  cursor: pointer;
}

.btn-grey {
  background-color: #3e3e46;
  font-size: 0.875rem;
  height: 2.375rem;
  width: 7.8125rem;
  border-radius: 0.1875rem;
  color: #fff;
  /* font-family: Poppins-Medium; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: -webkit-filter 0.2s ease-out;
  transition: -webkit-filter 0.2s ease-out;
  transition: filter 0.2s ease-out;
  transition: filter 0.2s ease-out, -webkit-filter 0.2s ease-out;
}

.btn-grey:hover {
  -webkit-filter: brightness(1.2);
  filter: brightness(1.2);
  cursor: pointer;
}

.questionnaire-row {
  height: 6.25rem;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

/* ========================================================================= */
/* ================================ media query ============================ */
/* ========================================================================= */
/* @media screen and (min-width: 991.98px) {
  .personal-history-page-wrapper {
    height: 100vh;
  }
} */

@media screen and (max-width: 1440px) {
  #bet-history .wrapper .popup-title .filters {
    flex-direction: column;
  }

  #bet-history .wrapper .popup-title {
    align-items: start;
  }

  #bet-history .wrapper .popup-title .filters .date-filter {
    /* flex-wrap: wrap; */
    text-align: center;
  }

  #bet-history .wrapper .popup-title .filters ul {
    margin-left: 0px;
    width: 100%;
    margin-top: 5px;
  }

  #bet-history .wrapper .popup-title .filters ul li {
    padding: 0px 2.25rem;
  }
}

@media screen and (max-width: 1401px) {
  #bet-history .wrapper .popup-title {
    justify-content: center;
  }
}

@media screen and (max-width: 1118px) {
  #bet-history .wrapper .popup-title .filters .date-filter .inp-wrapper input {
    width: 120px;
    font-size: 12px;
    padding: 0px .5rem !important;
  }

  main .page-wrapper,
  main .page-wrapper {
    padding: 1.9375rem 0.75rem !important;
  }
}

@media screen and (max-width: 1025px) {
  #bet-history .wrapper .popup-title .filters .date-filter {
    /* gap: 10px; */
    justify-content: center;
  }


}

#bet-history .wrapper .popup-title .filters .date-filter .inp-wrapper .from-date svg {
  width: 20px !important;
  height: 20px !important;
}

@media screen and (max-width: 1350px) {

  #bet-history .wrapper .popup-title .filters .date-filter .btn,
  #bet-history .wrapper .popup-title .filters .date-filter .inp-wrapper input {
    min-width: 3.3333rem;
    height: 2.5rem;
    padding: 0px .6rem;
    font-size: 11px;
  }

  .personal-info-content .half {
    width: 100%;
  }

  main.middle .bet-filter {
    line-height: 20px !important;
  }

}

@media screen and (max-width: 991.98px) {

  main nav.account-sidebar,
  main .page-wrapper:after,
  main .page-wrapper:before {
    display: none;
  }

  main .page-wrapper,
  main .page-wrapper {
    width: 100%;
    margin: 0;
    background: transparent;
    border: none;
    padding: 0 !important;
    min-height: 44rem;
    border-radius: 0 !important;
    padding: 3vw 0;
  }

  main .dashboard-title {
    color: #97a1c2;
    font-size: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.25rem;
  }

  main .dashboard-container {
    display: flex;
    align-items: center;
  }

  main .dashboard-title img:first-child {
    margin-right: 1.25rem;
    width: 1.5rem;
    height: 1.5rem;
    -webkit-filter: brightness(0) invert(26%) sepia(91%) saturate(1426%) hue-rotate(227deg) brightness(104%) contrast(92%);
    filter: brightness(0) invert(26%) sepia(91%) saturate(1426%) hue-rotate(227deg) brightness(104%) contrast(92%);
  }

  main .dashboard-container h4 {
    color: #ccd6f6;
    font-weight: 400;
    margin: 0;
    font-size: 0.75rem;
  }

  /* ======================== profile ============================ */
  main section .content {
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 1.25rem 1.563rem;
    background-color: #1a1f2b;
    border-radius: 15px;
  }

  main section.profile .content {
    position: relative;
  }

  main section.profile .content .edit {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  main section .content.center {
    align-items: center;
    justify-content: center;
  }

  main section.profile .content h3 {
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 0.625rem;
    color: #ccd6f6;
  }

  main section.profile .content p {
    color: #97a1c2;
    margin-bottom: 0.313rem;
    font-weight: 400;
  }

  main section.profile .content button {
    margin-top: 1.25rem;
    padding: 0.3125rem 0.875rem;
    border-radius: 10px;
    font-size: 0.75rem;
  }

  .page-wrapper section {
    min-height: inherit;
    display: block;
  }

  /* ======================== wallet ========================== */
  main section.wallets {
    margin-bottom: 1.25rem;
  }

  main .section-title {
    font-size: 1rem;
    font-weight: 700;
  }

  main section.wallets .section-title {
    margin-bottom: 1.25rem;
  }

  main section.wallets .currencies {
    margin-bottom: 1.25rem;
    height: 8.5rem;
    overflow: hidden;
  }

  main section.wallets .currencies.active {
    height: auto;
  }

  main section.wallets .currencies .currency {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0.9375rem;
    border: 1px solid rgba(204, 214, 246, 0.2);
    border-radius: 18px;
    margin-bottom: 0.3125rem;
    font-weight: 500;
  }

  main section.wallets .currencies .currency .value {
    font-size: 0.875rem;
    color: #ccd6f6;
  }

  main .currency-container {
    display: flex;
    align-items: center;
  }

  main section.wallets .currencies .currency .name {
    color: #97a1c2;
    margin-right: 1.25rem;
  }

  main section.wallets .currencies .currency img {
    width: 0.6875rem;
    height: auto;
  }

  main button.view-more {
    align-self: center;
    height: 2.5rem;
    color: #00ec66;
    width: 6.25rem;
    font-size: 0.6875rem;
    border-radius: 20px;
    padding: 0;
  }

  .view-more {
    font-weight: 500;
    align-self: center;
    /* height: 2.5rem; */
    /* width: 6.25rem; */
    font-size: 0.8875rem;
    border-radius: 20px;
    padding: 0;
  }

  /* ========================= transactions ============================= */
  main section.transactions .title {
    color: #ccd6f6;
    font-weight: 600;
    font-size: 0.875rem;
    margin-bottom: 0.625rem;
    margin-left: 2.5rem;
  }

  /* =============================== gift-page-wrapper ================================= */
  .page-wrapper>.gift-page-wrapper ul.tabs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    background: 0 0 !important;
    gap: 3vw;
  }

  .page-wrapper>.gift-page-wrapper ul.tabs li.active {
    background: #3cb251;
    color: #fff;
    border: 0;
  }

  .page-wrapper>.gift-page-wrapper ul.tabs li {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: calc(50% - 1.5vw);
    height: 50px;
    font-size: 16px;
    background-color: #252a3a;
    color: #fff;
    border-radius: 5px;
    padding: 0 5vw;
    line-height: 12vw;
    font-weight: 500;
  }

  .page-wrapper>.gift-page-wrapper ul.tabs li+li {
    margin: 0;
  }

  .page-wrapper>.gift-page-wrapper ul.tabs li .total-count {
    top: 7px;
  }

  .no-data {
    background: #16161b66 !important;
    width: 100%;
    height: 280px;
    text-align: center;
    overflow: hidden;
  }

  .no-data .icon {
    margin-top: 13vw;
    font-size: 11vw;
  }

  .no-data .text {
    font-size: 4vw;
    margin-top: 3vw;
    font-weight: 400;
  }

  .page-wrapper section table thead tr th:nth-child(1) {
    display: none;
  }

  .page-wrapper section table thead tr th {
    font-weight: 400;
    color: #fff;
    height: 10vw;
    padding-left: 3vw;
  }

  .page-wrapper .bonus-code,
  .page-wrapper .promo-code {
    background: linear-gradient(47deg, #0c121e, #3a4155);
    border-radius: 4px;
    margin: 4vw;
    padding: 4vw;
    height: auto;
  }

  .page-wrapper .promo-code {
    margin: 0;
    background: linear-gradient(47deg, #3a4155, #252a3a);
    margin: 3vw 0;
  }

  .page-wrapper .bonus-code form,
  .page-wrapper .promo-code form {
    flex-wrap: wrap;
  }

  .page-wrapper .bonus-code form .fields+.txt {
    order: 1;
    margin: 0;
    color: #fff;
    font-weight: 400;
  }

  .page-wrapper .promo-code form .txt {
    margin: 0;
    margin-top: 15px;
    font-weight: 400;
  }

  .page-wrapper .promo-code form .fields button {
    font-size: 0.9rem;
    font-weight: 400;
  }

  .page-wrapper .bonus-code form .fields {
    order: 2;
    margin-top: 3vw;
    height: 10vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    overflow: hidden;
  }

  .page-wrapper .active-freespins table thead {
    background: transparent;
  }

  .page-wrapper .active-freespins {
    margin: 4vw;
    height: inherit;
    margin-top: 4vw !important;
  }

  .page-wrapper .active-freespins table thead tr th {
    width: 25vw !important;
    text-align: left;
    padding-left: 10px !important;
    font-weight: 200;
    color: rgba(255, 255, 255, 0.7);
    font-size: 3vw;
  }

  .page-wrapper .bonus-code form .fields input,
  main .promo-code form .fields input {
    width: calc(100% - 37vw) !important;
    margin-right: 2vw;
    height: 100%;
    background: rgba(255, 255, 255, 0.17);
    border: none;
    color: #fff;
  }

  .page-wrapper .bonus-code form .fields button,
  .page-wrapper .promo-code form .fields button {
    width: 35vw;
    min-width: inherit;
    height: 100%;
    line-height: 10vw;
    font-size: 1rem;
    margin: 0;
    background: #262a3a;
    border: 1px solid #464f5e;
    color: #ccd6f6;
  }

  .page-wrapper .promo-code form .fields {
    margin-top: 3vw;
    width: 100%;
  }

  .page-wrapper .active-freespins table th {
    height: inherit;
  }

  .bonus-filters ul.tabs li,
  .bonus-filters ul.tabs li.active {
    width: calc(50% - 1.5vw);
    height: 50px;
    font-size: 16px;
    background: #252a3a !important;
    color: #fff;
    border-radius: 5px !important;
  }

  main .page-wrapper .gift-page-wrapper .visible {
    min-height: 30vw;
    margin-top: 3vw;
    margin-bottom: 5rem;
  }

  .page-wrapper>.gift-page-wrapper ul.tabs li .total-count.disabled {
    min-height: inherit;
  }

  /* =========================== back ========================== */
  main .page-title {
    padding: 1.25rem 0;
    padding-top: 0.625rem;
    color: var(--responsivecolor);
    display: flex;
    align-items: center;
    background: rgba(12, 18, 30, 0.9);
    z-index: 1;
    justify-content: space-between;
  }

  main .page-title .back {
    color: #687594;
    font-size: 0.875rem;
  }

  main .page-title .page-name {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: #ccd6f6;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }

  main .page-title>* {
    /* width: calc(100% / 3); */
  }

  .close-page {
    color: #687594;
    cursor: pointer;
  }

  /* ================================== notification ================================ */
  main .page-wrapper div.heading .left h2 {
    display: none;
  }

  main .page-wrapper div.heading {
    padding: 0.8333rem 0.91429rem !important;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #1d212c;
    border-radius: 0.2778rem 0.2778rem 0 0;
  }

  main .page-wrapper div.heading .left {
    display: flex;
    align-items: center;
    width: 60%;
  }

  main .page-wrapper div.heading .left input {
    width: 95%;
    height: 2.2222rem;
    background-color: #272d3e;
    color: #fff;
    margin-left: 0;
  }

  main .page-wrapper div.heading .right {
    position: relative;
    width: 40%;
  }

  main .page-wrapper div.heading .right .btn {
    width: 100%;
    background: #3cb251;
    -webkit-transition: 0.2s ease;
    transition: 0.2s ease;
    height: 2.2222rem;
    font-size: 0.7rem;
  }

  /* ================================ personal info =================================== */
  .personal-info-content .nav,
  .personal-info-content .nav {
    display: none;
  }

  .personal-info-content,
  .personal-info-content {
    padding: 0;
  }

  .personal-info-content .half {
    width: 100%;
    float: inherit;
    padding-right: 0;
  }

  .personal-info-content .half:last-child,
  .personal-info-content .half:last-child {
    padding: 0;
  }

  .personal-info-content .content,
  .personal-info-content .content {
    padding: 0 3vw;
  }

  .personal-info-content .half>h2,
  .personal-info-content .half>h2 {
    line-height: 20vw;
    font-size: 4.5vw;
    color: var(--brandColor);
    font-weight: 400;
    margin: 0;
  }

  .personal-info-content .half ul.fields li .material-icons.locked,
  .personal-info-content .half ul.fields li .material-icons.locked {
    font-size: 4.3vw;
    color: #ccd6f6;
  }

  .personal-info-content .half ul.fields li .material-icons.editable,
  .personal-info-content .half ul.fields li .material-icons.editable {
    color: var(--brandColor);
    font-size: 4.3vw;
  }

  .personal-info-content .half ul.fields li .material-icons.editable:before,
  .personal-info-content .half ul.fields li .material-icons.editable:before {
    background: var(--brandColor);
  }

  main .page-wrapper ul.fields li .desc.info,
  main .page-wrapper ul.fields li .info.info {
    color: #ccd6f6;
  }

  .personal-info-content .half ul.fields li,
  .personal-info-content .half ul.fields li {
    position: relative;
    overflow: hidden;
    height: 14.286vw;
    line-height: 14.286vw;
    border-top: 0.01em solid #252a3a;
  }

  /* .personal-info-content .half ul.fields li > div {
    width: 27vw;
  } */
  /* ================================ personal info ======================================= */

  .page-wrapper .content .filter ul li .title {
    height: 6vw;
    line-height: 8vw;
    font-weight: 400;
    font-size: 3.5vw;
  }


  .personal-history-page-wrapper .content {
    /* padding: 0 3vw; */
  }

  .personal-history-page-wrapper .history-page-height {
    height: calc(100% - 5.83333rem) !important;
  }

  .page-wrapper .content .filter ul {
    flex-wrap: wrap;
    height: inherit;
    margin: 0;
  }

  .page-wrapper .content .filter ul li:first-child,
  .page-wrapper .content .filter ul li:nth-child(2) {
    float: left;
    width: calc(50% - 1vw);
    padding-top: 1vw;
  }

  .page-wrapper .content .filter ul li:not(:first-child) {
    margin: 0;
  }

  .page-wrapper .content .filter ul li:nth-child(3):nth-child(3),
  .page-wrapper .content .filter ul li:nth-child(4):nth-child(3) {
    margin: 0;
  }

  .page-wrapper .content .filter ul li:nth-child(3),
  .page-wrapper .content .filter ul li:nth-child(4) {
    float: left;
    width: calc(50% - 1vw);
    padding-top: 1vw;
  }

  .page-wrapper .content .filter ul li:last-child {
    margin: 3vw auto;
    display: block;
    width: calc(50% - 1vw);
    height: 11vw;
  }

  .page-wrapper .content .filter ul li:nth-child(even) {
    margin-left: 2vw;
  }

  .page-wrapper .content .filter ul li input,
  .page-wrapper .content .filter ul li select {
    border: 0.0625rem solid #3b4252;
    outline: 0;
    box-sizing: border-box;
    height: 2.5rem;
    background-color: #242836;
    color: #ccd6f6;
    font-size: 0.875rem;
    -webkit-touch-callout: default !important;
    -webkit-user-select: text !important;
    padding-left: 1.25rem;
    border-radius: 0.3125rem;
  }

  .page-wrapper .content .filter {
    padding: 0.8571rem 0;
  }

  .page-wrapper .content .filter ul li .btn {
    height: 11vw;
    font-size: 1.1rem;
    background: #252a3a;
    border: 1px solid #464f5e;
    color: #ccd6f6;
    font-weight: 400;
  }

  .table-wrapper .no-data {
    background-color: #0c121e !important;
    height: inherit;
  }

  /* ================================ bet-history ========================================== */
  #bet-history .wrapper {
    padding: 0;
  }

  #bet-history .wrapper .popup-title,
  .transaction-history {
    flex-wrap: wrap;
    padding: 0;
  }

  .transactions-list::-webkit-scrollbar {
    height: 1px;
    width: 1px;
  }

  .transactions-list::-webkit-scrollbar-thumb {
    background-color: red;
  }

  .transactions-list::-webkit-scrollbar-thumb {
    background-color: #878484;
    border-radius: 10px
  }

  #bet-history .wrapper .popup-title .filters,
  .transaction-history ul {
    flex-wrap: wrap;
    margin: 0px 0px 20px 0px;
    width: 100%;
  }

  #bet-history .wrapper .popup-title .filters .date-filter {
    flex-wrap: wrap;
    display: none;
  }

  #bet-history .wrapper .popup-title .filters ul {
    margin: 0;
    width: 100%;
    border-radius: 0;
  }

  #bet-history .wrapper .popup-title .title {
    width: 100%;
  }

  #bet-history .wrapper .popup-title .filters ul li,
  .transaction-history ul li {
    float: left;
    width: 25%;
    text-align: center;
    padding: 0;
  }

  #bet-history .wrapper .popup-title .title {
    height: 9vw;
    line-height: 9vw;
    border-left: 1vw solid #00ec66;
    color: #e2e8fb;
    padding-left: 3vw;
    font-size: 4vw;
    font-weight: 600;
  }

  #bet-history.show-as-page {
    margin: 0;
  }

  /* ============================ kyc ========================= */
  .kyc-forms-content {
    padding: 0;
  }

  .page-wrapper .kyc-forms .accordion-button {
    padding: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #252a3a;
    cursor: pointer;
    height: inherit;
    border-radius: 5px !important;
    overflow: hidden;
  }

  .page-wrapper .kyc-forms .accordion-item:first-of-type .accordion-button {
    border-radius: 5px !important;
  }

  .page-wrapper .kyc-forms .kyc-form-collapsable .form-name .material-icons {
    font-size: 6vw;
    color: #abb0c0;
    margin-right: 0.5rem;
  }

  .page-wrapper .kyc-forms .form-content {
    background: rgba(38, 40, 46, 0.35);
  }

  .page-wrapper .kyc-forms .form-content .form-description {
    margin: 0;
    padding: 4vw;
  }

  .page-wrapper .kyc-forms .form-content ul.kyc-questionnaires li {
    border: 0.0625rem solid rgba(78, 78, 78, 0.35);
    border-radius: 0.1875rem;
    margin-top: 4vw;
    background: #1e2430;
    margin: 4vw;
  }

  .page-wrapper .kyc-forms .form-content ul.kyc-questionnaires .kyc-questionnaire-collapsible {
    padding: 4vw;
    color: #fff;
    background-color: rgba(50, 53, 61, 0.35);
  }

  .questionnaire-row {
    flex-wrap: wrap;
    height: inherit;
    gap: 4vw;
    margin: 5vw 0;
  }

  .questionnaire-row .btn-grey {
    width: 100%;
    font-weight: 600;
  }

  .page-wrapper .kyc-forms .kyc-input {
    width: 100%;
    border: 0.0625rem solid #3b4252;
    background-color: #242836;
    padding-left: 1.25rem;
    border-radius: 0.3125rem;
    color: #ccd6f6;
    font-size: 0.875rem;
    width: 100%;
    height: 10vw;
  }

  .page-wrapper .kyc-questionnaires li .content {
    max-height: 100%;
    background: #252934;
  }

  .kyc-questionnaire-btn .btn-save,
  .btn-save {
    background-color: #3cb251 !important;
    color: #000 !important;
    width: 100%;
    font-weight: 600;
  }

  .page-wrapper .kyc-forms .kyc-questionnaire-row {
    flex-wrap: wrap;
  }

  .page-wrapper .kyc-forms .file-inp-wrapper {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
    width: 100%;
  }

  .page-wrapper .kyc-forms .file-inp-wrapper .max-size {
    display: none;
  }

  .page-wrapper .kyc-forms .file-inp-wrapper span.chosen-file-name {
    display: none;
  }

  .kyc-questionnaire-btn {
    height: 12vw;
    margin-bottom: 6vw;
  }

  .kyc-questionnaire-btn .btn-save {
    height: 12vw;
  }
}

/* ============================================================= */
/* ============================================================= */
/* ============================================================= */

.sel,
button,
input,
select,
textarea {
  border: 0.0625rem solid #3b4252;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  height: 2.5rem;
  background-color: #242836;
  color: #ccd6f6;
  font-size: 0.875rem;
  font-family: Poppins-Medium;
  -webkit-touch-callout: default !important;
  -webkit-user-select: text !important;
  padding-left: 1.25rem;
  border-radius: 0.3125rem;
}

/********8 BET Page *********/

.bets-table {
  background-color: #1c232d;
  color: #ffffff;
  border-collapse: collapse;
  width: 100%;
}

.bets-table th,
.bets-table td {
  white-space: noWrap;
  padding: 12px 15px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 40px;


}

.bets-table tr {
  background: #0003;
  border-bottom: .0625rem solid #333645;

}

.tabs-active {
  color: rgb(0, 236, 102) !important;
}

.tabs-active:after {
  content: "";
  position: absolute;
  left: 0.5rem;
  bottom: 0px;
  width: calc(100% - 1rem);
  height: 0.2333rem;
  border-radius: 100px;
  background-color: rgb(0, 236, 102);
}

.bets-table thead {
  background-color: #ffffff0a;
}

.bets-table tbody tr:hover {
  background-color: #ffffff0d;
  font-size: 16px;
  cursor: pointer;
}

.bets-table th {
  text-transform: uppercase;
}

/* pagination  */
.transaction-pagination ul {

  display: flex;
  gap: 20px;
  align-items: center;
}

.transaction-pagination a {
  font-size: 16px;
}

.transaction-pagination .pagination-active {
  border: 1px solid #6a71c7 !important;

  padding: 8px 16px;
  border-radius: 10px;
  box-shadow: 2px 2px 20px 0 #3c3c43;

}