.home-page-content {
  display: flex;
  flex-direction: column;
  gap: 3.125rem;
  margin-top: 1.875rem;
}

/* ================================= Banner slider ============================= */
.mini-games-wrapper {
  margin-bottom: 3.125rem;
}

.mini-games-wrapper:last-child {
  margin: 0;
}

.banner-slider-content {
  position: relative;
  background: #1b1f2e;
  height: 290px;
  border-radius: 1.5rem;
  overflow: hidden;
}

.banner-slider-content img {
  height: 290px;
  width: 100%;
  object-fit: cover;
}

.banner-slider-content .slick-dots {
  display: flex !important;
  bottom: 2rem;
  align-items: center;
  justify-content: center;
  gap: 0.6rem;
}

.banner-slider-content .slick-dots li button:before {
  display: none;
}

.banner-slider-content .slick-dots li,
.banner-slider-content .slick-dots li button {
  padding: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  overflow: hidden;
  -webkit-transition: 0.4s ease;
  transition: 0.4s ease;
  background: #ccd6f6;
}

.banner-slider-content .slick-dots li.slick-active,
.banner-slider-content .slick-dots li.slick-active button {
  height: 0.625rem;
  width: 2.5rem;
  background: var(--swiper-theme-color);
  border-radius: 20px;
}

/* ============================================================================= */

/* ================================ category =================================== */

.categories {
  margin-top: 1.875rem;
  width: 100%;
}

.categories .categories-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 7.25rem;
  grid-gap: 0.75rem;
}

.categories .categories-wrapper .category a {
  position: relative;
  background-color: #1b1f2e;
  border-radius: 1.5rem;
  width: 100%;
  height: calc(100% - 0.25rem);
  /* padding: 0 0 0 3.75rem; */
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-shadow: inset 0 -4px 0 var(--color);
  box-shadow: inset 0 -4px 0 var(--color);
  text-decoration: none;
}

.categories .categories-wrapper .category a:hover img {
  -webkit-transform: scale(1.06);
  transform: scale(1.06);
}

.categories .categories-wrapper .category a img {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}

.categories .categories-wrapper .category a.sport {
  --color: #0afe36;
}

.categories .categories-wrapper .category a.casino {
  --color: #ff7c52;
}

.categories .categories-wrapper .category a.live-casino {
  --color: #f12a2a;
}

.categories .categories-wrapper .category a.mini-games {
  --color: #850bff;
}

.categories .categories-wrapper .category a:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0.825rem;
  background: var(--color);
  -webkit-filter: blur(1.25rem);
  filter: blur(1.25rem);
}

.categories .categories-wrapper .category a span.name {
  font-size: 1.5rem;
  z-index: 9;
  color: #fff;
  font-weight: bold;
  padding: 0 3.75rem;
}

/* ============================================================================= */
/* ============================= Mini Games ==================================== */

.game-head-wrapper .head-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.game-head-wrapper .head-wrapper .right {
  font-size: 0.875rem;
  margin-left: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.game-head-wrapper .head-wrapper .right .view-more {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.game-head-wrapper .head-wrapper .right .view-more a {
  color: var(--brandColor);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: 0.625rem;
  font-weight: 400;
}

.game-head-wrapper .head-wrapper .right .view-more span {
  color: #545961;
}

.game-head-wrapper .head-wrapper h1 {
  font-size: 1.5rem;
  font-weight: 100;
  margin: 0;
}

.game-head-wrapper .head-wrapper+.swiper-container {
  margin-top: 1.75rem;
  overflow: hidden;
  min-height: 9.4375rem;
}

.game-head-wrapper .head-wrapper+.swiper-container .swiper-slide {
  background: #1b1f2e;
  border-radius: 1.5rem;
  width: 100%;
  overflow: hidden;
}

.game-head-wrapper .head-wrapper+.swiper-container .swiper-slide a {
  cursor: pointer;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 1.5rem;
  overflow: hidden;
}

.game-head-wrapper .head-wrapper+.swiper-container .swiper-slide a:not(.btn) {
  height: 100%;
}

.game-head-wrapper .head-wrapper+.swiper-container .swiper-slide a img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.game-head-wrapper .slider-btn-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 0.625rem;
  gap: 0.625rem;
}

.game-head-wrapper .slider-btn-wrapper .swiper-next,
.game-head-wrapper .slider-btn-wrapper .swiper-prev {
  height: 2.8125rem;
  width: 2.8125rem;
  border-radius: 50%;
  background: url(../../assets/images/home/slider/arrow.svg);
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid rgba(255, 255, 255, 0.15);
  cursor: pointer;
  z-index: 9;
  -webkit-transition: 0.1s ease;
  transition: 0.1s ease;
}

.game-head-wrapper .slider-btn-wrapper .swiper-next.swiper-button-disabled,
.game-head-wrapper .slider-btn-wrapper .swiper-prev.swiper-button-disabled {
  opacity: 0.4;
}

.game-head-wrapper .slider-btn-wrapper .swiper-prev {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

/* ========================= slider design ======================================== */

.game-swiper-container .game-swiper-slider {
  /* background: #1b1f2e; */
  border-radius: 1.5rem;
  width: 100%;
  overflow: hidden;
}

.game-swiper-container {
  margin-top: 1.75rem;
  overflow: hidden;
  min-height: 9.4375rem;
}

.game-swiper-container .game-swiper-slider .game-thumb-container {
  cursor: pointer;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 1.5rem;
  overflow: hidden;
}

.game-swiper-container .game-swiper-slider img {
  /* width: 235px; */
  width: 100%;
  /* height: 235px; */
  /* object-fit: fill; */
  object-fit: cover;
  background-size: cover;
  aspect-ratio: 1;
  /* height: 205px; */

}

.game-swiper-slider-img {
  display: grid;
  gap: 17px;
  /* margin-right: 17px; */
  position: relative;
  /* height: 238px; */
  border: 2px solid #1a1a1a;
  border-radius: 25px;

  /* width: 242px; */
}

.game-swiper-slider-img .slot-hover {
  position: absolute;
  text-align: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #0F1B24CC;
  border-radius: 1.5rem;
  transform: translateY(101%);
  transition: transform 180ms;
  z-index: 1;
}

.game-swiper-slider-img:hover .slot-hover {
  transition-duration: .5s;
  transform: translateY(0);
}

.game-swiper-slider-img .slot-hover .play {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  cursor: pointer;
  width: 52px;
  height: 52px;
}

.game-swiper-slider-img .star-wrapper .material-icons {
  /* position: absolute;
  bottom: 15px;
  right: 0;
  height: 2.6rem;
  line-height: 2.6rem;
  padding: 0 1rem;
  color: var(--white);
  -webkit-user-select: none;
  user-select: none; */
  float: right;
  font-size: 24px;
  cursor: pointer;
}


.game-swiper-slider-img .star-wrapper {
  align-content: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 3rem;
  line-height: 2.6rem;
  padding: 0 1rem;
  color: var(--white);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;

  svg {
   fill: #ffce63;
  }
}

.live-casino .game-swiper-container .game-swiper-slider .game-thumb-container:not(.btn) {
  height: 100%;
}

.live-casino .game-swiper-container .game-swiper-slider img {
  aspect-ratio: 361/249;
}

.live-casino .game-swiper-container .game-swiper-slider img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.live-casino-game {
  margin-right: 17px;
}

/* ============================================================================= */

/* ============================= payment ======================================= */
section.payments {
  /* margin-top: 3.125rem; */
  position: relative;
  background: #1b1f2e;
  border-radius: 1.5rem;
}

section.payments .wrapper {
  position: relative;
  padding: 4.3125rem 4.0625rem;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 2rem 0;
  z-index: 1;
  border-radius: 0.625rem;
  overflow: hidden;
}

section.payments .wrapper img {
  height: 2.175rem;
}

/* ==================================================================== */

/* =============================== buy crypto  ========================= */

.banner-crypto-img {
  position: relative;
  overflow: hidden;
  border-radius: 1rem;
  height: 7rem;
}

.banner-crypto-img img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.banner-crypto-img .text-wrapper {
  position: absolute;
  left: 3.8rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 9;
  color: #00ec66;
  letter-spacing: 1px;
}

.banner-crypto-img .text-wrapper h2 {
  font-size: 2rem;
  /* margin: 0; */
  color: var(--secondary-text-color);
  font-weight: bold;
}

.banner-crypto-img .text-wrapper h6 {
  font-size: 1.2rem;
  font-weight: bold;
}

.banner-crypto-img span {
  overflow: unset;
  position: absolute;
  right: 8.6667rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 9;
  text-decoration: none;
}

.color-btn {
  min-width: 7.3333rem;
  height: 2.6667rem;
  border-radius: 2rem;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 1.8667rem;
  cursor: pointer;
  overflow: hidden;
  color: #0c121e;
  background: #00ec66;
  box-shadow: 0 2px 5px rgba(38, 51, 77, 0.03);
  background-position: center;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}

.color-btn:hover {
  filter: brightness(1.1);
}

.color-btn:before,
.color-btn:after {
  content: "";
  position: absolute;
  z-index: -1;
  border-radius: 2rem;
  display: block;
  pointer-events: none;
}

.color-btn:before {
  background: linear-gradient(to top, transparent, rgba(0, 0, 0, 0.4));
  top: 0;
  height: 100%;
  width: 100%;
  right: 0;
  margin: auto;
}

.color-btn:after {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 0.125rem;
  width: 80%;
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease;
  height: 0.5rem;
  background: #00ec66;
  -webkit-filter: blur(0.625rem);
  filter: blur(0.625rem);
}

/* ==================================================================== */
/* ============================== promotion =========================== */
.promotion {
  margin-top: 2.125rem;
}

.promotion-card {
  /* background: #1b1f2e; */
  border-radius: 1.5rem;
  width: 100%;
  overflow: hidden;
}

.promotion-card-content {
  margin: 0px 16px 0px 0px;
  border-radius: 1.5rem;
}

.promotion-card-image {
  border-radius: 1.5rem;
}

.promotion-card-image img {
  height: 15.5875rem;
  width: 100%;
  background-size: cover !important;
  border-radius: 1.5rem 1.5rem 0 0;
}

.promotion-card-text {
  position: relative;
  padding: 1.5625rem;
  overflow: hidden;
  /* border-radius: 0 0 0.4895rem 0.4895rem; */
  border-radius: 0 0 1.5rem 1.5rem;

  background: #1b1f2e;
}

.promotion-card-text h2 {
  text-align: center;
  font-size: 1.25rem;
  font-weight: bold;
}

.promotion-card-text .text-content p {
  color: #ccd6f6;
}

.promotion-card-text .text-content {
  margin-top: 1.1875rem;
  line-height: 1.5625rem;
  font-size: 1rem;
  height: 5rem;
  overflow: hidden;
}

.promotion-card-text .btn-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 0.625rem;
}

.promotion-card-text .btn-wrapper .read-more {
  cursor: pointer;
  padding: 0;
  min-width: unset;
  text-decoration: underline;
  color: var(--secondary-text-color);
  font-size: 1rem;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  font-weight: 400;
}

.promotion-card-text .btn-wrapper a {
  text-decoration: none;
}

.popupImg img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.popupImg p {
  font-size: 14px;
  padding: 5px 10px;
  margin: 0px;
  font-weight: normal;
}

/* ==================================================================== */
/* ========================================================================= */
/* ================================ media query ============================ */
/* ========================================================================= */
@media screen and (max-width: 991.98px) {
  .categories .categories-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }

  .banner-crypto-img span {
    right: 1rem;
  }

  .banner-crypto-img .text-wrapper {
    left: 2rem;
  }

  section.payments .wrapper {
    grid-template-columns: repeat(3, 1fr);
    padding: 3.3125rem 3.0625rem;
  }

  header,
  header.scrolled {
    background: #0c121e;
  }

  .home-page-content {
    margin-top: 0;
  }

  .game-swiper-slider-img {
    gap: 20px;
    width: auto;
    height: 100%;
  }

  /* .game-swiper-slider .game-swiper-slider-img {
    margin-left: 15px;
    margin-right: 15px;
  } */

  /* 
  .game-swiper-slider:first-child .game-swiper-slider-img {
    margin-left: 0;
  }

  .game-swiper-slider:nth-child(1) .game-swiper-slider-img {
    margin-right: 0;
  } */


  /* .game-swiper-slider:first-child .game-swiper-slider-img {
    margin-left: 15px;
    margin-right: 0;
  }

  .game-swiper-slider:nth-child(1) .game-swiper-slider-img {
    margin-left: 0;
    margin-right: 15px;
  } */


  .game-head-wrapper .head-wrapper {
    flex-wrap: wrap;
  }

  .game-head-wrapper .head-wrapper .right {
    width: 100%;
    justify-content: space-between;
  }

  section.payments .wrapper {
    position: relative;
    padding: 1.9rem 1.8333rem 1.9rem 1.2667rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr) 0.6fr;
    grid-gap: 1.4rem 0;
    z-index: 1;
    border-radius: 0.3333rem;
  }

  section.payments .wrapper div {
    display: flex;
    align-items: center;
  }

  section.payments .wrapper div .icon-wrapper {
    max-width: 1.4rem;
    margin-right: 1rem;
  }

  section.payments .wrapper div .icon-wrapper img {
    max-width: 100%;
    max-height: 100%;
  }

  section.payments .wrapper div span {
    font-size: 0.8125rem;
    color: #ccd6f6;
    font-weight: 400;
  }
}

@media screen and (max-width: 767.98px) {
  .game-swiper-container .game-swiper-slider p {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
  }

  .banner-slider-content img,
  .banner-slider-content {
    height: 610px;
    object-fit: contain;
  }

  .banner-slider-content div {
    position: relative;
  }

  .banner-slider-content .slick-dots {
    bottom: 1rem;
  }

  /* .banner-slider-content .slick-dots {
    bottom: 4rem;
  } */
  .game-head-wrapper .slider-btn-wrapper .swiper-next,
  .game-head-wrapper .slider-btn-wrapper .swiper-prev {
    height: 2.25rem;
    width: 2.25rem;
  }

  main .banner-slider .banner-slider-content a {
    position: relative;
  }

  main .banner-slider .banner-slider-content .btn {
    overflow: unset;
    position: relative;
    z-index: 1;
    color: #0c121e;
    background: #00ec66;
    box-shadow: 0 2px 5px rgba(38, 51, 77, 0.03);
    background-position: center;
    transition: 0.3s ease;
    position: absolute;
    left: 50%;
    bottom: 1.775rem;
    width: 10.875rem;
    height: 3rem;
    font-size: 0.875rem;
    transform: translateX(-50%);
    border: none;
    font-weight: 500;
  }

  .categories .categories-wrapper .category a {
    height: 218px;
  }

  .categories .categories-wrapper .category {
    height: 218px;
  }

  .categories .categories-wrapper {
    grid-auto-rows: inherit;
    grid-gap: 1.875rem;
  }

  .categories .categories-wrapper .category a span.name {
    font-size: 1.5875rem;
    padding: 0 2.375rem;
  }

  .banner-crypto-img {
    position: relative;
    overflow: hidden;
    border-radius: 1rem;
    height: 13.3333rem;
    background: #1b1f2e;
  }

  .banner-crypto-img .text-wrapper {
    position: absolute;
    top: 1.6667rem;
    left: 0;
    width: 100%;
    text-align: center;
    z-index: 9;
    color: #00ec66;
    letter-spacing: 1px;
    transform: inherit;
  }

  .banner-crypto-img .text-wrapper h2 {
    font-size: 1.5rem;
    color: #fff;
    margin-bottom: 0.5rem;
  }

  .banner-crypto-img .text-wrapper h6 {
    font-size: 1.2rem;
    font-weight: 500;
    margin: 0;
  }

  .banner-crypto-img span {
    overflow: unset;
    position: relative;
    z-index: 1;
    color: #0c121e;
    background: #00ec66;
    box-shadow: 0 2px 5px rgba(38, 51, 77, 0.03);
    background-position: center;
    transition: 0.3s ease;
    position: absolute;
    bottom: 0.8334rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9;
    font-size: 0.8875rem;
    height: 2.625rem;
    top: inherit;
    border-radius: 2.375rem;
    min-width: inherit;
    font-weight: 500;
    width: 8.7rem;
    overflow: hidden;
  }

  .game-head-wrapper .head-wrapper h1 {
    color: #fff;
  }

  .promotion-card-text h2 {
    margin: 0;
    font-weight: 600;
  }

  .promotion-card-text .btn-wrapper .color-btn {
    font-weight: 500;
  }

  /* ========================== payment=============================== */
  section.payments {
    position: relative;
    margin: 2.6667rem auto;
    width: calc(100% - 0.75rem);
    border: 1px solid #31364b !important;
    border-radius: 0.9375rem;
    background: 0 0 !important;
  }

  .categories .categories-wrapper {
    grid-gap: 0.75rem;
  }

  .categories {
    margin-top: 1.25rem;
  }

  .banner-crypto-img img {
    height: auto;
  }

  .banner-slider-content .slick-dots li,
  .banner-slider-content .slick-dots li button {
    margin: 0;
  }



  .game-swiper-container .game-swiper-slider img {
    width: 100%;
    height: 100%;
    /* object-fit: fill; */
    /* height: 205px; */
  }
}

@media screen and (max-width: 480.98px) {

  .banner-slider-content img,
  .banner-slider-content {
    height: 298px;
    object-fit: contain;
  }

  .categories .categories-wrapper .category a,
  .categories .categories-wrapper .category {
    height: 106px;
  }
}