header {
  width: 100%;
  display: flex;
  height: 90px;
  align-items: center;
  position: relative;
  grid-area: head;

  @media screen and (max-width: 991.98px) {
    height: 80px !important;
  }
}

header:after {
  content: "";
  position: absolute;
  left: 0;
  top: -1rem;
  height: 170px;
  width: 100%;
  -webkit-filter: blur(0.625rem);
  filter: blur(0.625rem);
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#0c121eef),
    color-stop(104%, transparent)
  );
  background: linear-gradient(to bottom, #0c121eef, transparent 104%);
  opacity: 0;
  pointer-events: none;
  z-index: -1;
}

header.scrolled:after {
  opacity: 1;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#0c121e),
    color-stop(104%, transparent)
  );
  background: linear-gradient(to bottom, #0c121e, transparent 104%);
}

header .middle {
  max-width: unset;
  width: 100%;
  height: 100%;
  left: unset;
}

.header-content {
  width: 100%;
  height: 100%;
  padding: 10px 24px;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  background: linear-gradient(180deg, #1e3241 0%, #11212e 100%);
  border-bottom: 2px solid;
  border-image-source: linear-gradient(
    180deg,
    rgba(30, 50, 65, 0) 0%,
    #1e3241 100%
  );

  @media screen and (max-width: 991.98px) {
    grid-template-columns: 1fr 2fr;
    padding: 0px 14px;
  }
}

.header-logo {
  position: relative;

  img {
    height: 3rem !important;
  }
}

.header-logo--mobile {
  display: none;
}

.header-content__wallet {
  border: unset;
  display: flex;
  background-image: url("../../assets/images/header/wallet-background.svg");
  background-size: auto 65px;
  background-repeat: no-repeat;
  border-radius: 16px;
  max-height: 60px;
  padding-bottom: 4px;
  justify-self: center;

  @media screen and (max-width: 991.98px) {
    max-height: 52px;
    background-size: auto 60px;
    justify-self: flex-end;

    button {
      padding: 14px;
      max-height: 52px;
    }
  }
}

.header-content__wallet-balance {
  display: flex;
  align-items: center;
  font-weight: 700;
  padding: 3px 10px 0 0;
  font-size: 18px;
  color: #ffffff;
  background-color: transparent;

  img {
    padding-top: 7px;
    margin-right: -10px;
  }

  @media screen and (max-width: 991.98px) {
    padding-top: 6px;
  }
}

.header-content__wallet-button {
  z-index: 5;
  padding: 15px;
  min-width: unset;
  display: flex;
  height: 100%;
  color: #101010;
  font-size: 16px;
  border-radius: 16px;
  background: linear-gradient(129.51deg, #ff9f00 -12.53%, #ffdb8c 82.06%);

  img {
    margin-right: 6px;
  }
}

.header-content__user-panel {
  justify-self: flex-end;
  display: flex;
  gap: 20px;
}

.header-content__user-text {
  font-weight: 700;
  margin: 0;
  font-size: 16px;
  &.--subtitle {
    color: #a8a8a8;
    font-size: 14px;
  }
}

.header-content__user-buttons-wrapper {
  display: flex;
  gap: 8px;
}

.header-content__user-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px !important;
  border: 3px solid #ffffff0d;
  border-radius: 18px;
  height: unset !important;
  background: #253749;
  max-height: 50px;
  img {
    width: 18px;
    height: 18px;
  }
}

.header-content__user-progress-button {
  display: flex;
  margin-top: 5px;
}

.header-content__user-panel {
  display: flex;

  @media screen and (max-width: 991.98px) {
    visibility: hidden;
    position: absolute;
  }
}

.header-content__buttons-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 100%;
  grid-column: none;
  align-self: flex-end;
  font-size: 16px;
}

.header-content__login {
  height: 40px;
  padding: 8px 15px;
  background-color: #253749 !important;
  border-radius: 12px !important;
}

.header-content__signup {
  height: 40px;
  padding: 8px 15px;
  color: #101010 !important;
  background: linear-gradient(129.51deg, #ff9f00 -12.53%, #ffdb8c 82.06%);
  border-radius: 12px !important;
}

.left {
  margin-right: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  max-width: 150px;
}

.right {
  width: 100%;
}

header .middle .left {
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease;
}

.search-bar {
  position: relative;
  line-height: 2.5rem;
  margin-bottom: 0.0625rem;
  position: absolute;
  width: 23.75rem;
  -webkit-transition: 0.4s ease;
  transition: 0.4s ease;
  z-index: 99;
}

header.search-mode .left .search-bar {
  /* width: 100%; */
  max-width: 1360px;
  width: calc(100% - 3.75rem);
}

/* .search-mode .search-bar input {
  max-width: 300px;
} */

.search-bar .wrp {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.box {
  overflow: hidden;
  border: var(--surface-border);
  display: block;
  border-radius: 1.5rem;
  background: linear-gradient(101deg, #1a1e2b, #0c121e2b 52%, #171c28);
  position: relative;
}

.search-bar .search-wrapper {
  position: relative;
  width: 100%;
  border-radius: 3.125rem;
}

.box:after,
.box:before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  background-image: radial-gradient(
    rgba(204, 214, 246, 0.116),
    transparent,
    transparent
  );
  z-index: -1;
  pointer-events: none;
  border-radius: 0.4rem;
}

.search-bar .search-wrapper::after,
.search-bar .search-wrapper:before {
  background: radial-gradient(#ffffff0a, transparent, transparent) !important;
}

.box:before {
  top: 0;
  height: 20rem;
  background-repeat: no-repeat;
  background-position: 98% 130%;
  background-size: 200% 200%;
}

.box:after {
  height: 6rem;
  background-repeat: no-repeat;
  background-position: 55% 2%;
  background-size: 194% 305%;
  bottom: 0;
}

.search-bar .icon {
  position: absolute;
  top: 0;
  color: var(--color);
  font-size: 1.5rem;
  text-align: center;
  height: 100%;
  width: 1rem;
  left: 1.5625rem !important;
  z-index: 9;
}

.search-bar input,
.search-bar input:focus,
.search-bar input:active {
  border: 0;
  height: 3.75rem;
  width: 100%;
  line-height: 0.9375rem;
  font-size: 0.875rem;
  padding-right: 2.5rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: linear-gradient(
    101deg,
    #1a1e2b,
    #0c121e2b 52%,
    #171c28
  ) !important;
  border-radius: 0.625rem;
  color: var(--white);
  padding-left: 3.75rem;
  border-radius: 2.5rem;
}

.search-bar input::-webkit-input-placeholder {
  color: var(--secondary-text-color);
}

.search-bar input::-moz-placeholder {
  color: var(--secondary-text-color);
}

.search-bar input::-ms-input-placeholder {
  color: var(--secondary-text-color);
}

.search-bar input::placeholder {
  color: var(--secondary-text-color);
}

input:-webkit-autofill {
  background: linear-gradient(
    101deg,
    #1a1e2b,
    #0c121e2b 52%,
    #171c28
  ) !important;
}

.search-bar .search-wrapper .search-right {
  position: absolute;
  right: 1.5625rem !important;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: var(--secondary-text-color);
  font-size: 0.875rem;
  opacity: 0;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  -webkit-transition-delay: 0.2s ease;
  transition-delay: 0.2s ease;
  pointer-events: none;
  white-space: nowrap;
}

header.search-mode .left .search-bar .search-right {
  opacity: 1;
  pointer-events: all;
}

header.search-mode .left .search-bar .info {
  opacity: 0.5;
  font-weight: 400;
}

.search-bar .search-wrapper .info {
  margin: 0 0.9375rem;
}

.search-bar .search-wrapper .clear {
  margin-right: 0.95rem;
  font-weight: 400;
  cursor: pointer;
}

.search-bar .search-wrapper .clear:hover {
  -webkit-filter: brightness(2);
  filter: brightness(2);
  cursor: pointer;
}

.search-bar .search-wrapper .close {
  cursor: pointer;
}

.search-bar .voice {
  position: relative;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  height: 3.75rem;
  width: 3.75rem;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 1.25rem;
  cursor: pointer;
}

.search-bar .voice img {
  filter: grayscale(1) brightness(1);
  width: 1.5625rem;
}

header .middle .right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}

header .middle .right .authorize.visible,
header .middle .right .user-info.visible {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

header .middle .right .authorize > ul,
header .middle .right .user-info > ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

header .middle .right .authorize > ul > li + li,
header .middle .right .user-info > ul > li + li {
  margin-left: 1.5625rem;
}

header .middle .right .authorize ul li .btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 2.6rem;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  min-width: 4rem;
  border-radius: 2rem;
  padding: 0 1.8667rem;
  cursor: pointer;
}

header .middle .right .authorize ul li .signup {
  overflow: unset;
  position: relative;
  z-index: 1;
  color: #0c121e;
  background: #00ec66;
  -webkit-box-shadow: 0 2px 5px rgba(38, 51, 77, 0.03);
  box-shadow: 0 2px 5px rgba(38, 51, 77, 0.03);
  background-position: center;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}

header .middle .right .authorize ul li .signup:after,
header .middle .right .authorize ul li .signup:before {
  content: "";
  position: absolute;
  z-index: -1;
  border-radius: 2rem;
  display: block;
  pointer-events: none;
}

header .middle .right .authorize ul li .signup:before {
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(transparent),
    to(rgba(0, 0, 0, 0.4))
  );
  background: linear-gradient(to top, transparent, rgba(0, 0, 0, 0.4));
  top: 0;
  height: 100%;
  width: 100%;
  right: 0;
  margin: auto;
}

header .middle .right .authorize ul li .signup:after {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 0.125rem;
  width: 80%;
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease;
  height: 0.5rem;
  background: #00ec66;
  -webkit-filter: blur(0.625rem);
  filter: blur(0.625rem);
}

header .middle .right .user-info {
  width: 100%;
}

header .middle .right .authorize,
header .middle .right .user-info {
  display: none;
}

header .middle .right .authorize.visible,
header .middle .right .user-info.visible {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

header .middle .right .change-lang {
  position: relative;
  height: 3.75rem;
}

header .middle .right .change-lang div.current-lang {
  text-transform: uppercase;
  margin-left: 0.9375rem;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
}

header .middle .right .change-lang div.current-lang img {
  margin-left: 0.4375rem;
  height: 0.9375rem;
  margin-right: 0.3125rem;
}

header .middle .right .change-lang div.current-lang * {
  vertical-align: middle;
}

header .middle .right .change-lang div.current-lang span.material-icons {
  font-size: 1.25rem;
}

header .middle .right .change-lang .language-list-wrapper {
  position: absolute;
  top: 4.5rem;
  visibility: hidden;
  opacity: 0;
  line-height: normal;
  z-index: 999;
  right: 0;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  -webkit-transform: translateY(1.25rem);
  transform: translateY(1.25rem);
}

header .middle .right .change-lang .language-list-wrapper ul {
  overflow: hidden;
  min-width: 13.875rem;
  border-radius: 0.625rem;
  border: var(--surface-border);
  background: linear-gradient(101deg, #1a1e2b, #0c121ee6 52%, #171c28);
}

header .middle .right .change-lang .language-list-wrapper ul li {
  cursor: pointer;
  border-bottom: 0.0625rem solid #464b5840;
}

header .middle .right .change-lang .language-list-wrapper ul li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.625rem 0.9375rem;
}

header .middle .right .change-lang .language-list-wrapper ul li span {
  display: inline-block;
  margin-left: 0.5rem;
  color: #fff;
}

.changeLang {
  position: relative;
  height: 3.75rem;
  margin-left: 0.9375rem;
}

.currentLang {
  text-transform: uppercase;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
  font-weight: 500;
}

.languageListWrapper li a span {
  font-weight: 400;
}

.currentLang img {
  margin-left: 0.4375rem;
  height: 0.9375rem;
  margin-right: 0.3125rem;
}

.currentLang span img {
  margin: 0;
  height: 100%;
}

.languageListWrapper {
  position: absolute;
  top: 4.5rem;
  visibility: hidden;
  opacity: 0;
  line-height: normal;
  z-index: 999;
  right: 0;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  -webkit-transform: translateY(1.25rem);
  transform: translateY(1.25rem);
}

.currentLang .languageListWrapper:hover,
.changeLang div.currentLang:hover .languageListWrapper {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.languageListWrapper ul {
  overflow: hidden;
  min-width: 13.875rem;
  border-radius: 0.625rem;
  border: var(--surface-border);
  background: linear-gradient(101deg, #1a1e2b, #0c121ee6 52%, #171c28);
  list-style-type: none;
  padding-left: 0;
}

.languageListWrapper li {
  cursor: pointer;
  border-bottom: 0.0625rem solid #464b5840;
}

.languageListWrapper li:hover {
  background: rgba(255, 255, 255, 0.04);
}

.languageListWrapper li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.625rem 0.9375rem;
  gap: 8px;
  color: #fff;
  text-decoration: none;
}

.box {
  overflow: hidden;
  border: var(--surface-border);
  display: block;
  border-radius: 1.5rem;
  background: linear-gradient(101deg, #1a1e2b, #0c121e2b 52%, #171c28);
  position: relative;
}

.box::before,
.box::after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  background-image: radial-gradient(
    rgba(204, 214, 246, 0.116),
    transparent,
    transparent
  );
  z-index: -1;
  pointer-events: none;
  border-radius: 0.4rem;
}

.box::before {
  top: 0;
  height: 20rem;
  background-repeat: no-repeat;
  background-position: 98% 130%;
  background-size: 200% 200%;
}

.box::after {
  top: 0;
  height: 20rem;
  background-repeat: no-repeat;
  background-position: 98% 130%;
  background-size: 200% 200%;
}

.headerButton {
  display: flex;
  align-items: center;
  gap: 25px;
}

.headerButton button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 2.875rem;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  overflow: hidden;
  border: var(--surface-border);
  display: block;
  border-radius: 1.5rem;
  padding: 0 1.8667rem;
  cursor: pointer;
  overflow: hidden;
  font-weight: bold;
  position: relative;
  min-width: 7.3333rem;
  height: 2.6667rem;
}

.graybtn.btn {
  background: var(--graybtn) !important;
  color: var(--secondary-text-color) !important;
  border: var(--surface-border) !important;
}

.graybtn.btn:hover,
.graybtn.btn:global(.btn:first-child:active),
.graybtn.btn:global(.btn:focus-visible) {
  background: linear-gradient(
    101deg,
    #1a1e2b,
    #8b8b8b2b 52%,
    #171c28
  ) !important;
  border: var(--surface-border) !important;
  box-shadow: none;
}

.colorbtn.btn {
  color: #0c121e !important;
  background: var(--colorbtn) !important;
  -webkit-box-shadow: 0 2px 5px rgba(38, 51, 77, 0.03);
  box-shadow: 0 2px 5px rgba(38, 51, 77, 0.03);
  background-position: center;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  border: none !important;
}

.colorbtn.btn:hover,
.colorbtn.btn:global(.btn.active),
.colorbtn.btn :global(:not(.btn-check) + .btn:active) {
  background: var(--colorbtn) !important;
  color: #0c121e !important;
  border: var(--surface-border);
  filter: brightness(1.1);
}

/* =============================== after logi user profile ====================================== */

header .middle .right .user-info .deposit {
  overflow: unset;
  position: relative;
  z-index: 1;
  color: #0c121e;
  background: #00ec66;
  -webkit-box-shadow: 0 2px 5px rgba(38, 51, 77, 0.03);
  box-shadow: 0 2px 5px rgba(38, 51, 77, 0.03);
  background-position: center;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  height: 3.75rem;
  width: 8.3125rem;
  margin-right: 1.25rem;
  border-radius: 2rem;
}

header .middle .right .user-info .deposit:after,
header .middle .right .user-info .deposit:before {
  content: "";
  position: absolute;
  z-index: -1;
  border-radius: 2rem;
  display: block;
  pointer-events: none;
}

header .middle .right .user-info .deposit:before {
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(transparent),
    to(rgba(0, 0, 0, 0.4))
  );
  background: linear-gradient(to top, transparent, rgba(0, 0, 0, 0.4));
  top: 0;
  height: 100%;
  width: 100%;
  right: 0;
  margin: auto;
}

header .middle .right .user-info .deposit:after {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 0.125rem;
  width: 80%;
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease;
  height: 0.5rem;
  background: #00ec66;
  -webkit-filter: blur(0.625rem);
  filter: blur(0.625rem);
}

header .middle .right .user-info .deposit:hover {
  -webkit-filter: brightness(1.1);
  filter: brightness(1.1);
}

header .middle .right .user-info .deposit:hover:after {
  height: 0.9375rem;
}

header .btn {
  position: relative;
  min-width: 7.3333rem;
  height: 2.6667rem;
  border-radius: 2rem;
  color: var(--secondary-text-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 1.8667rem;
  font-weight: bold;
  cursor: pointer;
  overflow: hidden;
}

/* =============================== wallet wrapper ============================== */

header .middle .right .user-info .wallets-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  margin: 0 auto;
}

header .middle .right .user-info .wallets-wrapper > * {
  background: linear-gradient(101deg, #1a1e2b, #0c121e2b 52%, #171c28);
  margin-left: 1.25rem;
  height: 3.75rem;
  border: var(--surface-border);
}

header .middle .right .user-info .wallets-wrapper > :after,
header .middle .right .user-info .wallets-wrapper > :before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  background-image: radial-gradient(#ffffff00, transparent, transparent);
  z-index: -1;
  pointer-events: none;
}

header .middle .right .user-info .wallets-wrapper > :before {
  top: 0;
  height: 20rem;
  background-repeat: no-repeat;
  background-position: 98% 130%;
  background-size: 200% 200%;
}

header .middle .right .user-info .wallets-wrapper > :after {
  height: 6rem;
  background-repeat: no-repeat;
  background-position: 55% 2%;
  background-size: 194% 305%;
  bottom: 0;
}

header .middle .right .user-info .wallets-wrapper .balance {
  position: relative;
  border-radius: 1.875rem;
  margin-right: auto;
  margin-left: auto;
  color: var(--secondary-text-color);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

header .middle .right .user-info .wallets-wrapper .balance a img {
  margin-right: 15px;
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .balance:hover
  .wallets-list-wrapper {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  pointer-events: all;
  visibility: visible;
}

header .middle .right .user-info .wallets-wrapper .balance .balance-helper {
  height: 100%;
  /* width: 15rem; */
  padding: 0 1.8rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .balance
  .balance-helper
  .refresh-balance
  svg {
  vertical-align: middle;
  margin-right: 1rem;
  cursor: pointer;
}

header .middle .right .user-info .wallets-wrapper .balance .amount {
  /* margin-right: 0.65rem; */
}

header .middle .right .user-info .wallets-wrapper .balance .amount.blur {
  -webkit-filter: blur(4px);
  filter: blur(4px);
}

header .middle .right .user-info .wallets-wrapper .balance .amount.blur:hover {
  -webkit-filter: blur(0) !important;
  filter: blur(0) !important;
}

header .middle .right .user-info .wallets-wrapper .balance .currency-str {
  margin-right: 0.625rem;
}

header .middle .right .user-info .wallets-wrapper .balance img {
  /* width: 1.375rem; */
  /* max-height: 1.375rem; */
  height: 50px !important;
  width: 50px !important;
  margin-right: 0.625rem;
}

header .middle .right .user-info .wallets-wrapper .balance img.currency-icon {
  margin-left: auto;
}

header .middle .right .user-info .wallets-wrapper .balance .arrow {
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease;
  width: 0.875rem;
  margin-right: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

header .middle .right .user-info .wallets-wrapper .balance.active .arrow {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .balance
  .refresh-balance.rotate {
  pointer-events: none;
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .balance
  .refresh-balance.rotate
  svg {
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  -webkit-transform: rotateZ(360deg);
  transform: rotateZ(360deg);
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .balance
  .wallets-list-wrapper {
  position: absolute;
  width: 18rem;
  top: 4.4333rem;
  left: 0;
  border-radius: 0.625rem;
  border: var(--surface-border);
  z-index: 99;
  background: linear-gradient(101deg, #1a1e2b, #0c121ee6 52%, #171c28);
  padding: 1.875rem 1.75rem;
  -webkit-transform: translateY(1.25rem);
  transform: translateY(1.25rem);
  pointer-events: none;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  opacity: 0;
  visibility: hidden;
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .balance
  .wallets-list-wrapper:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 2.275rem;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .balance
  .wallets-list-wrapper
  .custom-scrollbar {
  width: 4px !important;
  visibility: unset !important;
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .balance
  .wallets-list-wrapper
  ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-left: 0;
  width: 100%;
  overflow: auto;
  max-height: 367px;
  padding-right: 0.125rem;
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .balance
  .wallets-list-wrapper
  ul
  li {
  position: relative;
  margin-left: 0 !important;
  padding: 0.3rem;
  font-size: 0.8125rem;
  height: 2.125rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .balance
  .wallets-list-wrapper
  ul
  li.active,
header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .balance
  .wallets-list-wrapper
  ul
  li:hover {
  background: #1a1f2b;
  border-radius: 10px;
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .balance
  .wallets-list-wrapper
  ul
  li
  .currency-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: auto;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .balance
  .wallets-list-wrapper
  ul
  li
  .currency-wrapper
  .helper {
  width: 5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .balance
  .wallets-list-wrapper
  ul
  li
  + li {
  margin-top: 0.5rem;
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .balance
  .wallets-list-wrapper
  ul
  li
  a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 1.5625rem;
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .balance
  .wallets-list-wrapper
  ul
  li
  a:hover
  span {
  color: var(--white);
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .balance
  .wallets-list-wrapper
  ul
  li
  a
  .icon {
  position: relative;
  height: 1.5rem;
  width: 1.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 1.0625rem;
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .balance
  .wallets-list-wrapper
  ul
  li
  a
  .icon:after {
  content: "";
  position: absolute;
  left: -0.3125rem;
  top: 0;
  background: #f7904e;
  width: 1.375rem;
  height: 1.375rem;
  opacity: 0.45;
  border-radius: 1.875rem;
  -webkit-filter: blur(0.25rem);
  filter: blur(0.25rem);
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .balance
  .wallets-list-wrapper
  ul
  li
  span {
  color: var(--secondary-text-color);
  font-size: 0.875rem;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .balance
  .wallets-list-wrapper
  ul
  .btn {
  overflow: unset;
  position: relative;
  z-index: 1;
  color: #fff;
  background: #6c56f9;
  -webkit-box-shadow: 0 2px 5px rgba(38, 51, 77, 0.03);
  box-shadow: 0 2px 5px rgba(38, 51, 77, 0.03);
  background-position: center;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  width: calc(100% - 3.75rem);
  margin: auto;
  -webkit-filter: brightness(1.1);
  filter: brightness(1.1);
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .balance
  .wallets-list-wrapper
  ul
  .btn:after,
header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .balance
  .wallets-list-wrapper
  ul
  .btn:before {
  content: "";
  position: absolute;
  z-index: -1;
  border-radius: 2rem;
  display: block;
  pointer-events: none;
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .balance
  .wallets-list-wrapper
  ul
  .btn:before {
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(transparent),
    to(rgba(0, 0, 0, 0.4))
  );
  background: linear-gradient(to top, transparent, rgba(0, 0, 0, 0.4));
  top: 0;
  height: 100%;
  width: 100%;
  right: 0;
  margin: auto;
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .balance
  .wallets-list-wrapper
  ul
  .btn:after {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 0.125rem;
  width: 80%;
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease;
  height: 0.5rem;
  background: #6c56f9;
  -webkit-filter: blur(0.625rem);
  filter: blur(0.625rem);
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .balance
  .wallets-list-wrapper
  ul
  .btn:hover {
  -webkit-filter: brightness(1.1);
  filter: brightness(1.1);
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .balance
  .wallets-list-wrapper
  ul
  .btn:hover:after {
  height: 0.9375rem;
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .balance
  .wallets-list-wrapper
  ul
  .btn:after {
  height: 15px;
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .balance
  .wallets-list-wrapper
  .options {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 2rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .balance
  .wallets-list-wrapper
  .options
  .item {
  position: relative;
  color: #97a1c2;
  font-size: 0.8125rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  cursor: pointer;
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .balance
  .wallets-list-wrapper
  .options
  .item
  + .item {
  margin-top: 1.25rem;
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .balance
  .wallets-list-wrapper
  .options
  .item
  input[type="checkbox"] {
  position: absolute;
  opacity: 0;
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .balance
  .wallets-list-wrapper
  .options
  .item
  input:checked
  + .checkmark:before {
  -webkit-transform: translateY(-50%) translateX(1.6rem);
  transform: translateY(-50%) translateX(1.6rem);
  background: #00ec66;
  -webkit-box-shadow: 0 3px 6px rgba(0, 236, 102, 0.2);
  box-shadow: 0 3px 6px rgba(0, 236, 102, 0.2);
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .balance
  .wallets-list-wrapper
  .options
  .item
  .checkmark {
  position: relative;
  background: #1a1f2b;
  -webkit-box-shadow: inset 0 0.0625rem 0.0625rem rgba(70, 75, 88, 0.32);
  box-shadow: inset 0 0.0625rem 0.0625rem rgba(70, 75, 88, 0.32);
  height: 0.625rem;
  width: 2.5rem;
  border-radius: 2.5rem;
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .balance
  .wallets-list-wrapper
  .options
  .item
  .checkmark:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  display: block;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 1rem;
  width: 1rem;
  background: #97a1c2;
  border-radius: 0.25rem;
  -webkit-box-shadow: 0 3px 6px rgba(151, 161, 194, 0.32);
  box-shadow: 0 3px 6px rgba(151, 161, 194, 0.32);
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease;
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .balance
  .wallets-list-wrapper
  .currency-radio-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: calc(100% + 1.25rem);
  position: relative;
  left: -0.625rem;
  min-height: 3.125rem;
  background-color: #1a1f2b;
  border-radius: 1.875rem;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  margin-top: 0;
  margin-bottom: 1.25rem;
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .balance
  .wallets-list-wrapper
  .currency-radio-wrapper
  .radio-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  cursor: pointer;
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .balance
  .wallets-list-wrapper
  .currency-radio-wrapper
  .radio_item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  font-size: 0.75rem;
  cursor: pointer;
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .balance
  .wallets-list-wrapper
  .currency-radio-wrapper
  .radio_item
  .check-curr {
  visibility: hidden;
  position: absolute;
  z-index: 111;
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .balance
  .wallets-list-wrapper
  .currency-radio-wrapper
  .radio_item
  .custom-radio {
  height: 1.2rem;
  width: 1.2rem;
  border-radius: 50%;
  border: solid 0.125rem #687594;
  position: relative;
  margin-right: 0.3125rem;
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .balance
  .wallets-list-wrapper
  .currency-radio-wrapper
  .radio_item
  .check-curr:checked
  ~ .custom-radio::after {
  content: "";
  position: absolute;
  width: 55%;
  height: 55%;
  background-color: #ccd6f6;
  border-radius: 50%;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

header .middle .right .user-info .wallets-wrapper .balance .custom-scrollbar {
  background: #242424b5;
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .balance
  .custom-scrollbar
  .custom-scrollbar-btn {
  width: 4px !important;
  background-color: rgba(255, 255, 255, 0.9) !important;
}

header .middle .right .user-info .wallets-wrapper .deposit-alert {
  display: none;
}

header .middle .right .user-info .wallets-wrapper .show-deposit-alert {
  position: relative;
  border: 1px solid #faff00;
  -webkit-box-shadow: inset 0 0 12px rgba(250, 255, 0, 0.7);
  box-shadow: inset 0 0 12px rgba(250, 255, 0, 0.7);
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .show-deposit-alert
  .deposit-alert {
  position: absolute;
  background: #252a36;
  color: #00ec66;
  font-size: 0.6875rem;
  padding: 0.5rem 0.8125rem;
  border-radius: 0.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.625rem;
  left: 0.75rem;
  margin: 0.3125rem auto;
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .show-deposit-alert
  .deposit-alert
  span {
  white-space: nowrap;
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .show-deposit-alert
  .deposit-alert
  svg {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  cursor: pointer;
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .show-deposit-alert
  .deposit-alert:hover
  + .wallets-list-wrapper {
  opacity: 0;
  visibility: hidden;
}

header .middle .right .user-info .wallets-wrapper .wallet {
  position: relative;
  margin-right: auto;
  padding: 0 1.5625rem;
  font-size: 0.8125rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

header .middle .right .user-info .wallets-wrapper .wallet .icon {
  position: relative;
  height: 1.5rem;
  width: 1.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 1.0625rem;
}

header .middle .right .user-info .wallets-wrapper .wallet .icon:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: #f7904e;
  width: 1.375rem;
  height: 1.375rem;
  opacity: 0.45;
  border-radius: 1.875rem;
  -webkit-filter: blur(0.25rem);
  filter: blur(0.25rem);
}

header .middle .right .user-info .wallets-wrapper .circle {
  position: relative;
  width: 3.75rem;
  min-width: unset;
  padding: 0;
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .circle.notifications-wrapper {
  overflow: visible;
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .circle.notifications-wrapper:hover
  .notification-data {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

header .middle .right .user-info .wallets-wrapper .circle .notification-data {
  display: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: absolute;
  top: 4.0875rem;
  z-index: 99;
  border-radius: 5px;
  right: -6.25rem;
  width: 15.625rem;
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .circle
  .notification-data:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  width: 100%;
  height: 24px;
  background: 0 0;
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .circle
  .notification-data
  ul {
  border-radius: 5px 5px 0 0;
  overflow: auto;
  background-color: #161c28;
  max-height: 478px;
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .circle
  .notification-data
  ul
  li {
  position: relative;
  width: 100%;
  height: auto;
  padding: 15px 25px;
  -webkit-transition: all ease 0.3s;
  transition: all ease 0.3s;
  border-bottom: 1px solid rgba(72, 79, 94, 0.5);
  background: #161c28;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .circle
  .notification-data
  ul
  li:hover {
  background: #232c3f;
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .circle
  .notification-data
  ul
  li.read {
  background-color: #232c3f;
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .circle
  .notification-data
  ul
  li.read
  h5 {
  color: var(--secondary-text-color);
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .circle
  .notification-data
  ul
  li:not(.read)
  h5::before {
  content: "";
  position: absolute;
  left: 11px;
  top: 19px;
  height: 6.8px;
  width: 6.8px;
  background: #e62b3a;
  border-radius: 50%;
  display: block;
  margin-right: 10px;
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .circle
  .notification-data
  ul
  li
  h5 {
  margin-bottom: 10px;
  color: var(--secondary-text-color);
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .circle
  .notification-data
  ul
  li
  div,
header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .circle
  .notification-data
  ul
  li
  h5,
header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .circle
  .notification-data
  ul
  li
  > span {
  line-height: normal;
  color: var(--secondary-text-color);
  font-size: 14px;
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .circle
  .notification-data
  ul
  li
  > div {
  color: var(--secondary-text-color);
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .circle
  .notification-data
  ul
  li
  p {
  margin: 10px 0 16px;
  line-height: 20.4px;
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .circle
  .notification-data
  ul
  li
  > span {
  display: block;
  color: #8f98a8;
  font-size: 12px;
}

header
  .middle
  .right
  .user-info
  .wallets-wrapper
  .circle
  .notification-data
  ul
  li
  a {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

header .middle .right .user-info .wallets-wrapper .circle .notification-data a {
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #6c56f9;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  color: #fff;
  -webkit-filter: brightness(1.1);
  filter: brightness(1.1);
}

header .middle .right .user-info .wallets-wrapper .circle .count {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  height: 1.2rem;
  width: 1.2rem;
  font-size: 0.7rem;
  background: red;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

header .middle .right .user-info .wallets-wrapper .circle .count:not(.visible) {
  display: none;
}

/* ================================= ==================================== */

header .middle .right .user-info li {
  position: relative;
}

header .middle .right .user-info li .my-account {
  background: linear-gradient(101deg, #1a1e2b, #0c121ec4 52%, #171c28);
  margin-left: 1.25rem;
  height: 3.75rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--secondary-text-color);
  border: var(--surface-border);
}

header .middle .right .user-info li .my-account:hover + .account-menu {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  pointer-events: all;
}

header .middle .right .user-info li .my-account .icon {
  position: relative;
  margin: 0 0.625rem;
}

header .middle .right .user-info li .my-account .icon:after {
  content: "";
  position: absolute;
  right: -0.0625rem;
  top: 0;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(0),
    color-stop(#8070e5),
    to(#403873)
  );
  background: linear-gradient(0, #8070e5, #403873);
  width: 1.375rem;
  height: 1.375rem;
  opacity: 0.45;
  border-radius: 1.875rem;
  -webkit-filter: blur(0.25rem);
  filter: blur(0.25rem);
}

header .middle .right .user-info li .my-account .arrow {
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease;
  width: 0.875rem;
}

header .middle .right .user-info li .my-account:after,
header .middle .right .user-info li .my-account:before {
  /* content: ""; */
  position: absolute;
  left: 0;
  width: 100%;
  background-image: radial-gradient(#ffffff00, transparent, transparent);
  z-index: -1;
  pointer-events: none;
}

header .middle .right .user-info li .my-account:before {
  top: 0;
  height: 20rem;
  background-repeat: no-repeat;
  background-position: 98% 130%;
  background-size: 200% 200%;
}

header .middle .right .user-info li .my-account:after {
  height: 6rem;
  background-repeat: no-repeat;
  background-position: 55% 2%;
  background-size: 194% 305%;
  bottom: 0;
}

/* ================================ account menu ======================================== */

.account-menu {
  position: absolute;
  right: 0;
  top: 3.5rem;
  width: 15.9375rem;
  border-radius: 0.625rem;
  z-index: 99;
  opacity: 0;
  -webkit-transform: translateY(1.25rem);
  transform: translateY(1.25rem);
  pointer-events: none;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}

.account-menu:hover {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  pointer-events: all;
}

.account-menu .account-menu-helper {
  top: 1rem;
  border-radius: 0.625rem;
  border: var(--surface-border);
  z-index: 99;
  background: linear-gradient(101deg, #1a1e2b, #0c121ee6 52%, #171c28);
  padding-bottom: 1.5rem;
}

.account-menu .account-menu-helper ul {
  padding: 1.9375rem 1.75rem;
}

.account-menu ul li:first-child img {
  -webkit-filter: brightness(0) invert(26%) sepia(91%) saturate(1426%)
    hue-rotate(227deg) brightness(104%) contrast(92%);
  filter: brightness(0) invert(26%) sepia(91%) saturate(1426%)
    hue-rotate(227deg) brightness(104%) contrast(92%);
}

.account-menu ul li + li {
  margin-top: 0.9375rem;
}

.account-menu ul li a,
.account-menu ul li span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 1.5625rem;
  cursor: pointer;
}

.account-menu ul li a.active img {
  -webkit-filter: invert(74%) sepia(40%) saturate(3342%) hue-rotate(91deg)
    brightness(98%) contrast(103%);
  filter: invert(74%) sepia(40%) saturate(3342%) hue-rotate(91deg)
    brightness(98%) contrast(103%);
}

.account-menu ul li a.active span {
  color: #fff;
}

.account-menu ul li a:hover span {
  color: var(--white);
}

.account-menu ul li a .icon,
.account-menu ul li span .icon {
  position: relative;
  height: 1.5rem;
  width: 1.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 0.6rem;
}

.account-menu ul li span {
  color: var(--secondary-text-color);
  font-size: 0.875rem;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  font-weight: 400;
}

.account-menu .btn,
.account-menu .btn:hover {
  overflow: unset;
  position: relative;
  z-index: 1;
  color: #fff;
  background: #6c56f9 !important;
  -webkit-box-shadow: 0 2px 5px rgba(38, 51, 77, 0.03);
  box-shadow: 0 2px 5px rgba(38, 51, 77, 0.03);
  background-position: center;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  width: calc(100% - 3.75rem);
  margin: auto;
  -webkit-filter: brightness(1.1);
  filter: brightness(1.1);
}

.account-menu .btn:after,
.account-menu .btn:before {
  content: "";
  position: absolute;
  z-index: -1;
  border-radius: 2rem;
  display: block;
  pointer-events: none;
}

.account-menu .btn:before {
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(transparent),
    to(rgba(0, 0, 0, 0.4))
  );
  background: linear-gradient(to top, transparent, rgba(0, 0, 0, 0.4));
  top: 0;
  height: 100%;
  width: 100%;
  right: 0;
  margin: auto;
}

.account-menu .btn:after {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 0.125rem;
  width: 80%;
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease;
  height: 0.5rem;
  background: #6c56f9;
  -webkit-filter: blur(0.625rem);
  filter: blur(0.625rem);
}

.account-menu .btn:hover {
  -webkit-filter: brightness(1.1);
  filter: brightness(1.1);
}

.account-menu .btn:hover:after {
  height: 0.9375rem;
}

.account-menu .btn:after {
  height: 15px;
}

/* ==================================== search open content ====================================== */

.big-search-wrapper {
  position: fixed;
  top: 0;
  padding-top: var(--headerHeight);
  width: 100%;
  background: rgba(12, 18, 30, 0.88);
  z-index: 999;
  height: 100%;
  pointer-events: none;
  opacity: 0;
  -webkit-transition: 0.4s ease;
  transition: 0.4s ease;
}

.big-search-wrapper.visible {
  pointer-events: all;
  opacity: 1;
}

.big-search-wrapper.visible .middle {
  padding-top: 0;
}

.big-search-wrapper .middle {
  padding-top: 3.125rem;
}

.big-search-wrapper .categories {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0.625rem;
}

.big-search-wrapper .categories li {
  flex-shrink: 0;
  max-width: 8rem;
  width: 100%;
  height: 3.75rem;
  border-radius: 0.9375rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.875rem;
  padding: 0 1.0625rem;
  cursor: pointer;
  position: relative;
  z-index: 2;
}

.categories::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.categories::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.categories::-webkit-scrollbar-thumb {
  background: #f7904e;
  border-radius: 10px;
}

/* Handle on hover */
.categories::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}

.big-search-wrapper .categories li:before {
  background: radial-gradient(#ffffff17, transparent, transparent) !important;
}

.big-search-wrapper .categories li span {
  z-index: 2;
}

.big-search-wrapper .categories li.active span:before {
  content: "";
  position: absolute;
  left: 50%;
  border-radius: 0.25rem;
  top: 0;
  width: 75%;
  height: 0.25rem;
  background: var(--orange-primary);
  z-index: -1;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  -webkit-animation: 0.8s ease glow;
  animation: 0.8s ease glow;
  pointer-events: none;
}

.big-search-wrapper .categories li.active:after {
  content: "";
  position: absolute;
  left: 50%;
  border-radius: 0.625rem;
  top: 0;
  width: 100%;
  height: 289%;
  background: radial-gradient(
    var(--orange-primary) -20%,
    #f45a414d,
    transparent 56%,
    transparent
  );
  background-repeat: no-repeat;
  background-size: 226%;
  background-position: center -5.6875rem;
  z-index: 2;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  opacity: 1;
  -webkit-animation: 0.3s ease glow;
  animation: 0.3s ease glow;
  pointer-events: none;
}

@-webkit-keyframes glow {
  from {
    background-position: center -10.6875rem;
    opacity: 0;
  }
}

@keyframes glow {
  from {
    background-position: center -10.6875rem;
    opacity: 0;
  }
}

.big-search-wrapper .categories li.all {
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 5.625rem;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.big-search-wrapper .categories li.disabled {
  pointer-events: none;
}

.big-search-wrapper .categories li.disabled img {
  opacity: 0.2;
}

.big-search-wrapper .categories li.disabled span {
  color: rgba(255, 255, 255, 0.2);
}

.big-search-wrapper .categories li img {
  width: 1.875rem;
  margin-right: 0.9375rem;
  z-index: 3;
}

.big-search-wrapper .categories li.sports img {
  width: 2rem;
}

.big-search-wrapper .categories li.virtual img {
  width: 2.5rem;
}

/* .big-search-wrapper .categories li + li {
  margin-left: 1.25rem;
} */
.big-search-wrapper .categories li .count {
  font-size: 0.75rem;
  color: #f7904e;
  position: absolute;
  top: 0.25rem;
  right: 1rem;
  z-index: 3;
  font-weight: 400;
}

.big-search-wrapper .search-content.hide-vendors .games-container {
  grid-column: span 3;
}

.big-search-wrapper .search-content.hide-vendors .games-container #games {
  grid-auto-rows: unset;
}

.big-search-wrapper
  .search-content.hide-vendors
  .games-container
  #games
  .item
  p.text {
  font-size: 0.85rem !important;
}

.big-search-wrapper
  .search-content.hide-vendors
  .games-container
  .see-more-wrapper {
  margin-top: 0.9375rem;
}

.big-search-wrapper .search-content.hide-vendors .providers-container {
  display: none !important;
}

.big-search-wrapper .search-content ul {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1.25rem;
  margin-top: 1.25rem;
  /* overflow: auto; */
  max-height: 80vh;
}

.big-search-wrapper .search-content ul .scroll-wrapper {
  height: calc(100% - 2.5rem) !important;
}

.big-search-wrapper .search-content ul .scroll-wrapper .items {
  padding-right: 0.8125rem;
}

.big-search-wrapper .search-content ul .scroll-wrapper .see-more-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.big-search-wrapper
  .search-content
  ul
  .scroll-wrapper
  .see-more-wrapper
  .see-more {
  position: relative;
  overflow: hidden;
  background: #282d39;
  border-radius: 0.9375rem;
  width: 10.5rem;
}

.big-search-wrapper .search-content ul li {
  position: relative;
  padding: 1.875rem;
  padding-right: 1.25rem;
}

.search_history_game {
  display: grid;
  gap: 70px 40px;
  padding: 30px 50px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  justify-items: center;
  /* height: 500px; */
  height: calc(100vh - 400px);
  overflow: auto;
}

.game-not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.search_history_game::-webkit-scrollbar {
  display: none;
}

.search_history_game li {
  padding: unset !important;
  padding-right: unset !important;
  width: 100%;
}

.big-search-wrapper .search-content ul li.games-container {
  grid-column: span 2;
  max-height: 27.4625rem;
  padding-bottom: 0 !important;
}

.big-search-wrapper .search-content ul li.games-container #games {
  width: calc(100% + 18px) !important;
}

.big-search-wrapper .search-content ul li.games-container #games:after {
  content: "";
  width: 100%;
  display: grid;
  height: 1.875rem;
  grid-column: span 7;
  grid-template-rows: 1.875rem;
}

.big-search-wrapper .search-content ul li.games-container.full-width {
  grid-column: span 3;
}

.big-search-wrapper .search-content ul li.games-container.full-width #games {
  grid-auto-rows: unset;
}

.big-search-wrapper
  .search-content
  ul
  li.games-container.full-width
  #games
  .item
  p.text {
  font-size: 0.85rem !important;
}

.big-search-wrapper
  .search-content
  ul
  li.games-container.full-width
  .see-more-wrapper {
  margin-top: 0.9375rem;
}

.big-search-wrapper .search-content ul li.games-container .items {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 0.625rem 1.25rem;
  grid-auto-rows: 7.8125rem;
  overflow: auto;
}

.big-search-wrapper .search-content ul li.games-container .items .item {
  width: 100%;
  height: 100%;
}

.big-search-wrapper .search-content ul li.games-container .items .item .image {
  border-radius: 0.625rem;
  background: rgba(40, 45, 57, 0.9);
  width: 100%;
  height: 0;
  background-size: cover;
  padding-top: 81%;
  cursor: pointer;
}

.big-search-wrapper .search-content ul li.games-container .items .item p {
  font-size: 0.75rem;
  text-align: center;
  margin-top: 0.3125rem;
  color: var(--secondary-text-color);
}

.big-search-wrapper .search-content ul li.providers-container {
  max-height: 27.4625rem;
  grid-column: span 1;
}

.big-search-wrapper
  .search-content
  ul
  li.providers-container.full-width
  .items {
  grid-template-columns: repeat(6, 1fr) !important;
}

.big-search-wrapper .search-content ul li.providers-container .items {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.25rem;
  grid-auto-rows: 4.0625rem;
  overflow: auto;
}

.big-search-wrapper .search-content ul li.providers-container .items .item {
  width: 100%;
  border-radius: 0.625rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-transform: capitalize;
  line-height: 1.1875rem;
}

.big-search-wrapper .search-content ul li.full-width {
  grid-column: span 3;
}

.big-search-wrapper .search-content ul li.esport-container,
.big-search-wrapper .search-content ul li.sport-container {
  height: 21.375rem;
}

.big-search-wrapper .search-content ul li.esport-container #esport-matches,
.big-search-wrapper .search-content ul li.esport-container #sportsbook-matches,
.big-search-wrapper .search-content ul li.sport-container #esport-matches,
.big-search-wrapper .search-content ul li.sport-container #sportsbook-matches {
  color: #e2e8fb;
  overflow: auto;
  height: 100%;
  padding-right: 0.75rem;
}

.big-search-wrapper
  .search-content
  ul
  li.esport-container
  #esport-matches
  a.sport-match,
.big-search-wrapper
  .search-content
  ul
  li.esport-container
  #sportsbook-matches
  a.sport-match,
.big-search-wrapper
  .search-content
  ul
  li.sport-container
  #esport-matches
  a.sport-match,
.big-search-wrapper
  .search-content
  ul
  li.sport-container
  #sportsbook-matches
  a.sport-match {
  height: 4.0625rem;
  font-size: 0.75rem;
  border-top: 1px solid #282d39;
  line-height: 1.125rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.big-search-wrapper
  .search-content
  ul
  li.esport-container
  #esport-matches
  a.sport-match
  .icon-wrapper,
.big-search-wrapper
  .search-content
  ul
  li.esport-container
  #sportsbook-matches
  a.sport-match
  .icon-wrapper,
.big-search-wrapper
  .search-content
  ul
  li.sport-container
  #esport-matches
  a.sport-match
  .icon-wrapper,
.big-search-wrapper
  .search-content
  ul
  li.sport-container
  #sportsbook-matches
  a.sport-match
  .icon-wrapper {
  margin-right: 1.25rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  width: 0.9375rem;
}

.big-search-wrapper
  .search-content
  ul
  li.esport-container
  #esport-matches
  a.sport-match
  .icon-wrapper
  .sport-icon,
.big-search-wrapper
  .search-content
  ul
  li.esport-container
  #sportsbook-matches
  a.sport-match
  .icon-wrapper
  .sport-icon,
.big-search-wrapper
  .search-content
  ul
  li.sport-container
  #esport-matches
  a.sport-match
  .icon-wrapper
  .sport-icon,
.big-search-wrapper
  .search-content
  ul
  li.sport-container
  #sportsbook-matches
  a.sport-match
  .icon-wrapper
  .sport-icon {
  font-size: 1.375rem;
}

.big-search-wrapper
  .search-content
  ul
  li.esport-container
  #esport-matches
  a.sport-match
  .sport-info
  .league,
.big-search-wrapper
  .search-content
  ul
  li.esport-container
  #esport-matches
  a.sport-match
  .sport-info
  .team-name,
.big-search-wrapper
  .search-content
  ul
  li.esport-container
  #sportsbook-matches
  a.sport-match
  .sport-info
  .league,
.big-search-wrapper
  .search-content
  ul
  li.esport-container
  #sportsbook-matches
  a.sport-match
  .sport-info
  .team-name,
.big-search-wrapper
  .search-content
  ul
  li.sport-container
  #esport-matches
  a.sport-match
  .sport-info
  .league,
.big-search-wrapper
  .search-content
  ul
  li.sport-container
  #esport-matches
  a.sport-match
  .sport-info
  .team-name,
.big-search-wrapper
  .search-content
  ul
  li.sport-container
  #sportsbook-matches
  a.sport-match
  .sport-info
  .league,
.big-search-wrapper
  .search-content
  ul
  li.sport-container
  #sportsbook-matches
  a.sport-match
  .sport-info
  .team-name {
  color: #97a1c2;
}

.big-search-wrapper
  .search-content
  ul
  li.esport-container
  #esport-matches
  a.sport-match
  .sport-info
  .time-wrapper,
.big-search-wrapper
  .search-content
  ul
  li.esport-container
  #sportsbook-matches
  a.sport-match
  .sport-info
  .time-wrapper,
.big-search-wrapper
  .search-content
  ul
  li.sport-container
  #esport-matches
  a.sport-match
  .sport-info
  .time-wrapper,
.big-search-wrapper
  .search-content
  ul
  li.sport-container
  #sportsbook-matches
  a.sport-match
  .sport-info
  .time-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.big-search-wrapper
  .search-content
  ul
  li.esport-container
  #esport-matches
  a.sport-match
  .sport-info
  .time-wrapper
  .live,
.big-search-wrapper
  .search-content
  ul
  li.esport-container
  #sportsbook-matches
  a.sport-match
  .sport-info
  .time-wrapper
  .live,
.big-search-wrapper
  .search-content
  ul
  li.sport-container
  #esport-matches
  a.sport-match
  .sport-info
  .time-wrapper
  .live,
.big-search-wrapper
  .search-content
  ul
  li.sport-container
  #sportsbook-matches
  a.sport-match
  .sport-info
  .time-wrapper
  .live {
  margin-right: 0.5rem;
  color: #00ec66;
}

.big-search-wrapper
  .search-content
  ul
  li.esport-container
  #esport-matches
  a.sport-match
  .sport-info
  .time-wrapper
  .time,
.big-search-wrapper
  .search-content
  ul
  li.esport-container
  #sportsbook-matches
  a.sport-match
  .sport-info
  .time-wrapper
  .time,
.big-search-wrapper
  .search-content
  ul
  li.sport-container
  #esport-matches
  a.sport-match
  .sport-info
  .time-wrapper
  .time,
.big-search-wrapper
  .search-content
  ul
  li.sport-container
  #sportsbook-matches
  a.sport-match
  .sport-info
  .time-wrapper
  .time {
  margin-right: 0.625rem;
  color: #ccd6f6;
}

.big-search-wrapper
  .search-content
  ul
  li.esport-container
  #esport-matches
  a.sport-match
  .arrow-icon,
.big-search-wrapper
  .search-content
  ul
  li.esport-container
  #sportsbook-matches
  a.sport-match
  .arrow-icon,
.big-search-wrapper
  .search-content
  ul
  li.sport-container
  #esport-matches
  a.sport-match
  .arrow-icon,
.big-search-wrapper
  .search-content
  ul
  li.sport-container
  #sportsbook-matches
  a.sport-match
  .arrow-icon {
  margin-left: auto;
}

.big-search-wrapper .search-content ul li.history .items .item {
  position: relative;
  height: 3.375rem;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 1.875rem;
  cursor: pointer;
}

.big-search-wrapper .search-content ul li.history .items .item:hover span {
  color: var(--white);
}

.big-search-wrapper .search-content ul li.history .items .item:after,
.big-search-wrapper .search-content ul li.history .items .item:before {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background-size: cover;
  display: block;
}

.big-search-wrapper .search-content ul li.history .items .item:before {
  left: 0;
  background-image: url(../../assets/images/header/search/history.svg);
  height: 1.25rem;
  width: 1.25rem;
}

.big-search-wrapper .search-content ul li.history .items .item .remove {
  margin-left: auto;
  margin-right: 0.625rem;
  height: 0.6875rem;
  -webkit-transition: 0.4s ease;
  transition: 0.4s ease;
}

.big-search-wrapper .search-content ul li.history .items .item .remove:hover {
  -webkit-filter: brightness(2);
  filter: brightness(2);
}

.big-search-wrapper .search-content ul li.history .items .item span {
  font-size: 0.875rem;
  color: var(--secondary-text-color);
  pointer-events: none;
  font-weight: 400;
}

.big-search-wrapper
  .search-content
  ul
  li.history
  .items
  .item:not(:first-child) {
  border-top: 1px solid #282d39;
}

.big-search-wrapper .search-content ul li.banner-container {
  overflow: hidden;
  /* background-image: url(../assets/images/casino/big-banner.png); */
  background-size: cover;
  height: 21.375rem;
  border-radius: 0.9375rem;
}

.big-search-wrapper .search-content ul li.banner-container a {
  height: 100%;
  width: 100%;
  display: block;
}

.big-search-wrapper .search-content ul li.recomended-container {
  display: none !important;
  padding: 1.875rem 0;
}

.big-search-wrapper .search-content ul li.recomended-container .title {
  margin-left: 1.875rem;
}

.big-search-wrapper
  .search-content
  ul
  li.recomended-container
  .swiper-container {
  overflow: hidden;
}

.big-search-wrapper
  .search-content
  ul
  li.recomended-container
  .swiper-container
  .swiper-wrapper
  .swiper-slide {
  border-radius: 0.625rem;
  overflow: hidden;
  height: 11.4375rem;
  cursor: pointer;
}

.big-search-wrapper
  .search-content
  ul
  li.recomended-container
  .swiper-container
  .swiper-wrapper
  .swiper-slide
  img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  vertical-align: middle;
}

/* .big-search-wrapper .search-content ul li:not([data-view="first"]) {
  display: none;
} */
.big-search-wrapper .search-content ul li p.title {
  font-weight: bold;
  font-size: 0.875rem;
  margin-bottom: 1.875rem;
  color: var(--secondary-text-color);
}

.left a.logo {
  font-size: 1.775rem;
  color: var(--secondary-text-color);
}

/* search box data css */

.search-content .bg {
  height: 170px;
  width: 200px;
  position: relative;
  background-size: cover;
  overflow: hidden;
  box-sizing: border-box;
  background-position: center;
  border-radius: 25px;
}

.search-content .bg img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.search-content .slot-hover {
  position: absolute;
  text-align: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(
    to top,
    #f47341 0,
    rgba(244, 115, 65, 0.95) 11.3%,
    rgba(244, 115, 65, 0.92) 31.8%,
    rgba(244, 115, 65, 0.6) 71.5%,
    rgba(244, 115, 65, 0.2) 100%
  );
  transform: translateY(101%);
  z-index: 1;
  transition-duration: 3s, 1s;
}

.search-content .bg:hover .slot-hover {
  transform: translateY(0);

  z-index: 999;
}

.search-content .slot-hover img {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  cursor: pointer;
  width: 50px;
  height: 50px;
}

.search-content .star-wrapper {
  position: absolute;
  bottom: 10px;
  right: 10px;
  height: 2.6rem;
  line-height: 2.6rem;
  padding: 0 1rem;
  color: var(--white);
  -webkit-user-select: none;
  user-select: none;
}

.search-content li h6 {
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.search-content ul::-webkit-scrollbar {
  width: 2px;
  background-color: transparent;
}

.search-content ul::-webkit-scrollbar-track {
  background-color: transparent;
}
.tab-logo .logo img {
  /* width: 100%; */
  height: 4rem;
}
/* ========================================================================= */
/* ================================ media query ============================ */
/* ========================================================================= */
@media screen and (max-width: 991.98px) {
  /* =============================== responsive search ================================== */
  .big-search-wrapper {
    position: fixed;
    top: 0;
    padding: 0.8125rem 0.3125rem;
    width: 100%;
    background: #0c121e;
    z-index: 99999;
    height: 100%;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: 0.2s ease-out;
    transition: 0.2s ease-out;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }

  .big-search-wrapper.visible {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    visibility: visible;
  }

  .big-search-wrapper .search-bar {
    height: 2.5rem;
    display: flex;
    align-items: center;
    position: inherit;
    width: 96%;
  }

  .big-search-wrapper .middle {
    padding-top: 3.125rem !important;
  }

  .big-search-wrapper .search-bar .box.back {
    background: #0c121e;
    border: none;
    margin-left: 0;
  }

  .big-search-wrapper .search-bar .box {
    background: #282d39;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 0.3125rem;
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }

  .big-search-wrapper .search-bar input {
    background: #282d39;
    border-radius: 1.25rem;
    width: 100%;
    border: none;
    color: #fff;
    height: 100%;
    font-size: 0.8125rem;
    padding-left: 1.25rem;
  }

  .big-search-wrapper .search-bar input::placeholder {
    color: #97a1c2;
  }

  .big-search-wrapper .search-bar .box {
    background: #282d39;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 0.3125rem;
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }

  .big-search-wrapper .search-bar .box.voice img {
    -webkit-filter: grayscale(1) brightness(1);
    filter: grayscale(1) brightness(1);
    width: 1.5625rem;
  }

  .big-search-wrapper .search-bar .box.voice:after {
    content: "";
    height: 0.125rem;
    width: 0;
    background: #a1a1a1;
    display: block;
    position: absolute;
    left: 50%;
    top: 46%;
    -webkit-transform: translate(-50%, -50%) rotate(-45deg);
    transform: translate(-50%, -50%) rotate(-45deg);
    z-index: 99;
    -webkit-transition: 0.4s ease;
    transition: 0.4s ease;
  }

  .big-search-wrapper .categories {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 0.625rem;
    margin-bottom: 1.25rem;
  }

  .big-search-wrapper .categories li {
    height: 2.1875rem;
    border-radius: 0.625rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 0.75rem;
    padding: 0 1.0625rem;
    cursor: pointer;
    position: relative;
    z-index: 2;
    background: #1a1f2b;
    border: 1px solid #3e4453;
    margin-top: 0.625rem;
    color: #97a1c2;
    font-weight: bold;
    width: calc(100% / 4 - 0.4rem);
    text-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    z-index: 1;
    overflow: visible;
  }

  .big-search-wrapper .categories li.active:before {
    content: "";
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-100%);
    transform: translateX(-50%) translateY(-100%);
    top: 0;
    width: 4.075rem;
    height: 0.15rem;
    background: #f45a41;
    border-radius: 0.3125rem;
    z-index: -1;
  }

  .big-search-wrapper .categories li.active:after {
    content: "";
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    top: 0;
    width: 4.475rem;
    height: 0.775rem;
    background: #f45a41;
    -webkit-filter: blur(0.6125rem);
    filter: blur(0.6125rem);
    border-radius: 50%;
    z-index: -1;
  }

  .big-search-wrapper .categories li.disabled {
    pointer-events: none;
  }

  /* .big-search-wrapper .categories li + li {
    margin-left: 0.4375rem;
  } */
  .big-search-wrapper .categories li .count {
    font-size: 0.5875rem;
    color: #f7904e;
    position: absolute;
    top: 0.3125rem;
    right: 0.5rem;
    z-index: 3;
  }

  .big-search-wrapper .search-content {
    height: 45.2rem;
    padding-bottom: 1rem;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .big-search-wrapper .search-content ul {
    display: grid;
    grid-gap: 1.25rem;
  }

  .big-search-wrapper .search-content ul li {
    padding: 1.1125rem 0.9125rem;
    position: relative;
  }

  .big-search-wrapper .search-content ul li p.title {
    font-weight: bold;
    font-size: 0.8125rem;
    margin-bottom: 0.8125rem;
    color: #ccd6f6;
  }

  /* ============================== account responsive =============================== */
  .overlay.account-wrapper.visible {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    background: rgba(38, 42, 58, 0.5);
    z-index: 99;
  }

  .overlay.account-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    top: 3.6125rem;
    height: calc(100% - 3.6125rem);
    visibility: hidden;
    background: rgba(38, 42, 58, 0);
    opacity: 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    -webkit-transition: 0.3s ease;
    transition: 0.3s ease;
  }

  .overlay {
    width: 100%;
    height: 100%;
    background-color: var(--overlayColor);
    z-index: 999999;
  }

  .overlay.account-wrapper .user-info {
    position: fixed;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 98%;
    border-radius: 0.625rem;
    overflow: auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: #262a3a;
    padding: 1.9375rem 1.25rem 2.1875rem;
    -webkit-box-shadow: 0 0.0625rem 0.9375rem #00000047;
    box-shadow: 0 0.0625rem 0.9375rem #00000047;
  }

  .overlay.account-wrapper .user-info .content ul li a,
  .overlay.account-wrapper .user-info .content ul li span {
    border-radius: 1.875rem;
    padding: 0.525rem 0.625rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .overlay.account-wrapper .user-info .content ul li a.active {
    background-color: #1a1f2b;
  }

  .overlay.account-wrapper .user-info .content ul li a.active img {
    -webkit-filter: invert(74%) sepia(40%) saturate(3342%) hue-rotate(91deg)
      brightness(98%) contrast(103%);
    filter: invert(74%) sepia(40%) saturate(3342%) hue-rotate(91deg)
      brightness(98%) contrast(103%);
  }

  .overlay.account-wrapper .user-info .content ul li:first-child img {
    -webkit-filter: brightness(0) invert(26%) sepia(91%) saturate(1426%)
      hue-rotate(227deg) brightness(104%) contrast(92%);
    filter: brightness(0) invert(26%) sepia(91%) saturate(1426%)
      hue-rotate(227deg) brightness(104%) contrast(92%);
  }

  .overlay.account-wrapper .user-info .content ul li a img,
  .overlay.account-wrapper .user-info .content ul li span img {
    margin-right: 0.9375rem;
    max-width: 24px;
  }

  .overlay.account-wrapper .user-info .content ul li a p,
  .overlay.account-wrapper .user-info .content ul li span p {
    font-weight: 400;
    margin: 0;
    color: #ccd6f6;
    font-size: 15px;
  }

  .overlay.account-wrapper .user-info .content button.btn {
    width: 100%;
    background: linear-gradient(to bottom, #6d57fb, #7765ef, #8070e5);
    height: 3.125rem;
    border: none;
    border-radius: 1.875rem;
    font-size: 1rem;
    margin-top: 1.25rem;
    position: relative;
  }

  .overlay.account-wrapper .user-info .content button.btn:after {
    content: "";
    position: absolute;
    width: 85%;
    height: 5px;
    background: #8070e5;
    bottom: 0;
    -webkit-box-shadow: 0 0 15px 4px #8070e5;
    box-shadow: 0 0 15px 4px #8070e5;
    border-radius: 10px;
    z-index: -1;
  }

  .search-bar,
  .changeLang {
    display: none;
  }

  header .header-content .left {
    margin: inherit;
  }

  .headerButton {
    gap: 0.9375rem;
  }

  .tab-logo {
    display: block;
  }

  header .btn {
    position: relative;
    /* height: 2.125rem; */
    min-width: inherit;
    border: 0;
    outline: 0;
    text-align: center;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    /* font-size: 0.6875rem; */
    /* padding: 0 0.9375rem; */
    border-radius: 2rem;
    font-weight: 400;
  }

  .graybtn.btn {
    background: #262a3a !important;
    border: 1px solid #464f5e !important;
    color: #ccd6f6 !important;
  }

  /* header .middle .right .user-info.visible {
    display: none;
  } */
  .headerButton .search-icon {
    background: #2d323f;
    border-radius: 50%;
    border: none !important;
    width: 2.025rem;
    height: 2.025rem;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none !important;
  }

  .headerButton .search-icon img {
    height: 1.0625rem;
  }

  /* ================================== tab header authorized ============================== */
  header .header-content {
    width: 100%;
    justify-content: space-between;
  }

  #authorized > ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 0.4375rem;
  }

  #authorized > ul .balance-wrapper {
    display: flex;
    background-color: #252a3a;
    border-radius: 2.25rem;
    padding: 0 0.625rem;
    min-height: 3.025rem;
    align-items: center;
    /* max-width: 13.4rem; */
    gap: 0.3125rem;
  }

  #authorized > ul li {
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }

  #authorized > ul .balance-wrapper .top-up {
    width: 2.4625rem;
    height: 2.4625rem;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(../../assets/images/header/plus.svg);
  }

  #authorized > ul .balance-wrapper .user_amount {
    font-size: 0.9875rem;
    margin-right: 0.4625rem;
    color: #ccd6f6;
    font-weight: 400;
  }

  #authorized > ul .balance-wrapper .currency-icon {
    width: 0.9875rem;
    height: 2rem;
    margin-left: 0.4625rem;
  }

  #authorized > ul .balance-wrapper .currency-name {
    font-size: 0.9875rem;

    font-weight: 600;
    color: #ccd6f6;
  }

  #authorized > ul li.box {
    background: #2d323f;
    border-radius: 50%;
    border: none !important;
    width: 3.025rem;
    height: 3.025rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #authorized > ul .balance-wrapper .arrow {
    margin-left: 0.6875rem;
    width: 0.6875rem;
    -webkit-transition: ease-in-out 0.3s;
    transition: ease-in-out 0.3s;
  }

  #authorized > ul li.box.account-wrapper img {
    -webkit-filter: invert(74%) sepia(40%) saturate(3342%) hue-rotate(91deg)
      brightness(98%) contrast(103%);
    filter: invert(74%) sepia(40%) saturate(3342%) hue-rotate(91deg)
      brightness(98%) contrast(103%);
  }

  #authorized > ul li img {
    height: 1.4625rem;
  }

  .overlay.account-wrapper {
    top: 4.6125rem;
  }

  .header-logo img {
    height: 2rem !important;
  }

  .header-logo--desktop {
    display: none;
  }

  .header-logo--mobile {
    display: block;
  }
}

/* ========================================================================= */
/* ========================================================================= */
/* ========================================================================= */

@media screen and (min-width: 991.98px) {
  header .middle .right .user-info .wallets-wrapper .balance .dollar-coin-icon {
    height: 50px !important;
    width: 50px !important;
    margin-right: 0;
  }
}

@media screen and (max-width: 500px) {
  header .middle .right .user-info .wallets-wrapper .balance .balance-helper {
    padding: 0 5px !important;
  }

  header .middle .right .user-info .wallets-wrapper .balance img {
    margin-right: 0.1rem !important;
  }

  header .middle .right .user-info li .my-account {
    padding: 0 14px !important;
  }

  header .middle .right .user-info li .my-account .icon {
    margin: 0 0.2rem !important;
  }

  header .middle .right .user-info li .my-account .icon img {
    height: 23px;
    width: 23px;
  }

  header .middle .right .user-info li .my-account .username {
    width: 35px;
  }
}

@media screen and (max-width: 767.98px) {
  header .middle .right .user-info .wallets-wrapper .balance .balance-helper {
    padding: 0 13px;
  }

  header .middle .right .user-info li .my-account .icon {
    margin: 0 0.3rem !important;
  }

  header .middle .right .user-info li .my-account .arrow {
    width: 0.375rem;
  }

  header
    .middle
    .right
    .user-info
    .wallets-wrapper
    .balance
    .balance-helper
    span {
    font-size: 16px;
  }

  header .middle .right .user-info .wallets-wrapper > * {
    height: 2.3rem;
  }

  header .middle .right .user-info .wallets-wrapper .balance .dollar-coin-icon {
    height: 40px !important;
    width: 40px !important;
  }

  header .middle .right .user-info li .my-account {
    height: 2.3rem;
    margin-left: 0.5rem;
    /* padding: 0 1rem; */
    padding: 0px 15px;
  }

  .search-content .bg {
    height: 200px !important;
    width: 200px !important;
  }

  .search-content li h6 {
    font-size: 16px !important;
    max-width: 200px !important;
  }

  #authorized > ul .balance-wrapper {
    min-height: 2.025rem;
    max-width: 13.4rem;
  }

  #authorized > ul .balance-wrapper .top-up {
    width: 1.4625rem;
    height: 1.4625rem;
  }

  #authorized > ul .balance-wrapper .user_amount {
    font-size: 0.6875rem;
  }

  #authorized > ul .balance-wrapper .currency-icon {
    width: 0.7875rem;
  }

  #authorized > ul .balance-wrapper .currency-name {
    font-size: 0.6875rem;
    font-weight: 700;
  }

  #authorized > ul .balance-wrapper .arrow {
    margin-left: 0.2875rem;
    width: 0.6875rem;
  }

  #authorized > ul li.box {
    width: 2.025rem;
    height: 2.025rem;
  }

  #authorized > ul li img {
    height: 1.0625rem;
  }

  .tab-logo {
    max-width: 8rem;
    margin-right: 1rem;
  }

  header {
    height: 3.6125rem;
  }

  header .btn {
    font-size: 0.6875rem;
  }

  #authorized > ul .balance-wrapper {
    border-radius: 1.25rem;
    padding: 0 0.625rem;
    min-height: 2.025rem;
    max-width: 13.4rem;
  }

  #authorized > ul .balance-wrapper .top-up {
    width: 1.4625rem;
    height: 1.4625rem;
  }

  #authorized > ul .balance-wrapper .user_amount {
    font-size: 0.6875rem;
    margin-right: 0.2625rem;
  }

  #authorized > ul .balance-wrapper .currency-icon {
    width: 0.7875rem;
    margin-left: auto;
    margin-right: 0.1rem;
    height: 1.0625rem;
  }

  #authorized > ul .balance-wrapper .currency-name {
    font-size: 0.6875rem;
    /* margin-left: 0.2188rem; */
    font-weight: 600;
  }

  .refresh-balance.rotate {
    transition: 0.5s ease;
    transform: rotateZ(360deg);
  }

  #authorized > ul li.box {
    width: 2.025rem;
    height: 2.025rem;
  }

  #authorized > ul li img {
    height: 1.0625rem;
  }

  .tab-logo .logo img {
    /* width: 100%; */
    height: 4rem;
  }

  .tab-logo {
    max-width: 8rem;
    margin-right: 1rem;
  }

  .overlay.account-wrapper {
    top: 3.6125rem;
  }

  .left a.logo {
    font-size: inherit;
  }

  #authorized > ul .balance-wrapper .refresh-balance {
    margin-right: 0.3rem;
  }

  header.scrolled:after {
    display: none;
  }
}

@media screen and (max-width: 480.98px) {
  header .btn {
    font-size: 0.6875rem;
    padding: 0 0.9375rem;
    border-radius: 1.375rem;
    height: 2.125rem;
  }

  .headerButton {
    gap: 0.4375rem;
  }

  header .middle .right .user-info .wallets-wrapper .balance .dollar-coin-icon {
    height: 30px !important;
    width: 30px !important;
  }

  header .middle .right .user-info .wallets-wrapper > * {
    height: 2.5rem;
  }

  header .middle .right .user-info li .my-account {
    height: 2.5rem;
    margin-left: 0.5rem;
    padding: 0 1rem;
  }

  header .middle .right .user-info li .my-account .arrow {
    width: 0.5rem;
  }

  header
    .middle
    .right
    .user-info
    .wallets-wrapper
    .balance
    .balance-helper
    span,
  header .middle .right .user-info li .my-account span {
    font-size: 14px;
  }

  header .btn {
    border-radius: 50px;
  }
}

@media screen and (max-width: 400.98px) {
  header
    .middle
    .right
    .user-info
    .wallets-wrapper
    .balance
    .balance-helper
    span {
    font-size: 11px !important;
  }

  header .middle .right .user-info li .my-account {
    padding: 0 10px !important;
  }
}

@media screen and (max-width: 375px) {
  header .middle .right .user-info .wallets-wrapper .balance .balance-helper {
    padding: 0 3px !important;
  }

  header .middle .right .user-info li .my-account span {
    font-size: 11px !important;
  }

  header .middle .right .user-info .wallets-wrapper .balance .dollar-coin-icon {
    height: 20px !important;
    width: 20px !important;
  }

  header .middle .right .user-info li .my-account .icon img {
    height: 20px;
    width: 20px;
  }

  header .middle .right .user-info li .my-account {
    padding: 0 8px !important;
  }

  .tab-logo .logo img {
    height: 3.5rem;
  }
}
