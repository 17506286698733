main.middle .filters {
  height: 3.75rem;
  line-height: 3.75rem;
  margin-top: 1.25rem;
}

main.middle .filters ul {
  overflow: hidden;
  background: rgba(27, 32, 44, 0.9);
  border-radius: 0.625rem;
}

main.middle .bet-filter ul {
  overflow: hidden;
  background: transparent;
  border-radius: 0.625rem;
}

main.middle .bet-filter {
  height: 3.75rem;
  line-height: 3.75rem;
  margin-top: 0;
}

main.middle .filters ul li {
  float: left;
  padding: 0 0.9375rem;
  min-width: 3.75rem;
  -webkit-transition: all 0.12s ease-in;
  transition: all 0.12s ease-in;
  text-align: center;
  font-size: 0.9375rem;
  cursor: pointer;
  color: var(--secondary-text-color);
  font-weight: 400;
}

main.middle .filters ul li.active,
main.middle .filters ul li:hover {
  color: var(--white);
}

main.middle ul.promos {
  overflow: hidden;
  min-height: 50rem;
  padding-bottom: 0.625rem;
  display: grid;
  grid-template-columns: repeat(3, minmax(20.1rem, 1fr));
  grid-gap: 1.25rem;
  margin-top: 1.25rem;
}

main.middle ul.promos>li {
  border-radius: 0.625rem;
}

main.middle ul.promos>li .promo-img {
  height: 15.5875rem;
  background-size: cover !important;
  border-radius: 0.4895rem 0.4895rem 0 0;
}

main.middle ul.promos>li .info {
  position: relative;
  padding: 1.5625rem;
  overflow: hidden;
  border-radius: 0 0 0.4895rem 0.4895rem;
  background: rgba(27, 32, 44, 0.9);
}

main.middle ul.promos>li .info>h2 {
  text-align: center;
  font-size: 1.25rem;
  font-weight: bold;
}

main.middle ul.promos>li .info div.text-content {
  margin-top: 1.1875rem;
  line-height: 1.5625rem;
  font-size: 1rem;
  height: 5rem;
  text-align: center;
  overflow: hidden;
  color: var(--secondary-text-color);
}

main.middle ul.promos>li .info div.text-content span {
  font-size: 1rem !important;
}

main.middle ul.promos>li .info div.text-content ul {
  padding-left: 1rem;
  list-style-type: disc !important;
  list-style-position: inside !important;
}

main.middle ul.promos>li .info div.text-content ul li {
  list-style-type: disc !important;
  list-style-position: inside !important;
}

main.middle ul.promos>li .info .btn-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 0.625rem;
}

main.middle ul.promos>li .info .btn-wrapper span.btn {
  overflow: unset;
  position: relative;
  z-index: 1;
  color: #0c121e;
  background: #00ec66;
  -webkit-box-shadow: 0 2px 5px rgba(38, 51, 77, 0.03);
  box-shadow: 0 2px 5px rgba(38, 51, 77, 0.03);
  background-position: center;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  width: 8.75rem;
}

main.middle ul.promos>li .info .btn-wrapper span.btn:after,
main.middle ul.promos>li .info .btn-wrapper span.btn:before {
  content: "";
  position: absolute;
  z-index: -1;
  border-radius: 2rem;
  display: block;
  pointer-events: none;
}

main.middle ul.promos>li .info .btn-wrapper span.btn:before {
  background: -webkit-gradient(linear,
      left bottom,
      left top,
      from(transparent),
      to(rgba(0, 0, 0, 0.4)));
  background: linear-gradient(to top, transparent, rgba(0, 0, 0, 0.4));
  top: 0;
  height: 100%;
  width: 100%;
  right: 0;
  margin: auto;
}

main.middle ul.promos>li .info .btn-wrapper span.btn:after {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 0.125rem;
  width: 80%;
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease;
  height: 0.5rem;
  background: #00ec66;
  -webkit-filter: blur(0.625rem);
  filter: blur(0.625rem);
}

main.middle ul.promos>li .info .btn-wrapper span.btn:hover {
  -webkit-filter: brightness(1.1);
  filter: brightness(1.1);
}

main.middle ul.promos>li .info .btn-wrapper span.btn:hover:after {
  height: 0.9375rem;
}

main.middle ul.promos>li .info .btn-wrapper .read-more {
  font-size: 0.875rem;
  float: left;
  padding-top: 0.75rem;
  padding-bottom: 0.1875rem;
  cursor: pointer;
  padding: 0;
  min-width: unset;
  text-decoration: underline;
  color: var(--secondary-text-color);
  font-size: 1rem;
  font-weight: 400;
}

main.middle ul.promos>li .info .btn-wrapper .read-more:hover {
  color: var(--white);
}

/* ========================================================================= */
/* ================================ media query ============================ */
/* ========================================================================= */
@media screen and (max-width: 991.98px) {
  main.middle .filters ul {
    display: flex;
    width: 100%;
    min-height: 3.3125rem;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    /* overflow: auto; */
    -ms-scroll-snap-type: x mandatory;
    /* scroll-snap-type: x mandatory; */
    padding: 0;
    grid-gap: 0.3125rem;
    background: transparent;
  }

  main.middle .filters {
    margin: 0;
    height: auto;
  }

  main.middle .filters ul li {
    /* border-radius: 1.875rem;
    background-color: #0c121e;
    border: 0.0625rem solid #2f3646; */
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 2.25rem;
    padding: 0.75rem;
    color: #97a1c2;
    z-index: 3;
    white-space: nowrap;
  }

  main.middle .filters ul li.active,
  main.middle .filters ul li:hover {
    /* background-color: #262b36; */
    color: #ccd6f6;
  }

  main.middle ul.promos {
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-content: center;
    grid-gap: 0.625rem;
    padding: 0.625rem 0;
    min-height: inherit;
    margin: 0;
  }

  main.middle ul.promos>li .promo-img {
    height: 6.875rem;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0 0 0.9375rem 0.9375rem;
    vertical-align: middle;
    background-size: cover;
    margin-bottom: 0.5rem;
  }

  main.middle ul.promos>li {
    border-radius: 0.8125rem;
    width: 100%;
    border: 0.125rem solid #2f3646;
    overflow: hidden;
  }

  main.middle ul.promos>li .info {
    overflow: hidden;
    height: 4.625rem;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: start;
    justify-content: flex-start;
    border-radius: 0 0 0.9375rem 0.9375rem;
    padding: 0 0.9375rem;
    background: #0c121e;
  }

  main.middle ul.promos>li .info>h2 {
    /* overflow: hidden; */
    color: #ccd6f6;
    font-size: 0.875rem;
    line-height: 1rem;
    height: 2rem;
    text-align: start;
    margin: 0;
    margin-bottom: 0.3375rem;
  }

  main.middle ul.promos>li .info div.text-content {
    display: none;
  }

  main.middle ul.promos>li .info .btn-wrapper span.btn {
    display: none;
  }

  main.middle ul.promos>li .info .btn-wrapper .read-more {
    font-size: 0.6875rem;
    text-align: right;
    color: #00ec66;
    cursor: pointer;
    padding: 0;
    text-decoration: none;
    height: auto;
  }

  main.middle ul.promos>li .info .btn-wrapper {
    justify-content: flex-end;
    padding: 0.625rem;
    margin: 0;
  }
}

@media screen and (max-width: 1024px) {
  main.middle ul.promos {
    grid-template-columns: repeat(2, minmax(10rem, 1fr));
  }
}