.lucky-wheel {
  width: 535px;
  margin: 0 12px;
  gap: 22px;
  display: flex;
  flex-direction: column;
  
  p {
    margin-bottom: 0 !important;
  }
  @media screen and (max-width: 480px) {
    width: 400px;
    padding: 16px;

    .lucky-wheel__content {
      img {
        width: 350px;
      }
    }
  }
}

.lucky-wheel__content {
  display: flex;
  justify-content: center;
}

.lucky-wheel__header {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;

  button {
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #253749;
    padding: 8px;
    margin: 0;
    border: 3px solid #FFFFFF0D;
    border-radius: 12px;
    box-shadow: 0px 9px 39.6px 0px #0700214D;
    max-height: 36px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
}

.lucky-wheel__footer {
  display: flex;
  justify-content: center;

  p {
    color: #FFFFFF;
    font-family: Druk Wide Bold;
    font-size: 36px;
    font-weight: 700;
    line-height: 47.02px;

  }
}