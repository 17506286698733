.account-modal {
  width: 580px;
  margin: 0 12px;
  gap: 22px;
  display: flex;
  flex-direction: column;
  background-color: #11212E;
  border-radius: 24px;
  box-shadow: 3px -3px 12px -7px rgba(144,162,175,0.44);
  padding: 22px;
  
  p {
    margin-bottom: 0 !important;
  }

  @media screen and (max-width: 480px) {
    width: 400px;
    padding: 16px;
  }
}

.account-modal__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.account-modal__header-title {
  gap: 12px;
  display: flex;
  align-items: center;
  color: #FFFFFF;

  p {
    font-size: 24px;
    font-weight: 800;
    line-height: 24px;
  }
}

.account-modal__close-button {
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background-color: #253749;
  margin: 0;
  border: 3px solid #FFFFFF0D;
  border-radius: 12px;
  box-shadow: 0px 9px 39.6px 0px #0700214D;
  max-height: 36px;

  svg {
    width: 16px;
    height: 16px;
  }
}

.account-modal__content {
  display: flex;
  flex-direction: column;
  gap: 22px;
}

.account-modal__input-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
  color: #90A2AF;

  > p {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #90A2AF;
  }

  input {
    background-color: #09151E;
    height: 52px;
    border: 0;
    border-radius: 16px;
    color: #90A2AF;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: #90A2AF;
    padding-right: 50px;
  }

  ::placeholder {
    color: #90A2AF;
  }

  &.--name input {
    color: #41505A;
  }

  &.--error {
    input {
      border: 2px solid #FF2F2F;
    }
    .account-modal__show-password {
      top: 30%;
    }
  }

  >svg {
    top: 30%;
    right: 20px;
    position: absolute;
  }

}

.account-modal__email-content {
  gap: 2px;
  display: flex;
  flex-direction: column;

  input {
    border-bottom-right-radius: unset !important;
    border-bottom-left-radius: unset !important;
  }

  .account-modal__input-icons {
    top: 45%;
    right: 16px;
    gap: 9px;
    display: flex;
    position: absolute;
    align-items: center;
    border: unset;
    padding: unset;
    background-color: unset;

    svg {
      &:first-of-type {
       path {
         fill: #FF842B;
       }
      }
    }
  }

  .account-modal__confirm-text {
    height: 37px;
    padding: 8px 16px;
    display: flex;
    justify-content: flex-end;
    background-color: #09151E;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;

    p {
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
      color: #90A2AF;
    }
  }
}

.account-modal__password-content {
  gap: 12px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  .account-modal__show-password {
    position: absolute;
    top: 43%;
    color: #90A2AF;
    right: 9px;
    opacity: 0.6;
    width: 38.5px;
    height: 37.5px;
    
    svg, path {
      width: 22.5px;
      height: 22.5px;
    }

  }

  .account-modal__change-password {
    height: 52px;
    width: 50%;
    border: unset;
    border-radius: 16px;
    padding: 12px 18px;
    background-color: #253749;

    p {
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
      color: #90A2AF;
    }

  }
  .account-modal__password-error {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 2%;
    color: #FF2F2F;
  }

  @media screen and (max-width: 480px) {
    flex-direction: column;

    .account-modal__change-password {
      width: 100%;
    }
  }
}

.account-modal__switch-wrapper {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .account-modal__switch-text {
    gap: 6px;
    display: flex;
    flex-direction: column;

    p {
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
      color: #FFFFFF;

      &:last-of-type {
        color: #90A2AF;
      }
    }
  }

  .account-modal__balance-switch {
    padding: 9px 12px;

    .MuiSwitch-track {
      border-radius: 42px;
    }

    .Mui-checked+.MuiSwitch-track {
      opacity: 1;
      background-color: #70F16E;
    }

    .MuiSwitch-switchBase {
      padding: 12px 16px;

      .MuiSwitch-thumb {
        background-color: #90A2AF;
        width: 14px;
        height: 14px;
      }


      &.Mui-checked {
        padding: 12px 9px;

        .MuiSwitch-thumb {
          background-color: #FFFFFF;
        }
      }
    }
  }
}

.account-modal__confirm-button {
  height: 52px;
  padding: 16px;
  border: unset;
  border-radius: 16px;
  background: linear-gradient(129.51deg, #FF9F00 -12.53%, #FFDB8C 82.06%);

  p {
    color: #101010;
    font-weight: 700;
    font-size: 18px;
    line-height: 21.92px;
  }
}

.account-modal__help-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #90A2AF;

  b {
    color: #FFFFFF;
  }
}