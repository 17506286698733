
.referral-award__overview {
  gap: 36px;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.referral-award__cards-wrapper {
  gap: 18px;
  display: flex;
  flex: 1;

  &.--column {
    flex-direction: column;
  }
}

.referral-award__card-balance {
  gap: 18px;
  min-width: 242px;
  min-height: 357px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 22px;
  border-radius: 16px;
  box-shadow: inset 0 0 0 2px #FFFFFF47;
  background-image: url(../../../assets/images/sidebar/referral-bg.png);
  background-repeat: repeat;
  background-position: top right;
  background-size: cover;

  button {
    height: 52px;
    width: 100%;
    margin-top: auto;
    border: unset;
    border-radius: 12px;
    background-color: #FFFFFF;

    >p {
      font-size: 18px;
      font-weight: 700;
      line-height: 21.92px;
      color: #101010;
    }
  }
}

.referral-award__balance-title {
  font-size: 24px;
  font-weight: 800;
  line-height: 24px;
  color: #111426;
}

.referral-award__balance-badge {
  gap: 8px;
  display: flex;
  align-items: center;
  padding: 12px;
  background-color: #111117;
  border-radius: 28px;

  >svg {
    width: 24px;
    height: 24px;
  }

  p {
    font-family: Druk Wide Bold;
    font-size: 22px;
    font-weight: 700;
    line-height: 14px;
    color: #FFFFFF;
  }
}

.referral-award__card {
  display: flex;
  flex-direction: column;
  max-height: 118px;
  width: 100%;
  padding: 24px;
  border-radius: 16px;
  background-color: #11212E;
  border: 2px solid #FFFFFF0D;
  box-shadow: 0px 9px 39.6px 0px #0700214D;

  &.--campaign {
    max-height: unset;
    gap: 28px
  }
}

.referral-award__card-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 21.92px;
  color: #90A2AF;
}

.referral-award__card-value {
  gap: 9px;
  display: flex;
  align-items: center;
  margin-top: auto;

  svg, path {
    fill: #DCDCDC;
    height: 20px;
  }

  p {
    font-family: Druk Wide Bold;
    font-size: 22px;
    font-weight: 700;
    line-height: 14px;
    color: #FFFFFF;
  }
}

.referral-award__campaign-item {
  display: flex;
  gap: 18px;
  justify-content: space-between;
}

.referral-award__campaign-title {
  gap: 6px;
  display: flex;
  flex-direction: column;

  p {
    color: #FFFFFF;
    font-size: 22px;
    font-weight: 800;
    line-height: 22px;
  }

  p:last-of-type {
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    color: #90A2AF;
  }
}

.referral-award__campaign-buttons {
  gap: 18px;
  display: flex;

  button {
    height: 44px;
    gap: 10px;
    display: flex;
    align-items: center;
    padding: 12px;
    border: unset;
    border-radius: 12px;
    color: #90A2AF;
    background-color: #253749;

    &:last-of-type {
      color: #101010;
      background: linear-gradient(129.51deg, #FF9F00 -12.53%, #FFDB8C 82.06%);
    }

    p {
      font-size: 14px;
      font-weight: 700;
      line-height: 18px;
    }
  }
}

.referral-award__campaign-divider {
  width: 100%;
  height: 1px;
  border: 1px solid #1E3241;
}

.referral-award__input-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
  color: #90A2AF;

  > p {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
  }

  input {
    background-color: #09151E;
    height: 52px;
    border: 0;
    border-radius: 16px;
    color: #90A2AF;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.02em;
  }

  .referral-award___copy-icon {
    right: 19px;
    top: 55%;
    position: absolute;
  }
}

@media screen and (max-width: 991.98px) {
  .referral-award__cards-wrapper {
    flex-direction: column;
  }

  .referral-award__card-balance {
    height: unset;
    background-position: top -10px right;
  }

  .referral-award__card {
    gap: 15px;
  }

  .referral-award__campaign-item,
  .referral-award__campaign-buttons {
    flex-direction: column;

    button {
      display: flex;
      justify-content: center;
    }
  }
}

.referral-award__your-referrals {
  gap: 14px;
  display: flex;  
  flex-direction: column;
}

.referral-award__referrals-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  >p {
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 800;
    line-height: 18px;
  }
}

.referral-award__referrals-tabs {
  display: flex;
  gap: 10px;
}

.referral-award__referrals-tab {
  padding: 10px 16px;
  background-color: transparent;
  height: 41px;
  color: #90A2AF;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  border: unset;
  border-radius: 12px;

  &.--active {
    color: #FFFFFF;
    background-color: #11212E;
  }
}

.referral-award__referrals-wrapper {
  overflow: auto;
}

.referral-award__referrals-list {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;

  @media screen and (max-width: 991.98px) {
    min-width: 1220px;
  }
}

.referral-award__list-item {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  justify-content: space-between;
  padding: 14px 16px;
  background-color: #11212E;
  border-radius: 16px;

  &.--header {
    background-color: transparent;
    p {
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0.02em;
      color: #90A2AF;
    }
  }
  
  .referral-award__item-col {
    gap: 6px;
    display: flex;
    align-items: center;
    color: #90A2AF;

    

    p {
      font-size: 16px;
      font-weight: 600;
      line-height: 21px;

    }

    &.--white-text {
      color: #FFFFFF;
    }

    img {
      width: 28px;
      height: 28px;
    }

    svg, path {
      width: 20px;
      height: 20px;
    }
  }
}
