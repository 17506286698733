.referral-award {
  gap: 36px;
  display: flex;
  flex-direction: column;
  padding: 36px 66px;

  p {
    margin-bottom: 0;
  }
}

.referral-award__header {
  gap: 23px;
  display: flex;
  flex-direction: column;
}

.referral-award__header-item {
  gap: 12px;
  display: flex;
  align-items: center;

  &.--butons {
    gap: unset;
  }
  >svg {
    width: 24px;
    height: 24px;
  }
}

.referral-award__header-title {
  font-size: 24px;
  font-weight: 800;
  line-height: 24px;
  color: #FFFFFF;
}

.referral-award__header-button {
  gap: 8px;
  display: flex;
  align-items: center;
  height: 37px;
  padding: 0 18px;
  background-color: transparent;
  border: unset;
  border-radius: 12px;

  svg, path {
    fill: #90A2AF;
  }

  p {
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    color: #90A2AF;
  }

  &.--active {
    background-color: #253749;

    svg, path, p {
      fill: #DCDCDC;
      color: #DCDCDC;
    }

  }
}

@media screen and (max-width: 991.98px) {
  .referral-award {
    padding: 24px 12px;
  }
}