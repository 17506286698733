.tapbar {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  background: linear-gradient(180deg, #1E3241 0%, #11212E 100%);
  width: 100%;
  height: 66px;
  grid-area: tapbar;
  position: fixed;
  bottom: 0;
  z-index: 12;
}

.tapbar__button {
  height: 66px;
  flex: 1;
  gap: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background-color: transparent;
  border: unset;
  padding: 9px 12px;
  opacity: 1 !important;

  &.--open {
    background-image: url("../../assets/images/footer/footer-active-bg.png");
    background-repeat: no-repeat;
    background-position: top center;
    rect {
      fill: #FFDB8C;
    }

    p {
      background: linear-gradient(129.51deg, #FF9F00 -12.53%, #FFDB8C 82.06%);
      background-clip: text;
      color: transparent;
    }
  }

  >p {
    margin-bottom: 0;
    font-size: 12px;
    line-height: 21px;
    font-weight: 600;
    color: #90A2AF;
  }

  svg, path {
    fill: #DCDCDC;
  }
}

.tapbar__icon-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2px;
  width: 26px;
  height: 26px;
  
}

