.user-profile__container {
  position: relative;
  max-height: 50px;

  p {
    margin-bottom: 0;
  }
}

.user-profile__avatar-wrapper {
  margin-top: -10px;
  width: 65px;
  height: 65px;
}

.user-profile__avatar {
  max-height: 50px;
  max-width:  50px;
}

.user-profile__progress {
  display: flex;
  gap: 4px;
  font-size: 12px;
  color: #B0B0B0;
  position: absolute;
  background: #292834;
  padding: 1px 5px;
  border-radius: 9px;
  top: 70%;
  left: -1px;

  p:first-of-type {
    color: #fff;
  }
}

.user-profile__user-level {
  z-index: 10;
  position: absolute;
  width: 28px;
  height: 28px;
  border-radius: 100%;
  top: -12px;
  right: -8px;
  text-align: center;
  background-color: #FF1E00;
  border: 3px solid #2C1F25;
  color: #FFFFFF;
}