.campaing {
  gap: 36px;
  display: flex;
  flex-direction: column;
}

.campaigns__header {
  gap: 14px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.campaigns__title {
  display: flex;
  align-items: center;
  gap: 12px;
  
  p {
    font-size: 18px;
    font-weight: 800;
    line-height: 18px;
    color: #FFFFFF;
  }
}

.campaigns__header-badge {
  border-radius: 100px;
  padding: 2px 8px;
  background-color: #5956FF;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 600;
  line-height: 20.3px;
  letter-spacing: 0.01em;

}

.campaigns__buttons-wrapper {
  gap: 10px;
  display: flex;
  align-items: center;
}

.campaigns__header-button {
  height: 41px;
  border-radius: 12px;
  padding: 0 16px;
  border: unset;
  color: #90A2AF;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  background-color: transparent;

  &.--active {
    color: #FFFFFF;
    background-color: #11212E;
  }
}

.campaigns__list-wrapper {
  display: flex;
  flex-direction: column;
}

.campaigns__list {
  gap: 6px;
  display: flex;
  flex-direction: column;
}

.campaigns__list-header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  height: 48px;
  padding: 16px;
}

.campaigns__list-item {
  cursor: pointer;
  display: inline-grid;
  grid-template-columns: 2fr 2fr 2fr 2fr 2fr 1fr;
  background-color: #11212E;
  border-radius: 16px;
  padding: 16px 24px 16px 16px;
  overflow-x: scroll;
  @media screen and (max-width: 991.98px) {
    min-width: 1220px;
  }
}

.campaigns__col-item {
  display: flex;
  align-items: center;
  gap: 8px;

  &:last-of-type {
    justify-self: flex-end;
  }

  &.--justify-center {
    justify-self: center;
  }

  &.--justify-end {
    justify-self: flex-end;
  }

  &.--white-text {
    p {
      color: #FFFFFF;
    }
  }

  &.--vertical {
    flex-direction: column;
    align-items: unset;
  }
  

  p {
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    color: #90A2AF;
  }
}
.campaigns__col-subitem {
  gap: 8px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 800;
  line-height: 21px;
  color: #FFFFFF;
}

.campaigns__expanded-item {
  gap: 4px;
  display: flex;
  flex-direction: column;
}

.campaigns__expanded-top {
  cursor: pointer;
  display: grid;
  height: 56px;
  grid-template-columns: 2fr 2fr 1fr;
  background-color: #11212E;
  border-radius: 16px;
  border-bottom-left-radius: unset;
  border-bottom-right-radius: unset;
  padding: 16px 24px 16px 16px;
}


.campaigns__top-subitem {
  gap: 8px;
  display: flex;
  flex-direction: column;
}

.campaigns__mobile-header {
  display: none;
}

.campaigns__coll-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.campaigns__expanded-bottom {
  gap: 24px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 16px;
  border-top-left-radius: unset;
  border-top-right-radius: unset;
  background-color: #11212E;
}

.campaigns__bottom-item {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
}

.campaigns__divider {
  height: 1px;
  width: 100%;
  border: 1px solid #1E3241;
}

.campaigns__inputs-wrapper {
  gap: 18px;
  display: flex;
}

.campaigns__input-item {
  flex: 1;
  position: relative;
  gap: 6px;
  display: flex;
  flex-direction: column;

  > label {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    color: #90A2AF;
  }

  svg {
    position: absolute;
    right: 16px;
    top: 50%;
  }

  &:first-of-type {
    input {
      &::placeholder {
        color: #41505A;
      }
    }
  }
}

.campaigns__input {
  height: 52px;
  padding:  0 16px;
  border-radius: 16px;
  border: unset;
  background-color: #09151E;

  &::placeholder {
    color: #90A2AF;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
  }
}

@media screen and (max-width: 991.98px) {
  .campaigns__header {
    flex-direction: column;
    margin-bottom: 14px;
  }
  .campaigns__list-header {
    display: none;
  }
  .campaigns__expanded-item {
    .campaigns__col-item {
      &:last-of-type {
        align-items: flex-start;
      }
    }
  }

  .campaigns__expanded-top {
    height: unset;
    grid-template-columns: 4fr 3fr 1fr;
  }

  .campaigns__mobile-header {
    display: block;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: #90A2AF !important;
  }

  .campaigns__bottom-item {
    grid-template-columns: 4fr 3fr 1fr;
  }

  .campaigns__coll-wrapper {
    gap: 51px;
    display: flex;
    flex-direction: column;

    p:first-of-type {
      font-size: 14px !important;
    }
    div {
      &:last-of-type {
        justify-self: flex-end;
      }
    }
  }

  .campaigns__inputs-wrapper {
    flex-direction: column;
  }
}