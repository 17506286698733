.new-modal {
  z-index: 999999;

  >div {
    background-color: #09151ECC;
  }
}
.new-modal__content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}