.profile__container {
  padding: 36px 66px;
  gap: 36px;
  display: flex;
  flex-direction: column;

  p {
    margin-bottom: unset;
  }

  button {
    &:disabled {
      opacity: 0.5;
      gap: unset;
      span {
        margin-right: 10px;
      }
    }
  }
}

.profile__container-header {
  display: flex;
  justify-content: space-between;

  .pofile__header-wrapper {
    gap: 16px;
    display: flex;

    img {
      border-radius: 18px;
      width: 92px;
      height: 92px;
    }
  }

  .profile__user-details {
    display: flex;
    flex-direction: column;

    .profile__details-name {
      gap: 2px;
      display: flex;
      flex-direction: column;
      background-image: linear-gradient(180deg, #FFFFFF 0%, #A8A8A8 100%);
      color: transparent;
      background-clip: text;
      font-weight: 700;
      font-size: 20px;
      line-height: 24.36px;

      p:last-child {
        font-size: 14px;
        line-height: 17.05px;
      }
    }
  }

  .profile__logout-button {
    gap: 13px;
    display: flex;
    align-items: center;
    height: 41px;
    border: unset;
    border-radius: 12px;
    padding: 10px 14px;
    background-color: #FF2F2F33;
    margin-top: 9px;

    p {
      color: #FF2F2F;
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;

    }
  }

  .profile__settings-button {
    gap: 12px;
    display: flex;
    align-items: center;
    height: 52px;
    padding: 14px 16px;
    border: 2px solid #FFFFFF0D;
    border-radius: 16px;
    background-color: #253749;

    p {
      color: #DCDCDC;
      font-weight: 700;
      font-size: 18px;
      line-height: 21.92px;
    }
  }
}

.profile__bet-history {
  gap: 14px;
  display: flex;
  flex-direction: column;
}

.profile__history-header {
  display: flex;
  justify-content: space-between;

  >p {
    color: #FFFFFF;
    font-weight: 800;
    font-size: 24px;
    line-height: 24px;
  }

  .profile__history-buttons {
    gap: 10px;
    display: flex;
  }

  .profile__date-filter {
    height: 41px;
    padding: 10px 16px;
    border-radius: 12px;
    background-color: transparent;
    border: unset;

    &.--active {
      background-color: #11212E;
      p {
        color: #FFFFFF;
      }
    }

    p {
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
      color: #90A2AF;
    }
  }
}

.profile__game-filter {
  gap: 9px;
  display: flex;

  svg, path {
    fill: #DCDCDC;
  }

  .profile__game-button {
    gap: 10px;
    display: flex;
    align-items: center;
    height: 34px;
    padding: 7px 10px;
    border: unset;
    border-radius: 10px;
    background-color: transparent;

    &.--active {
      background-color: #11212E;
      p {
        color: #DCDCDC;
      }
    }

    p {
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
      color: #90A2AF;
    }
  }
}

.profile__history-list {
  gap: 6px;
  display: flex;
  flex-direction: column;
}

.profile__list-header {
  height: 48px;
  padding: 16px;
  display: flex;
  justify-content: space-between;

  p {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 2%;
    color: #90A2AF;
  }
}

.profile__list-item {
  height: 50px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  background-color: #11212E;
  border-radius: 16px;

  p {
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: #90A2AF;
  }
}

.profile__list-col {
  flex: 3;
  gap: 6px;
  display: flex;
  align-items: center;

  &.--flex2 {
    flex: 2;
  }

  &.--white-text {
    p {
      color: #FFFFFF;
    }
  }

  &.--gold-text {
    flex: 2;
    p {
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
      color: transparent;
      background-clip: text;
      background-image: linear-gradient(180deg, #FFC882 0%, #FD9632 100%);
    }
  }

  &.--ellipsis {
    p {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 160px;
      
      @media screen and (max-width: 1950px) { 
        max-width: 130px;
      }
    }
  }

  svg {
    width: 22px;
    height: 22px;
  }
  
}

.profile__list-counter {
  margin-top: 22px;
  gap: 20px;
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;

  p {
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #90A2AF;

    b {
      color: #FFFFFF;
    }
  }

  button {
    height: 45px;
    border: unset;
    border-radius: 16px;
    padding: 12px 20px;
    background-color: #11212E;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: #90A2AF;
  }
}

@media screen and (max-width: 480px) { 
  .profile__container {
    padding: 24px 12px;
  }

  .profile__container-header {
    gap: 16px;
    flex-direction: column;
    align-items: flex-start;
  }

  .profile__history-header {
    gap: 34px;
    align-items: center;

    > p { 
      color: #FFFFFF;
      font-weight: 800;
      font-size: 20px;
      line-height: 100%;
    }
  }

  .profile__list-counter {
    width: 100%;
  }
}

@media screen and (max-width: 1650px) {
  .profile__list-item, 
  .profile__list-header {
      min-width: 1000px;
  }
  
  .profile__bet-history {
    overflow: hidden;
  }
  
  .profile__history-list {
    overflow: auto;
    
  }
}