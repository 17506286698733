@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import url('https://fonts.cdnfonts.com/css/druk-wide-bold');

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: sans-serif;
  font-weight: bold;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --white: #fff;
  --primary: #97d700;
  --brandColor: #00ec66;
  --orange-primary: #f45a41;
  --casinocolor: #f47341;
  --backgroundColor: #09151E;
  --darkBackground: #1a1f2b;
  --secondary-text-color: #ccd6f6;
  --headerHeight: 5.3333rem;
  --sideBarWidth: 290px;
  --gradeinet: linear-gradient(50deg,
      rgba(128, 112, 229, 0.26),
      rgba(9, 13, 21, 0.26));
  --surface-shadow: 0.5rem 0.5rem 5rem 0 rgba(9, 13, 20, 0.4),
    -0.25rem -0.25rem 0.5rem 0 rgba(224, 224, 255, 0.04),
    0 0.0625rem 0.0625rem 0 rgba(9, 13, 20, 0.4);
  --surface-border: solid 0.0625rem #464b58b4;
  --graybtn: linear-gradient(101deg, #1a1e2b, #0c121e2b 52%, #171c28);
  --colorbtn: #00ec66;
  --swiper-theme-color: #00ec66 !important;
  --responsivecolor: #ccd6f6;
  --brandColor: #00ec66;
}

button {
  &:hover {
    transition: opacity 0.4s ease-out;
    opacity: 0.5;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

input:focus {
  border: 2px solid #ffffff27;
  transition: border-color 1s ease; 
}

body {
  background-color: var(--backgroundColor) !important;
  background-repeat: no-repeat;
  background-position: center;
  color: var(--white);
  /* min-height: 100vh; */
  /* overflow: hidden !important; */
}

.hiddenScoll {
  overflow: hidden !important;
}


* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5 {
  color: var(--secondary-text-color);
  font-weight: 400;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset;
}

input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.3);
}

input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.3);
}

input::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.3);
}

input::placeholder {
  color: rgba(255, 255, 255, 0.3);
}

.modal::-webkit-scrollbar,
body::-webkit-scrollbar {
  width: 2px;
  background-color: transparent;
}

.modal::-webkit-scrollbar-track,
body::-webkit-scrollbar-track {
  background-color: transparent;
}

.modal::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
  background-color: rgb(135, 132, 132);
}

input,
select {
  padding-left: 1rem;
}

input:focus-visible {
  outline: none;
}

html {
  /* font-size: 0.9vw !important; */
  font-size: 15px !important;
}

@media (max-width: 991.98px) {
  html {
    font-size: 2.5vw !important;
  }

  body {
    color: var(--responsivecolor);
  }
}

@media (max-width: 767.98px) {
  html {
    font-size: 3.8vw !important;
  }
}

.err-text {
  color: #f44141 !important;
  min-height: 1.0625rem;
  text-align: center;
  font-weight: 400;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  width: 24px;
  height: 24px;
  cursor: pointer;
  background-image: url("./assets/images/account/calendar.png");
  /* Replace with your own icon image */
  background-size: cover;
}

/* input.dirty:not(:focus):not(.invalid):valid {
  background-image: url(../assets/images/icons/auth/success43a0.svg?v3);
  background-repeat: no-repeat;
  background-size: auto 1.5rem;
  background-position: center right 1.2rem;
}
input.dirty:not(:focus).invalid,
input.dirty:not(:focus):invalid {
  border: 1px solid #f44141;
  background-image: url(./assets/images/header/auth/danger.svg);
  background-repeat: no-repeat;
  background-size: auto 1.5rem;
  background-position: center right 1.2rem;
  padding-right: 3.5rem;
} */
input.dirty.invalid,
input.dirty:invalid {
  border: 1px solid #f44141 !important;
  background-image: url(./assets/images/header/auth/danger.svg);
  background-repeat: no-repeat;
  background-size: auto 1.5rem;
  background-position: center right 1rem;
  padding-right: 3.5rem;
}

.input-sub-icon.dirty.invalid,
.input-sub-icon.dirty:invalid {
  border: 1px solid #f44141 !important;
  background-image: url(./assets/images/header/auth/danger.svg);
  background-repeat: no-repeat;
  background-size: auto 1.5rem;
  background-position: center right 2.6rem;
  padding-right: 3.5rem;
}

.input-sub-icon+svg {
  height: 2.2rem !important;
}

input.dirty:not(:focus).invalid::placeholder,
input.dirty:not(:focus):invalid::placeholder {
  color: #f44141 !important;
}

input.dirty.invalid::placeholder,
input.dirty:invalid::placeholder {
  color: #f44141 !important;
}

/* ============================================================== */
.modal {
  z-index: 9999;
}

.jackbit-modal .modal-content {
  background: transparent !important;
  border: none !important;
  padding: 0px !important;
}

.jackbit-modal .modal-body {
  padding: 0 !important;
}

.modal-open .middle {
  filter: blur(10px);
}

.modal-open header .middle {
  filter: inherit;
}

.modal.fade .modal-dialog {
  transform: inherit;
}

.modal.fade .modal-dialog {
  -webkit-animation: 0.6s popupAnimation;
  animation: 0.6s popupAnimation;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes popupAnimation {
  from {
    transform: translate(0, 10%);
    opacity: 0;
  }

  to {
    transform: translate(0, 0);
    opacity: 1;
  }
}

/* ====================================================== */
.btn {
  position: relative;
  min-width: 7.3333rem;
  height: 2.6667rem;
  border-radius: 2rem;
  color: var(--secondary-text-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 1.8667rem;
  font-weight: bold;
  cursor: pointer;
  overflow: hidden;
  border: none;
}

.btn.solid {
  border: 1.6px solid var(--secondary-text-color);
  position: relative;
  overflow: hidden;
}

.jackbit-modal.modal {
  /* --bs-modal-width: 100%; */
  padding: 0 !important;
  z-index: 99999;
}

/* ========================================================================= */
/* ================================ media query ============================ */
/* ========================================================================= */

@media screen and (max-width: 991.98px) {
  .jackbit-modal.modal {
    --bs-modal-width: 100%;
  }

  .modal-dialog {
    margin: 0;
  }

  #sidebar .sections-wrapper .language {
    margin: 3rem 0 8rem;
  }
}

@media screen and (max-width: 480.98px) {
  .jackbit-modal.modal {
    --bs-modal-margin: 0;
  }
}

.Toastify__toast-container {
  z-index: 999999999999;
}