body {
  background-color: var(--backgroundColor);
  background-repeat: no-repeat;
  background-position: center;
  color: var(--white);
  min-height: 100vh;

  p {
    margin-bottom: unset;
  }
  /* overflow: hidden; */
}

.drawer__sidebar {
  width: 244px;
  position: relative;
  height: 100%;
  background: #11212E !important;

}

.drawer {
  top: 79px !important;
  height: calc(100% - 144px) !important;
 
  .MuiBackdrop-root {
    top: 79px !important;
    height: calc(100% - 144px) !important;
  }

  .MuiPaper-root {
    top: 79px !important;
    height: calc(100% - 144px) !important;
    box-shadow: unset !important;
    background-color: #11212E !important;
  }
}


.drawer__profile {
  gap: 20px;
  display: flex;
  align-items: center;
  padding: 30px 24px 0;
}

.drawer__profile-details {
  gap: 2px;
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(180deg, #FFFFFF 0%, #A8A8A8 100%);
  background-clip: text;
  color: transparent;
  .drawer__profile-text {
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
   

    &.--subtitle {
      font-size: 14px;
      
    }
  }
}

.content-footer {
  grid-area: navbar;
}

.wrapper {
  position: relative;
  width: 100%;
  display: grid;
  overflow: hidden;
  max-height: 100vh;
  grid-template-areas: 
    "head head head"
    "nav main chat"
    "navbar navbar navbar";
  grid-template-columns: 290px 4fr 1fr; 
  grid-template-rows: 90px calc(100vh - 90px);
}

.main-content {
  display: flex;
  position: relative;
  flex: 1;
}

.content-grid {
  display: flex;
  justify-content: space-between;
}

.middle {
  position: relative;
  transition: 0.3s ease;
  display: flex;
  flex-direction: column;
  overflow: auto;
  grid-area: main;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

/* .middle {
  width: 87.5rem !important;
} */

.dummy {
  height: 500px;
  padding: 100px;
  text-align: center;
  font-size: 40px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ========================================================================= */
/* ================================ media query ============================ */
/* ========================================================================= */
@media screen and (max-width: 1024px) {
  .middle {
    transition: 0.3s ease;
  }
}

@media screen and (max-width: 991px) {
  .middle {
    width: 100%;
    max-width: unset;
  }

  body {
    margin: 0 !important;
    height: 100% !important;
    overflow: hidden !important;
  }

  .wrapper {
    grid-template-areas: 
    "head"
    "main";
    grid-template-columns: 1fr;
    grid-template-rows: 80px calc(100% - 146px);
    position: absolute;
    height: 100svh;
    width: 100%;
  }
}