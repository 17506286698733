.leaderboard {
  gap: 36px;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  p {
    margin-bottom: 0;
  }

  @media screen and (max-width: 480px) {
    padding: 0;

    .leaderboard__header {
      padding: 37px 12px;
    }
    
  }
}

.leaderboard__header {
  gap: 14px;
  display: flex;
  padding: 36px 66px;

  p {
    font-weight: 800;
    font-size: 24px;
    line-height: 24px;
    color: #FFFFFF;
  }
}

.leaderboard__container {
  display: flex;
  justify-content: center;
  overflow-x: scroll;
  
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent; 
  }

  @media screen and (max-width: 991.98px) {
    justify-content: unset;

    .leaderboard__top-places {
      gap: unset;
    }
  }
}
.leaderboard__top-places {
  gap: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1090px;
}

.leaderboard__leader-wrapper {
  width: 290px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:first-of-type {
    .leaderboard__leader-avatar {
      background-image: url("../../assets/images/leaderboard/top2-bg.png");
    }
    .leaderboard__avatar-wrapper {
      background-image: linear-gradient(180deg, #C2C2C2 0%, #FFFFFF 57.86%, #5F6265 100%);
    }
  }

  &:last-of-type{
    .leaderboard__leader-avatar {
      background-image: url("../../assets/images/leaderboard/top3-bg.png");
    }
    .leaderboard__avatar-wrapper {
      background-image: linear-gradient(180deg, #AA6A1D 0%, #D59549 57.86%, #703E03 100%);
    }
  }
}



.leaderboard__leader-avatar {
  gap: 16px;
  width: 100%;
  height: 297px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: url("../../assets/images/leaderboard/top1-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: -40px;

  .leaderboard__avatar-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 113px;
    height: 113px;
    border-radius: 100%;
    background-image: linear-gradient(180deg, #FFCC47 0%, #FFDE88 57.86%, #FFB800 100%);
  }
  
  p {
    font-family: Druk Wide Bold;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
  }

  img {
    border-radius: 100%;
    height: 106px;
    width: 106px;
  }
}

.leaderboard__leader-box {
  background-image: url('../../assets/images/leaderboard/score-bg.png');
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  gap: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 20px 44px;
  height: 197px;
  width: 243px;
}

.leaderboard__box-text {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.--score>p:last-of-type {
    font-weight: 700;
    font-size: 18px;
    line-height: 21.92px;
  }

  svg {
    margin-right: 10px;
    width: 22px;
    height: 22px;
  }

  p {
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: #90A2AF;

    &:last-of-type {
      color: #FFFFFF;
    }
  }
}

.leaderboard__box-divider {
  width: 100%;
  height: 2px;
  border: 1px solid #1E3241;
}

.leaderboard__leader-list {
  gap: 14px;
  display: flex;
  flex-direction: column;
  padding: 0 66px;

  @media screen and (max-width: 991.98px) {
    padding: 0 12px 37px;
  }
}

.leaderboard__list-header {
  gap: 10px;
  display: flex;
  justify-content: flex-end;

  .leaderboard__list-button {
    background-color: transparent;
    border: unset;
    border-radius: 12px;
    height: 41px;
    padding: 10px 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: #90A2AF;

    &.--active{
      color: #FFFFFF;
      background-color: #11212E;
    }
  }
}
.leaderboard__list-wrapper {
  gap: 6px;
  display: flex;

  @media screen and (max-width: 991.98px) {
    flex-direction: column;

    .leaderboard__item-col {
     
      justify-content: center;

      &:first-of-type {
        flex: 1;
        justify-content: flex-start;
      }
    }
  }
}
.leaderboard__list {
  flex: 1;
  gap: 6px;
  display: flex;
  flex-direction: column;
}

.leaderboard__list-item {
  page-break-inside: avoid;
  break-inside: avoid;
  border-radius: 16px;
  padding: 16px;
  height: 56px;
  display: flex;
  align-items: center;
  background-color: #11212E;

  .leaderboard__item-col {
    flex: 2;
    display: flex;
    align-items: center;

    &.--white-text p {
      color: #FFFFFF;
    }

    &:last-of-type {
      flex: 1;
    }

    p {
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
      color: #90A2AF;
    }

    img {
      margin-right: 12px;
      border-radius: 100%;
      height: 28px;
      width: 28px;
    }
    
    svg {
      margin-right: 6px;
      height: 22px;
      width: 22px;
    }
  }
}

