.terms {
  display: flex;
  flex-direction: column;
  margin: 36px 66px;

  .content {
    .MsoListParagraph {
      font-weight: 800;
      font-size: 18px;
      line-height: 21px;
      color: #FFFFFF;
    }

    .MsoNormal {
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
      color: #90A2AF;
    }
  }
}

.terms-header {
  h1 {
    font-weight: 800;
    font-size: 24px;
    line-height: 24px;
    color: #FFFFFF;
  }
}


@media screen and (max-width: 991.98px) {
  .terms {
    margin: 24px 12px;
  }

  .terms-header h1 {
    font-size: 20px;
    line-height: 20px;
  }
}