.chat {
  min-width: 320px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #11212E;
  grid-area: chat;
  z-index: 12;
  p {
    margin-bottom: 0;
  }

  button {
    padding: 0;
  }
}

.chat__header {
  display: flex;
  padding: 24px 24px 20px;
  align-items: center;
}

.chat__header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.chat__header-title {
  gap: 14px;
  display: flex;
  align-items: center;
  grid-area: title;

  p {
    font-weight: 700;
    font-size: 18px;
    line-height: 16px;
    color: #FFFFFF;
  }
}

.chat__header-badge {
  display: flex;
  align-items: center;
  gap: 7px;
  border-radius: 10px;
  padding: 0 10px;
  height: 33px;
  background-color: #76E7410D;
  grid-area: badge;
  
  p {
    font-weight: 700;
    font-size: 14px;
    line-height: 17.05px;
  }

  .chat__badge-indicator {
    height: 8px;
    width: 8px;
    border-radius: 100%;
    background-color: #76E741;
  }
}

.chat__header-close {
  color: #90a2af !important;
  display: none !important;
  grid-area: close;
  height: unset;
}

.chat__message-time {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #90A2AF;
}

.chat__messages {
  flex: 1;
  padding: 0 24px 16px;
  gap: 16px;
  display: flex;
  flex-direction: column-reverse;
  overflow: auto;
}

.chat__message-item {
  gap: 12px;
  display: flex;
  flex-direction: column;
}

.chat__message-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .chat__message-title {
    gap: 8px;
    display: flex;
    align-items: center;

    .chat__message-badge {
      padding: 4px;
      border-radius: 100px;
      text-transform: capitalize;
      font-weight: 600;
      font-size: 11px;
      line-height: 15.95px;
      color: #FFFFFF;

      &.--moderator {
        background-color: #5956FF;
      }

      &.--admin {
        background-color: #D63789;
      }
    }
    
    >p {
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      color: #DCDCDC;
    }

    img {
      border-radius: 8px;
    }
  }
}

.chat__message-content {
  gap: 10px;
  display: flex;
  padding: 8px;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 12px;
  background-color: #09151E;

  p {
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: #DCDCDC;
  }
}

.chat__message-replied {
  gap: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
  margin-bottom: 10px;
  min-height: 35px;
  border-left: 2px solid #FFA23A;

  p {
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: #90A2AF;
  }
}

.chat__footer {
  background-color: #253749;
  padding: 22px 24px;
  display: flex;
  flex-direction: column;
  border-top: 2px solid #1E3241;
}

.chat__footer-reply {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  animation: slideFromRight 1s;
}

.chat__close-reply {
  color: #90a2af !important;
  height: unset;
}

.chat__footer-wrapper {
  gap: 10px;
  display: flex;
  align-items: center;
  padding: 8px;
  width: 100%;
  height: 66px;
  border-radius: 16px;
  background-color: #09151E;
}

.chat__input-wrapper {
  position: relative;
  display: flex;
  height: 100%;
  flex: 1;

  input {
    background-color: transparent;
    border: unset;
    height: 100%;
    width: 100%;
    padding-right: 40px;

    &::placeholder {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #FFFFFF;
    }
  }

  .chat__button-emoji {
    right: 8px;
    height: 100%;
    width: 40px;
    position: absolute;
    background-color: unset;
    border: unset;
    svg {
      height: 21px;
      width: 21px;
    }
  }

  .EmojiPickerReact {
    position: absolute;
    bottom: 40px;
    right: 0;
  }
}

.chat__button-sent {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid #FFFFFF0D;
  border-radius: 18px;
  background-color: #253749;
  box-shadow: 0px 9px 39.6px 0px #0700214D;

  &:disabled {
    opacity: 0.5;
  }

  svg {
    height: 19px;
    width: 22px;
  }
}

@media screen and (max-width: 991px) {
  .chat {
    top: 0;
    bottom: 0;
    position: fixed;
    height: 100svh;
    width: 100%;
    z-index: 1300;
    transform: translateX(100%);
    transition: transform ease-in-out 0.7s;

    &.--show {
      transform: translateX(0);
      transition: transform ease-in-out 0.7s;
    }
  }

  .chat__header-wrapper {
    justify-content: unset;
    gap: 16px;
  }

  .chat__header-close {
    display: block !important;
  }

  .chat__input-wrapper {
    input {
      padding-right: 14px;
    }
  }

  .chat__button-emoji {
    display: none;
    height: 0;
  }
}

@keyframes slideFromRight {
  from {
    transform: translateX(50%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideToRight {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}