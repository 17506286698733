.auth-container {
  display: flex;
  width: 720px;
  background: #11212E;
  border-radius: 24px;
  overflow: hidden;
  margin: 0 15px;
}

.auth-container__banner {
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  overflow: hidden;

  img {
    object-fit: cover;
    object-position: center;
  }

  @media screen and (max-width: 991.98px) {
    display: none;
  }
}

.auth-container__wrapper {
  flex: 1;
}

.auth-container__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 0;
 }
 
 .auth-container__header-button {
   color: #90A2AF;
   font-size: 14px;
   font-weight: 700;
   padding: 14px 18px;
   border-radius: 18px;
   height: 50px;
   background-color: transparent;
   border: unset;
   
   &.--active {
     background-color: #253749;
     color: #FFFFFF;
     font-weight: 800;
     margin-left: 6px;

     &:first-of-type {
      margin-right: 6px;
     }
   }
 }
 
 .auth-container__header-button-wrapper {
   display: flex;
   align-items: center;
 }
 
 .auth-container__header-button-close {
    border: 3px solid #FFFFFF0D;
    border-radius: 12px;
    padding: 5px;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #253749;
    cursor: pointer;
 
   img {
     width: 20px;
     height: 20px;
   }
 }

 .auth-container__fields {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px 20px;
  animation: fadein 1.5s;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.auth-container__input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 6px;
  position: relative;
  
  label {
    color: #90A2AF;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
  }

  input {
    background-color: #09151E;
    opacity: 0.8;
    border: 0;
    border-radius: 16px;
    height: 52px;
    color: #90A2AF;

    &::placeholder {
      font-size: 12px;
      color: #90A2AF;
      font-weight: 400;
    }

  }
  .show-password {
    position: absolute;
    top: 67%;
    right: 10px;
    color: #90A2AF;
    height: 18px !important;
    transform: translateY(-50%);

    &.--error {
      top: 49%;
      right: 6px;
    }
  }
}

.auth-container__fields-terms {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.02em;
  text-align: left;
  color: #90A2AF;

  b {
    color: #FFFFFF;
  }

  .MuiCheckbox-root {
    margin-top: -35px;
  } 

  .MuiFormControlLabel-asterisk  {
    visibility: hidden;
  }

  svg {
    color: #DCDCDC;
  }
}

.auth-container__fields-button {
  border-radius: 16px !important;
  background: linear-gradient(129.51deg, #FF9F00 -12.53%, #FFDB8C 82.06%);
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.92px;
  color: #101010 !important;
  height: 52px !important;

  &:hover {
    filter: brightness(1.1);
  }
}

.auth-container__social {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  gap: 20px;
}

.auth-container__social-divider {
  display: flex;
  align-items: center;
  gap: 16px;

  p {
    margin: 0;
    color: #90A2AF;
  }
  div {
    width: 100%;
    border: 1px solid #1E3241;
    height: 0;
  }
}

.auth-container__social-button-wrapper {
  display: flex;
  flex-direction: row;
  gap: 12px;

  button {
    border: unset;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 52px;
    padding: 14px 0;
    border-radius: 16px;
    background-color: #09151E;

    svg, img {
      color: #90A2AF;
      width: 24px;
      height: 24px;
    }
  }
}