.category-content {
  display: flex;
  align-items: flex-start;
  margin-top: 1.5625rem !important;
}

/* ============================= side bar nav bar =========================== */

main .navbar {
  width: 15.688rem;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-right: 1.25rem;
  position: -webkit-sticky;
  position: sticky;
  top: var(--headerHeight);
  display: block;
  padding: 0;
}
main .navbar .category-title {
  position: relative;
  height: 5rem;
  background-color: var(--darkBackground);
  margin-bottom: 0.4375rem;
  border-radius: 0.9375rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  color: var(--secondary-text-color);
  background-image: radial-gradient(
    var(--orange-primary) -32%,
    transparent,
    transparent
  );
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: 281% 540%;
  background-position-y: 61%;
}
main .navbar .category-title:before {
  content: "";
  position: absolute;
  left: 50%;
  border-radius: 0.25rem;
  top: 0;
  width: 80%;
  height: 0.25rem;
  background: var(--orange-primary);
  z-index: -1;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  -webkit-animation: 0.8s ease glow;
  animation: 0.8s ease glow;
  pointer-events: none;
}
main .navbar .category-title:not(:first-of-type) {
  margin-top: 1.5625rem;
}
main .navbar ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
main .navbar ul li a {
  height: 3.75rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 0.9375rem;
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: var(--secondary-text-color);
  font-size: 0.875rem;
  font-weight: bold;
  cursor: pointer;
  background: var(--darkBackground);
  border-radius: 0.9375rem;
  line-height: 1.2rem;
}
main .navbar ul li a.active {
  color: var(--orange-primary);
}
main .navbar ul li a:hover {
  color: var(--white);
}
main .navbar ul li + li {
  margin-top: 0.4375rem;
}
/* ==================================== category content =============================== */

main .content-wrapper {
  width: 100%;
}

main .head-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 5rem;
  background: var(--darkBackground);
  margin-bottom: 1.25rem;
  border-radius: 0.9375rem;
  overflow: hidden;
  padding: 0 3.125rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  color: var(--secondary-text-color);
  background-image: radial-gradient(
    var(--orange-primary),
    transparent,
    transparent
  );
  background-repeat: no-repeat;
  background-position-y: center;
  background-size: 190% 265px;
  background-position-x: 120%;
}
main .head-content h1 {
  font-size: 1.375rem;
  font-weight: bold;
}
main .head-content span {
  display: none;
  font-size: 0.875rem;
}
main .content-wrapper {
  width: 100%;
}

main .category-content .content {
  width: 100%;
  color: var(--secondary-text-color) !important;
  line-height: 1.6rem;
  background: var(--darkBackground);
  border-radius: 0.9375rem;
  height: 76vh;
  overflow: hidden;
}
main .category-content .content .text-content {
  width: calc(100% + 18px) !important;
  /* overflow: hidden; */
  padding: 3.125rem;
  overflow: auto;
  font-weight: bold;
  position: relative;
  /* width: calc(100% + 17px); */
  height: 100%;
  overflow: hidden auto;
}
main .category-content .content .text-content p {
  margin: 0;
}
main .category-content .content .text-content h1,
main .category-content .content .text-content h2 {
  position: relative;
  display: inline-block;
  line-height: 2.875rem;
  white-space: nowrap;
  margin-right: 100%;
  padding: 0 0.75rem;
  margin-bottom: 0.313rem;
  font-weight: bold;
}
main .category-content .content .text-content h1 span,
main .category-content .content .text-content h2 span {
  color: var(--white) !important;
}
main .category-content .content .text-content h1 {
  font-size: 2.063rem;
  font-weight: bold;
}
main .category-content .content .text-content h2 {
  font-size: 1.375rem;
  font-weight: 400;
}
main .category-content .content span {
  line-height: 1.563rem;
  font-weight: 400;
}
/* ========================================================================= */
/* ================================ media query ============================ */
/* ========================================================================= */

@media screen and (max-width: 991.98px) {
  main .navbar,
  main .head-content {
    display: none;
  }
  main .category-content .content {
    background-color: transparent;
    height: 100%;
    min-height: calc(100vh - 13vw);
  }
  main .category-content .content .text-content {
    padding: 6vw;
    margin-left: -10px;
  }
  .category-content {
    margin-top: 0 !important;
  }
  main .category-content .content .text-content p {
    text-align: justify;
    text-align-last: left;
    padding-bottom: 4vw;
    font-size: 4vw;
    font-weight: 400;
  }
}
